import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import herogifavatar from "../assets/Gif/VideoAwake.mp4";
import { SvgFiles } from "../pages/DataSvg";
const Awake = () => {
  return (
    <>
      <section className="pt-3 pt-lg-4 pb-3 pb-lg-5 sections_bg_image position-relative">
        {SvgFiles.map((data, i) => {
          return (
            <div className="">
              <span className="position-absolute d-inline-block Img_svg_Position_Awake1 svg_awake_fill_light">
                {data.svgSimple}
              </span>
              <span className="position-absolute d-inline-block Img_svg_Position_Awake2 svg_awake_fill_light">
                {data.svgBrain}
              </span>
              <span className="position-absolute d-inline-block Img_svg_Position_Awake3 svg_awake_fill_light">
                {data.svgBrain}
              </span>
              <span className="position-absolute d-inline-block Img_svg_Position_Awake4 svg_awake_fill_light">
                {data.svgSimple}
              </span>
            </div>
          );
        })}
        <div className="avatar_hero_sec_margin">
          <div className="avatar_hero_sec_bg avatar_hero_sec_border_radius py-3 py-xl-4">
            <Container className="py-3 py-lg-4 py-xl-5  container-custom">
              <Row className="flex-column-reverse flex-lg-row">
                <Col
                  lg={6}
                  xxl={7}
                  className="py-lg-5 pt-4 text-center text-lg-start">
                  <div className="ps-lg-4 ps-xl-3 ps-xxl-0">
                    <h1 className="clr_secondary fs_5xl ff_work fw-semibold line_height122  letter_spac_minus_06 mb-3">
                      Awake your avatar's potential with AI.
                    </h1>
                    <p className="clr_secondary fs_lg ff_work fw-medium opacity06 line_height150  letter_spac_minus_01 mb-0 pb-3">
                      Embrace this transformative journey as your avatar's
                      <span className="d-lg-block">
                        potential expands, fueled by the harmonious synergy
                      </span>
                      between AI and imagination.
                    </p>{" "}
                    {/* href="https://discord.gg/Kbqwcz3J8e" */}
                    <a
                      aria-label="go to page Community"
                      target="_blank"
               
                      className="btn_common mt-3 mt-lg-4 d-inline-block">
                      Join Discord
                    </a>
                  </div>
                </Col>
                <Col
                  lg={6}
                  xxl={5}
                  className="text-xl-center text-xxl-start ps-xxl-0">
                  <div className="d-flex align-items-center justify-content-center justify-content-xxl-start h-100">
                    <div
                      style={{ mixBlendMode: "exclusion" }}
                      className="circle_hero_img border_radius_300px overflow-hidden">
                      <video
                        muted
                        autoPlay
                        loop
                        className=""
                        width="100%"
                        height="100%"
                        style={{
                          minHeight: "150px",
                          objectFit: "cover",
                        }}>
                        <source src={herogifavatar} type="video/mp4" />
                      </video>
                    </div>
                  </div>
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </section>
    </>
  );
};

export default Awake;
