import Transforming from "../components/Transforming";
import NavBar from "../components/NavBar";
import RecentVoice from "../components/RecentVoice";
import Footer from "../components/FooterUser";
import React from 'react';

const GenerateVoice = React.memo((props) => {
    document.documentElement.scrollTop = 0;

    return (
        <>
            <NavBar />
            <Transforming />
            <Footer />
        </>
    );
});


export default GenerateVoice;
