import React, {useState} from 'react';
import { Input,message  } from 'antd';
import PropTypes from 'prop-types';
import Websocket from "react-websocket";
import AudioPlay from "./AudioPlay";
import {wsUrl} from "./public/config";
import {getGeneratePosition} from "./public/main";
const { Search } = Input;


/**
 * 封装 ReactWebsocket
 */
let msgContent = ""
// 自己的url

// 是否重连
const reconnect=false
const reconnectIntervalInMilliSeconds=1000

const maxCountNum = 100

let auth = localStorage.getItem('SpeakingAiToken')
const url=`${wsUrl}?authorization=`+ auth
class ReactWebsocket extends React.Component{
    constructor(props) {
        super(props);
        this.state = {
            ws: new WebSocket(url),
            reconnect:reconnect,
            TextMessage:'',
            showPlay: false,
            textNum: 0,
            flags: false,//generate 按钮是否可以点击
            init:false,
            genText:"Generate",
            msgKey:"",
            textPlaceholder:"Enter text here. Appropriate punctuation can make the result more natural. Currently, we only support English and Chinese.",
            composing: false
        };

        this.sendMessage = this.sendMessage.bind(this);
        this.setupWebsocket = this.setupWebsocket.bind(this);
    }

    componentDidMount() {
        this.setupWebsocket();
    }
    componentWillUnmount() {
        this.state.ws.close()
        clearInterval(this.interval);

    }

    sendMessage(msg) {
        this.state.ws.send(msg)
        message.info("消息已发送！")
    }
    reconnectWs(){
        console.log("正在重新连接")
        this.timeoutID= setTimeout(() => {
            this.setState({
                ws: new WebSocket(url)
            });
            this.state.ws.onopen = () => {
                console.log("重启websocket")
            }
        }, reconnectIntervalInMilliSeconds);
    }

    setupWebsocket() {
        this.state.ws.onopen = () => {
            console.log("开启websocket")
        }
        this.state.ws.onerror=e=>{
            console.log("发生错误："+e)
        }

        this.state.ws.onmessage=evt=>{
            console.log("收到消息：" + evt.data)
            this.props.onMessage(evt);

            const data = JSON.parse(evt.data); // Parse the received JSON string
            if (data.status === 2){
                clearInterval(this.interval);
                this.setState({genText:"Start Generate"});
            }
            else if(data.status === 0){//generate success
                clearInterval(this.interval);
                this.setState({genText:"Regenerate"});
                this.setState({flags: true});
            }
            else if (data.status === 3 ){//in queue position
                this.setState({msgKey:data.message});
                this.interval = setInterval(() => this.tick(), 2000);
            }
            else if (data.status === 4 ){//limit times
                clearInterval(this.interval);
                this.setState({genText:"Generate"});
                this.setState({flags: true});
            }
            else if (data.status === 1 ){//ERROR
                clearInterval(this.interval);
                this.setState({genText:"Regenerate"});
                this.setState({flags: true});
            }
        }

        this.state.ws.onclose=evt=>{
            console.log("Websocket关闭连接，原因："+evt.reason+"错误代码："+evt.code)
            this.setState({reconnect: true});
            this.setState({genText:"Generate"});
            this.props.onERROR("Websocket close: "+evt.reason+" error code："+evt.code);
        }
    }

    tick() {
        getGeneratePosition(this.state.msgKey).then((res)=>{
            if(res.code === 200){
                console.log("In queue " + res.msg)
                if(res.msg === 0){
                    clearInterval(this.interval);
                    this.setState({genText:"Start Generate"});
                }
                else{
                    this.setState({genText:`In Queue Position ${res.msg}`});
                }
            }
        })
    }

    setLanguage(strType){
        if(strType === "EN")
            this.setState({textPlaceholder : "Enter text here. Appropriate punctuation can make the result more natural. Currently, we only support English and Chinese."})
        else
            this.setState({textPlaceholder : "请输入文本，合适的标点可以让表达更自然，目前只支持中英文。"})

        this.setState({genText:"Generate"});
    }

    handleCompositionStart = () => {
        this.setState({
            composing: true
        });
    };

    handleCompositionEnd = (e) => {
        this.setState({
            composing: false
        });
        this.setState({
            TextMessage: e.target.value
        });
    };

    handleMessageChange= (event) =>{
        this.setLength(event.target.value)
        if(event.target.value.length > 0)
            this.setState({flags: true});
        else
            this.setState({flags: false});

        if(!this.state.composing){
            this.setState({
                TextMessage: event.target.value
            });
        }else
        {
            console.log("54354");
        }

        console.log(this.state.TextMessage);
    }

    // 判断字符串是否包含中文
   countWords(str) {
        const chinese = Array.from(str)
            .filter(ch => /[\u4e00-\u9fa5]/.test(ch))
            .length

        const english = Array.from(str)
            .map(ch => /[a-zA-Z0-9\s]/.test(ch) ? ch : ' ')
            .join('').split(/\s+/).filter(s => s)
            .length

        return chinese + english
    }

   setLength(str){
        let iTotal = this.countWords(str)

       this.setState({
           textNum: iTotal
       });

        if(iTotal > maxCountNum)
            alert("Word count must be less than 100")
    }

    sendMyMsg = () =>{
        if(this.state.textNum > maxCountNum){
            alert("Word count must be less than 100")
            return;
        }
        if(this.reconnect){
            this.setState({
                ws: new WebSocket(url)
            });
        }

        console.log("点击了generate");
        let msgContent = 'This is speaking ai, we are very pleased to serve you！'
        if(this.state.TextMessage != null && this.state.TextMessage.length > 0)
            msgContent = this.state.TextMessage ;
        //let timestamp = new Date().getTime();
        const data = {
            is_login: 1,
            prompt_id: this.props.prompt_id_user,
            output_language:this.strType(this.state.TextMessage), //'zh'
            gen_text: msgContent,
        };
        console.log(JSON.stringify(data))
        this.state.ws.send(JSON.stringify(data))
        this.setState({genText:"In Queue"});
        this.setState({flags: false});
        this.props.onProgress();
    }

    strType(str){
        const hasChinese = /[\u4e00-\u9fa5]/.test(str);
        const hasEnglish = /[a-zA-Z]/.test(str);
        return hasChinese ? 'zh':'en';
    }

    render() {
        //const { textPlaceholder} = this.props;
        // 以下的自己改
        return(
            <div  className="align-center-column ">
                <div className=" position-relative">
                    <textarea
                        placeholder={this.state.textPlaceholder}
                        id="WriteSpeech"
                        className="textarea py-4 px-3 "
                        onChange={this.handleMessageChange}
                        onCompositionStart={this.handleCompositionStart}
                        onCompositionEnd={this.handleCompositionEnd}
                    ></textarea>

                    {this.state.textNum <= maxCountNum &&
                        <span id="wordsLength" className="text-num-strict">{this.state.textNum +"/100"}</span>
                    }
                    {this.state.textNum > maxCountNum &&
                        <span id="wordsLength" className="text-num-strict-red">{this.state.textNum +"/100"}</span>
                    }
                </div>

                <div className="center-container  pt-lg-4 ">
                    <button disabled={!this.state.flags} className="btn_common width398 center-container" onClick={this.sendMyMsg}>{this.state.genText}</button>
                </div>

            </div>

            )
    }
}
ReactWebsocket.propTypes = {
    onMessage: PropTypes.func.isRequired,
};
export default ReactWebsocket;

