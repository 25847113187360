import React, {Component, createRef, useRef, useState} from "react";
import {getVoiceList, getFileInfoList} from "./public/main";
import {Link} from "react-router-dom";
import AudioPlay from "./AudioPlay";
import {arrRemoveJson, voiceContainer} from "../pages/MapData";

export let CloneVoiceContainer = []
class CloneContainerBox extends Component {
  constructor(props) {
    super(props);
    this.state = {
      containerState: 1,
    };
  }
  componentDidMount() {
    // 移除监听函数
    this.init();
  }

  init() {
    console.log("getData")
    getVoiceList().then((retDat) => {
      console.log(retDat)
      if (retDat.code == 200) {

        CloneVoiceContainer.length = 0;
        for (let i = 0; i < retDat.data.length; i++) {
          let obj = {
            id: retDat.data[i].voice_id,
            fileS3Name: retDat.data[i].gen_voice_path,
            prompt_file_name: retDat.data[i].prompt_file_name,
            fileName: retDat.data[i].voice_name,
            DownloadFileName: retDat.data[i].generate_date + ".wav",
            //fileSize: retDat.data[i].voice_name
          }
          CloneVoiceContainer.unshift(obj)
        }
        //刷新组件状态才会在UI显示出来
        this.setState({
          containerState: this.containerState++,
        })
      }
    });
  }

  deleteItemSet(uUid) {
    CloneVoiceContainer = arrRemoveJson(CloneVoiceContainer,'id',uUid)
    this.setState({
      containerState: this.state.containerState - 1,
    })
  }

  changeState() {
    this.setState({
      containerState: this.state.containerState + 1,
    })
    //this.refPlayer.current.changePlayState();
  }

  render() {
    return (
        <div className=" rowFlex-auto-re-row"  >
          {
            CloneVoiceContainer.map((item) => {
                  return <CloneVoiceTemplate key={item.id}  VoiceInfo={item}  deleteItem={this.deleteItemSet.bind(this)} />;
                }
            )
          }
        </div>
    );
  }
}

class CloneVoiceTemplate extends Component {

  constructor(props) {
    super(props);
    this.state = {
      containerSate: 0,
      observer: null
    }
    this.targetRef =  createRef();
    this.rePlayer = createRef();
  }

  componentDidMount() {
    // 添加监听函数
    this.state.observer = new IntersectionObserver(
        ([entry]) => {
          if (entry.isIntersecting) {
            console.log('组件进入视窗');
          } else {
            console.log('组件离开视窗');
            this.rePlayer.current.pauseAudio()
          }
        },
        { threshold: 0.5 } // threshold 表示交叉比例，0.5 表示当元素一半以上在视窗中时触发回调
    );

    this.state.observer.observe(this.targetRef.current);
  }

  componentWillUnmount() {
    // 移除监听函数
    this.state.observer.disconnect();
  }

  changePlayState = ()=> {
    this.setState({containerSate: 3});
    this.rePlayer.current.setAudioSrc("src")
  }

  deleteFileFromContainer = ()=>{
    this.props.deleteItem(this.props.VoiceInfo.id)
  }

  render() {
   let {VoiceInfo} = this.props;

    return (
        <div className=" py-4 px-3 clone-voice-cards   marginTop20" ref={this.targetRef}>
            <div className="marginTop10">
              <AudioPlay deleteFile={this.deleteFileFromContainer.bind(this)} ref={this.rePlayer} s3Name={VoiceInfo.fileS3Name} fileName={VoiceInfo.fileName} id={VoiceInfo.id} showDownload={false}
                         showTime={true} DownloadFileName={VoiceInfo.DownloadFileName}></AudioPlay>
            </div>
        </div>
    );
  }
};

export default CloneContainerBox;
