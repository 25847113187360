import React, { useEffect, useState, useRef } from "react";
import PropTypes from 'prop-types';
import mic from "../assets/images/webp/mic.png";

const FilesDragAndDrop = (props) => {
  const [dragging, setDragging] = useState(false);
  const [message, setMessage] = useState({ show: false, text: null, type: null });
  const drop = useRef();
  const drag = useRef();

    useEffect(() => {
      let divDrop = drop.current;
      // useRef 的 drop.current 取代了 ref 的 this.drop
      divDrop.addEventListener('dragover', handleDragOver);
      divDrop.addEventListener('drop', handleDrop);
      divDrop.addEventListener('dragenter', handleDragEnter);
      divDrop.addEventListener('dragleave', handleDragLeave);

      // 销毁时移除事件
      return function cleanup() {
        divDrop.removeEventListener("dragenter", handleDragEnter);
        divDrop.removeEventListener("dragleave", handleDragLeave);
        divDrop.removeEventListener("dragover", handleDragOver);
        divDrop.removeEventListener("drop", handleDrop);
      };

    })

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();
    console.log("drop release")
    setDragging(false)
    const { count, formats } = props;
    const files = [...e.dataTransfer.files];

    if (count && count < files.length) {
      console.log(files)
      console.log("Sorry, you can only upload at most 1 files you sent " + files.length + " files")
      showMessage(`Sorry, you can only upload at most ${count} files`, 'error', 2000);
      return;
    }

    if (formats && files.some((file) => !formats.some((format) => file.name.toLowerCase().endsWith(format.toLowerCase())))) {
      console.log("format files error")
      showMessage(`Upload only ${formats.join(', ')} format files`, 'error', 2000);
      return;
    }

    if (files && files.length) {
      console.log(files)
      props.onUpload(files[0]);
    }
  };

  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.target !== drag.current && setDragging(true)
  };

  const handleDragLeave = (e) => {
    e.preventDefault();
    e.stopPropagation();
    e.target === drag.current && setDragging(false)
  };

  const showMessage = (text, type, timeout) => {
    setMessage({ show: true, text, type, })
    setTimeout(() =>
        setMessage({ show: false, text: null, type: null, },), timeout);
  };

  // {dragging && (<div ref={drag} >release</div>)}

  return (
      <div ref={drop} className="inputFileContainer">
        {props.children}
      </div>
  );
}

FilesDragAndDrop.propTypes = {
  onUpload: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
  count: PropTypes.number,
  formats: PropTypes.arrayOf(PropTypes.string)
}

export { FilesDragAndDrop };
