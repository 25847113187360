import React, {createRef, useEffect, useRef, useState} from 'react';
import {selectHeadName, selectHeadPic2, selectHeadSentence} from "../pages/MapData";
import AudioPlayBtn from "./AudioPlayBtn"

import {eventBus, eventBus2 } from './EventsBus';



import Taylor_MP3 from "../assets/audio/org/swift_org.mp3";
import Musk_MP3  from "../assets/audio/org/musk_org.mp3";
import Kobe_MP3  from "../assets/audio/org/kobe_org.mp3";

import Taylor_MP3_AI from "../assets/audio/taylor_swift_3.mp3";
import Musk_MP3_AI  from "../assets/audio/elon_result_1.mp3";
import Kobe_MP3_AI  from "../assets/audio/kobe-result-2.mp3";




const Card = ({ title, description, isHovered, handleHover,  isSelected, handleClick, isPlaying, srcAudio }) => {

    useEffect(() => {
        return () => {
            if(audioRef.current)
                audioRef.current.pauseAudio()
        }
    }, []);

    const audioRef = useRef()
    let classType = "card-role"
    let classText = "text-role"
    let classPlay = 0
    if(isSelected){
        classType = "card-selected"
        classText = "text-selected"
        classPlay = 2
        //audioRef.current.playAudio()
    }
    else if(isHovered){
        classType = "card-hovered"
        classText = "text-hovered"
        classPlay = 1
    }

    const playOrPauseAudio = (state) =>{
        if(audioRef.current === null || audioRef.current === undefined )
            return
        if(state){
            //eventBus.emit('message', 'LEFT');
            audioRef.current.playAudio()
        }
        else{
            audioRef.current.pauseAudio()
        }
    }

    playOrPauseAudio (isPlaying)

    const pauseAudio = ()=>{
        if(audioRef.current === null || audioRef.current === undefined )
            return
        if(audioRef.current)
            audioRef.current.pauseAudio()
    }


    return (
        <div
            className={classType}
            onMouseEnter={handleHover}
            onMouseLeave={handleHover}
            onClick={handleClick}
        >
            <li className="marginLeft40">{isSelected ? selectHeadPic2(description, 118) : selectHeadPic2(description, 100) }</li>
            <div className="marginBottom20">
                <div className={"marginTop-20"}>
                    <h3 className={classText} >{title}</h3>
                </div>

                <div className="marginTop20 marginLeft20">
                    <AudioPlayBtn clickPlay={pauseAudio.bind()} ref={audioRef} palyState={classPlay} srcAudio={srcAudio} leftOrRight="LEFT"  ></AudioPlayBtn>
                </div>
            </div>
        </div>
    );
}

export function CardsContainer({changeName})  {
    const targetRef = useRef();

    const [hoveredCard, setHoveredCard] = useState(null);
    const [selectCard, setSelectCard] = useState(null);

    const [audios, setAudios] = useState([
        { index: 0, isPlaying: false, name:"Taylor", promptID: "579326a9-ed25-49ad-bd1c-5f3a0523b5bc", src: Taylor_MP3 },
        { index: 1, isPlaying: false, name:"Musk", promptID: "d41e26cb-f024-4134-b9b9-4fb99d1857ba", src: Musk_MP3 },
        { index: 2, isPlaying: false, name:"Kobe", promptID: "d41e26cb-f024-4134-b9b9-4fb99d1857ba", src: Kobe_MP3},
    ]);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    console.log('组件进入视窗');
                } else {
                    console.log('组件离开视窗');
                    const updatedAudios = audios.map((audio) => {
                        return { ...audio, isPlaying: false };
                    });
                    setAudios(updatedAudios);
                }
            },
            { threshold: 0.5 } // threshold 表示交叉比例，0.5 表示当元素一半以上在视窗中时触发回调
        );

        eventBus.on('message', (message) => {
            console.log("receive msg LEFT" + message)
            const updatedAudios = audios.map((audio) => {
                return { ...audio, isPlaying: false };
            });
            setAudios(updatedAudios);

        });

        observer.observe(targetRef.current);

        return () => {
            eventBus.removeAllListeners('message');
            observer.disconnect();
            const updatedAudios = audios.map((audio) => {
                return { ...audio, isPlaying: false };
            });
            setAudios(updatedAudios);
        };
    }, []); // 空数组表示仅在组件挂载和卸载时运行一次


    const handleHover = (index) => {
        setHoveredCard(index);
    }

    const handleClick = (index) => {
        setSelectCard(index);
        changeName(audios[index].name);
        const updatedAudios = audios.map((audio) => {
            if (audio.index === index) {
                if(audio.isPlaying)
                    return { ...audio, isPlaying: false };
                else
                    return { ...audio, isPlaying: true };
            } else {
                return { ...audio, isPlaying: false };
            }
        });

        setAudios(updatedAudios);
    }

    const elementItems = audios.map((card, index) => (
        <Card
            key={index}
            title={selectHeadName(card.name)}
            description={card.name}
            isHovered={hoveredCard === index}
            handleHover={() => handleHover(index)}
            isSelected={selectCard === index}
            handleClick={() => handleClick(index)}
            isPlaying={card.isPlaying}
            srcAudio={card.src}
        />
    ))

    return (
        <>
        <div className="card-container " ref={targetRef}>
            {elementItems}
        </div>
        </>
    );
}



export function CardsContainerAIRole({name,handleClick})  {
    const targetRef = useRef();
    const audioRef = useRef()

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    console.log('组件进入视窗');
                } else {
                    audioRef.current.pauseAudio()
                }
            },
            { threshold: 0.5 } // threshold 表示交叉比例，0.5 表示当元素一半以上在视窗中时触发回调
        );

        eventBus2.on('stop', (message) => {
                audioRef.current.pauseAudio()
                console.log("receive msg" + message)
        });

        observer.observe(targetRef.current);

        return () => {
            if(audioRef.current)
                audioRef.current.pauseAudio()
            eventBus2.removeAllListeners('stop');
            observer.disconnect();
        };
    }, []); // 空数组表示仅在组件挂载和卸载时运行一次

    const dispatchMsg = () =>{
       // eventBus.emit('message', 'RIGHT');
    }

    let getAudioSrc  = (nameIndex) =>{
        switch (nameIndex) {
            case "Taylor":
                return Taylor_MP3_AI
            case "Musk":
                return Musk_MP3_AI
            case "Kobe":
                return Kobe_MP3_AI
            default:
                return Kobe_MP3_AI
        }
    }


    return (
        <>
            <div ref={targetRef}
                onClick={handleClick}
                className="align-left-column-marginLeft20 marginBottom20  ">
                <div className="rowFlex ">
                    <svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" fill="white"/>
                        <path d="M19.4319 25.256L9.15195 19.184V14.896L20.0559 8L30.8319 14.512L29.5279 16.664L20.0879 10.96L11.6719 16.28V17.744L20.7119 23.088L19.4319 25.256Z" fill="url(#paint0_linear_498_2654)"/>
                        <path d="M19.832 32.4718L8.79999 26.7678L9.95999 24.5358L19.768 29.6078L27.936 24.9198V23.0798L19.144 17.7438L20.456 15.5918L30.448 21.6638V26.3758L19.832 32.4718Z" fill="url(#paint1_linear_498_2654)"/>
                        <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="url(#paint2_linear_498_2654)"/>
                        <defs>
                            <linearGradient id="paint0_linear_498_2654" x1="10.4079" y1="16.632" x2="30.1759" y2="16.632" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#2825C7"/>
                                <stop offset="1" stop-color="#6A33DF"/>
                            </linearGradient>
                            <linearGradient id="paint1_linear_498_2654" x1="9.37599" y1="24.0318" x2="29.192" y2="24.0318" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#2825C7"/>
                                <stop offset="1" stop-color="#6A33DF"/>
                            </linearGradient>
                            <linearGradient id="paint2_linear_498_2654" x1="4.61603e-07" y1="-7.43351" x2="49.1845" y2="2.8717" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#5258F2"/>
                                <stop offset="1" stop-color="#A64FEB"/>
                            </linearGradient>
                        </defs>
                    </svg>

                    <svg className="marginLeft10 marginTop5" width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fill-rule="evenodd" clip-rule="evenodd" d="M18.27 2C18.65 3.77 20.03 5.16 21.81 5.54C20.04 5.92 18.65 7.3 18.27 9.08C17.89 7.31 16.51 5.92 14.73 5.54C16.5 5.16 17.89 3.78 18.27 2ZM16.23 20H13.08L11.87 16.46H6.3L5.09 20H2L7.3 5.48H10.93L16.23 20ZM6.99 14.21H11.19L9.08 7.87L6.99 14.21ZM19.73 10H16.73V20H19.73V10Z" fill="url(#paint0_linear_589_917)"/>
                        <defs>
                            <linearGradient id="paint0_linear_589_917" x1="6.5" y1="4.5" x2="28" y2="27" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#5258F2"/>
                                <stop offset="1" stop-color="#A64FEB"/>
                            </linearGradient>
                        </defs>
                    </svg>

                    <div className="marginLeft10 marginTop5 text-play-role-title">
                        { selectHeadName(name)}
                    </div>
                </div>

                <div className="align-left-row-line ">
                </div>

                <div>
                    <li className="marginLeft20 marginTop20">{selectHeadPic2(name, 60)}</li>
                </div>

                <div className="marginLeft20 marginTop20 marginRight40">

                    <h3 className="text-play-role marginRight40 opacity08 ">
                        {selectHeadSentence(name)}
                    </h3>
                </div>

                <div className="center-container" id={name}>
                    <AudioPlayBtn  clickPlay={dispatchMsg.bind()} ref={audioRef} palyState={0} srcAudio={getAudioSrc(name)} leftOrRight="RIGHT" ></AudioPlayBtn>
                </div>

            </div>
        </>
    );
}




