import { Col, Row } from "react-bootstrap";
import React, { useState, useRef, useLayoutEffect, useEffect } from 'react';
import MyWebsocket from "./MyWebsocket";
import CloneResultContainer, {CloneVoiceContainer} from "./CloneResultContainer"
import {getCurrentFormatDate, } from "./public/main";
import animationData from "../assets/LottieGIf/loading.json";
import Lottie from "react-lottie";
import RoleItemContainer from "./RoleItemContainer";
import Swal from "sweetalert2";
import { url } from "./public/config";

function base64ToBlob(base64, mimeType) {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mimeType });
}
const Transforming = React.memo((props) => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };



  const [showPlay, setShowPlay] = useState(true);
  const [showLoading, setShowLoading] = useState(false);
  const [containerSate, setContainerSate] = useState(0);

  //生成相关的参数
  const [prompt_id, setPrompt_id] = useState("733d6a62-fa07-42d5-ae64-ae55e5d26eb1");


  const [cloneCunt, setCloneCunt] = useState(0);

  let refContainer = useRef();
  let refWebsocket = useRef();

  useEffect(()=>{

  })


  let selectPromptID = (divIndex, id) =>{
    console.log("set Prompt_id: " + id)//voice
    setPrompt_id(id)
    if(divIndex === 3 || divIndex === 4)
      refWebsocket.current.setLanguage("CN")
    else
      refWebsocket.current.setLanguage("EN")
  }

  let onMessage = (evt)=>{
    const data = JSON.parse(evt.data); // Parse the received JSON string
    if (data.status === 0) {
      setCloneCunt(cloneCunt + 1)

      let audioName = "G-" + getCurrentFormatDate() + "-0" + cloneCunt + ".wav";
      let objVoice = {
        DownloadFileName: audioName,
        fileName: data.voice_name,
        id: data.gen_voice_path,
        fileS3Name: data.gen_voice_path,
      }

      CloneVoiceContainer.unshift(objVoice)
      setShowLoading(false);
      setContainerSate(containerSate + 1)
      refContainer.current.changeState();
      setShowPlay(true);

      Swal.fire({
        position: "center",
        icon: "success",
        title: "Generate Voice successfully",
        showConfirmButton: false,
        timer: 2500,
      });
    }
    else if (data.status === 1) {
      setShowLoading(false);
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Generate server error",
        showConfirmButton: false,
        timer: 4000,
      });
    }
    else if (data.status === 4 ){//limit times
      setShowLoading(false);
      setShowPlay(true);

      Swal.fire({
        position: "center",
        icon: "error",
        title: "Beyond limit of daily free quota",
        showConfirmButton: false,
        timer: 4000,
      });
    }
  }
  let onERROR = (data) =>{
    setShowLoading(false);
    //alert(data);
  }
  let onProgress = () =>{//点击了generate
    setShowLoading(true);
  }


  return (
    <>
      <section
        id="Transform"
        className="py-lg-5 py-5 pb-4 sections_bg_image marginBottom90">
        <div className="container  container-custom">
          <h1
            style={{ letterSpacing: "-0.66px", maxWidth: "888px" }}
            className="ff_work fs_5xl clr_off_black line_height122 fw-semibold text-center mx-auto ">
            AI Voice Cloning with Emotion
          </h1>

          <div className="box_settings_transform      mt-lg-5 mt-4">

            <RoleItemContainer selectPromptID={selectPromptID.bind()}></RoleItemContainer>

            <div className="px-lg-5 pb-2  py-4 align-center-column">

              <div className="row justify-sm-content-center position-relative z-1 ">

                <MyWebsocket ref={refWebsocket} onMessage={onMessage} onProgress={onProgress}  onERROR={onERROR} prompt_id_user={prompt_id} ></MyWebsocket>
              </div>
              <div>
                { showLoading &&
                  <div className="center-container   ">
                    <Lottie
                        style={{ pointerEvents: "none" }}
                        options={defaultOptions}
                        width="10%"
                    />
                  </div>
                }

                <div className="wait-time-notice">
                  <div className="background-notice"></div>
                  <div className="text-wait-time-notice">
                  Due to the rapid growth of user volume, the 1.0 version requires a longer waiting time. Version 2.0 is being urgently upgraded, please wait patiently. Thank you for your support.
                  </div>
                </div>
              </div>
            </div>
          </div>

          {showPlay &&
            <CloneResultContainer  ref={refContainer} ></CloneResultContainer>
          }
        </div>
      </section>
    </>
  );
})

export default Transforming;

