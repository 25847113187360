import React from "react";

function ElevateHero() {

  const handleClick = event => {
    window.location.href = "https://yeeqohbyi7d.typeform.com/to/RNEUexJm"
  };

  return (
    <section className="py-sm-5 pt-5 pb-3  h-100 w-100  position-relative d-flex flex-column justify-content-center align-content-between">
      <div className="container  container-custom text-center py-lg-4">
        <h1
          className="ff_work fs_5xl clr_off_black line_height122 fw-semibold text-center mx-auto "
          style={{ letterSpacing: "-0.66px", maxWidth: "703px" }}>
          Partnership for Customized Solutions
        </h1>
        <p
          style={{ letterSpacing: "-0.198px", maxWidth: "576px" }}
          className="ff_work clr_primary fw-medium fs_lg text-center mx-auto line_height150 opacity08 pb-3">
          Fine tuning to get better voice. Your voice is a powerful tool that
          can captivate and influence those around you.
        </p>

        <a
            aria-label="go to page Discord"
            className={`btn_common mt-lg-4`}
            href="https://yeeqohbyi7d.typeform.com/to/RNEUexJm"
            target="_blank">
          Contact us today
        </a>

      </div>
    </section>
  );
}

export default ElevateHero;
