import React, {useEffect} from "react";
import ElevateHero from "../components/ElevateHero";
import NavBar from "../components/NavBar";
import { SvgFiles } from "../pages/DataSvg";
import VoiceCloning from "../components/VoiceCloning";
import EndlessBenefits from "../components/EndlessBenefits";
import Faq from "../components/Faq";
import Footer from "../components/FooterUser";

function Partnership() {
  document.documentElement.scrollTop = 0;

  return (
    <>
      <div className="d-flex flex-column min_vh_custom">
        <NavBar Partnershipfix="Nav_links_common_fix" />

        <div className="d-flex flex-column justify-content-center align-items-center flex-grow-1 sections_bg_image position-relative">
          {SvgFiles.map((data, i) => {
            return (
              <div className=" opacity-25">
                <span className="position-absolute d-none d-md-block Img_svg_Position_Elevate1">
                  {data.svgSimple}
                </span>
                <span className="position-absolute Img_svg_Position_Elevate2">
                  {data.svgSimple}
                </span>
                <span className="position-absolute Img_svg_Position_Elevate3">
                  {data.svgSimple}
                </span>
                <span className="position-absolute Img_svg_Position_Elevate4">
                  {data.svgSimple}
                </span>
                <span className="position-absolute Img_svg_Position_Elevate5">
                  {data.svgBrain}
                </span>

                <span className="position-absolute Img_svg_Position_Elevate6">
                  {data.svgBrain}
                </span>
              </div>
            );
          })}
          <ElevateHero />
        </div>
      </div>
      <VoiceCloning />
      <EndlessBenefits />
      <Faq />
      <Footer />
    </>
  );
}

export default Partnership;
