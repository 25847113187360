import React from "react";
import NavBar from "../components/NavBar";
import Awake from "../components/Awake";
import PricingPlans from "../components/PricingPlans";
import Footer from "../components/Footer";

function Avtar() {
  document.documentElement.scrollTop = 0;

  return (
    <>
      <NavBar />
      <Awake />
      <PricingPlans />
      <Footer sections_bg_image_community="sections_bg_image_community" />
    </>
  );
}

export default Avtar;
