import React from "react";
import { useParams, useSearchParams } from "react-router-dom";

export function GoogleCallback() {

    //const params = useParams()
    //alert(params);

    // 获取 URL 查询参数
    const [searchParams] = useSearchParams();
    alert(searchParams);

    // 将查询参数转换为 JavaScript 对象
    const query = Object.fromEntries(searchParams);

    // 现在你可以使用 query.name 和 query.age 获取查询参数了
    return (
        <div>
            {/* 显示查询参数 */}
            <p>名字: {query.name}</p>
            <p>年龄: {query.age}</p>
        </div>
    );
}

export default GoogleCallback;

