import React from "react";
//import animatedArt from "../assets/LottieGIf/Dark section.mp4";
import { Link } from "react-router-dom";
import { SvgFiles } from "../pages/DataSvg";

const UltraRealVoice = () => {

  const handleClick = event => {
    window.location.href = "/GenerateVoice";
  };

  return (
    <section className="py-5 px-2 position-relative z-2 sections_bg_image">
      <div className="common-black-container position-relative mx-auto z-2  text-center py-5 my-xl-5">

        <div className="py-lg-5 my-lg-3 position-relative z-3">
          <h2 className="text-center clr_secondary fs_4xl ff_work fw-semibold line_height122">
            Record and clone  your voice now
            <span className="d-md-block"> in 10 seconds</span>
          </h2>
          <p className="ff_work fs-lg clr_secondary line_height150 opacity08 text-center">
            Our advanced model captures the essence of your unique tone, pitch,
            and modulation,
            <span className="d-md-block">
               allowing you to reproduce and utilize your voice in ways you've never imagined
            </span>
          </p>
          <div className="mt-4 pt-3">
            <Link>
              <button className="btn-try-for-free ff_work fw-medium fs_lg line_height150 letter-spacing-198 transition300" onClick={handleClick}>
                Try for free
              </button>
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default UltraRealVoice;
