import React from 'react';
import {Helmet} from 'react-helmet';

export default  class TwitterContent extends React.Component {
    render () {
        return (
            <>
                <Helmet>
                    <meta content="text/html; charset=UTF-8" name="Content-Type" />
                    <meta name="twitter:card"         content="player"/>
                    <meta name="twitter:site"          content="@youtube"/>
                    <meta name="twitter:title"         content="Juergen Hoeller and Ben Hale at SpringOne Platform 2019"/>
                    <meta name="twitter:description"   content="SpringOne Platform 2019 - Day 1 "/>
                    <meta name="twitter:image"         content="https://i.ytimg.com/vi/D0Tv_scCzaM/maxresdefault.jpg"/>
                    <meta name="twitter:player"        content="https://www.youtube.com/embed/D0Tv_scCzaM"/>
                    <meta name="twitter:player:width"  content="1280"/>
                    <meta name="twitter:player:height" content="720"/>
                    <meta name="twitter:player:stream" content="https://www.youtube.com/embed/D0Tv_scCzaM"/>
                    <title>New Title</title>
                </Helmet>
            </>
        )
    }
};
