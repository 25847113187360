import React, { Component, useState, useRef } from "react";
import Swal from "sweetalert2";
import { addFileInfoToSql, getAuthUrlUploadFile, getCurrentFormatDate, cutAudioIn10s, getAudioTime } from "./public/main";

import { Modal } from "antd";
import AudioRecorder from "./AudioRecord";
import { FilesDragAndDrop } from "./FilesDragAndDropHook";
import AudioPlaySimple from "./AudioPlaySimple";
import { DlgProgress } from "./DlgProgress"
import { DlgMSGError } from "./DlgMSGError"
import { removeVoiceTemplate, voiceContainer, arrRemoveJson } from "../pages/MapData";


import axios from 'axios'

let audioContainer = [];


function handleAddControl(src, audioName, sizeStr) {
  let obj = {
    url: src,
    name: audioName,
    size: sizeStr
  }
  audioContainer.push(obj)
}



export function removeVoice(url) {
  removeVoiceTemplate(url)
}


export function DialogAdd({ addItem, small = 1 }) {
  const [isModalOpen, setIsModalOpen] = useState(false);
  let [audioObj, setAudioObj] = useState();
  let [auodioContainerSate, setauodioContainerSate] = useState(0);
  let [audioName, setAudioName] = useState("");
  let [audioDsc, setAudioDsc] = useState("");

  let [isProgressModalOpen, setProgressModalOpen] = useState(false);
  let [isMSGModalOpen, setMSGModalOpen] = useState(false);
  let [showError, setShowError] = useState(false);
  const modal = useRef(null)
  const showModal = () => {
    setIsModalOpen(true);
  };

  const showMSGHide = () => {
    setMSGModalOpen(false);
  };

  const showProgressHide = () => {
    setProgressModalOpen(false);
  };

  const handleOk = () => {
    if (audioObj === undefined) {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Must select or record a voice",
        showConfirmButton: false,
        timer: 2500,
      });
      return;
    }
    if (audioName === "") {
      Swal.fire({
        position: "center",
        icon: "error",
        title: "Must enter a voice name",
        showConfirmButton: false,
        timer: 2500,
      });
      return;
    }
    setProgressModalOpen(true)

    let audioNameTemp;
    let file;  //注意：直接上传file文件，不要用FormData对象的形式传
    const data = new FormData();
    if (audioObj.name === undefined) {
      audioNameTemp = "P-" + getCurrentFormatDate() + "-01.mp3";
      //let blob = URL.createObjectURL(audioObj);
      data.append('Wav', audioObj);
      file = audioObj;
    }
    else {
      audioNameTemp = audioObj.name;
      data.append('Wav', audioObj);
      file = audioObj;
    }
    console.log(file.type + " " + file.size + "name； " + audioNameTemp)

    let urlObj = getAuthUrlUploadFile(audioNameTemp);//获取上传url

    const myDate = new Date(); //Thu Dec 28 2017 10:</span>07:39 GMT+0800 (中国标准时间)
    const dataStr = myDate.getDate() + "/" + (myDate.getMonth() + 1) + "/" + myDate.getFullYear() + " " + myDate.getHours() + ":" + myDate.getMinutes() + ":" + myDate.getSeconds();   //2017-12-28
    setAudioDsc(dataStr);
    console.log(dataStr)

    if (urlObj !== undefined) {
      let fileSubmitUrl = urlObj.data.SignedUrl;
      let config = {
        headers: {
          'Content-Type': 'multipart/form-data'
        },
        rejectUnauthorized: false,
        // headers:urlObj.data.ActualSignedRequestHeaders
      };
      console.log("上传文件：", file)
      axios.put(fileSubmitUrl, file, config)
        .then(res => {
          if (res.status === 200) {
            console.log("上传文件：", res)
            let fileUuid = urlObj.fileName;
            addFileInfoToSql(audioNameTemp, fileUuid, audioName, audioDsc).then((retDat) => {
              if (retDat.length > 5) {
                setProgressModalOpen(false)
                setIsModalOpen(false);
                Swal.fire({
                  position: "center",
                  icon: "success",
                  title: "Uploading Voice successfully",
                  showConfirmButton: false,
                  timer: 2500,
                });
                audioContainer = [];
                addItemThis(retDat, audioName, audioDsc);//在UI加上对应的元素
              }
            })
          }
        }).catch(
          err => {
            console.log(err)
          }
        )
    }
  };
  const handleCancel = () => {
    setIsModalOpen(false);
    audioContainer = [];
  };

  let updateAudioList = (obj) => {
    setShowError(false);
    setauodioContainerSate(auodioContainerSate + 1);
    setAudioObj(obj);
  }
  const deleteItemParent = () => {
    setauodioContainerSate(auodioContainerSate - 1);
    setAudioObj(undefined);
  }

  let addItemThis = (uUid, voiceName, voiceDsc) => {
    let obj = {
      url: uUid,
      name: voiceName,
      dsc: voiceDsc
    }
    voiceContainer.push(obj)

    let obj2 = {
      promptID: uUid,
      name: voiceName,
    }

    addItem(obj2)
  }

  let input = (e) => {
    setAudioName(e.target.value);
  }
  let handleChange = (event) => {
    setAudioDsc(event.target.value);
  }

  const onError = () => {
    setShowError(true);
  }

  const showErrorDlg = () => {
    Swal.fire({
      position: "center",
      icon: "error",
      title: "Free users can only have one prompt at a time",
      showConfirmButton: false,
      timer: 4000,
    });
  }

  let classType = auodioContainerSate === 0 ? "text-center box_file_submit width398 height420" : "  text-center box_file_submit width398 height420 disabledCard";

  return (
    <>
      {small == 1 && <div className="d-flex align-items-center gap-1 justify-content-center btn_play_simple" >
        <svg
          width="17"
          height="17"
          viewBox="0 0 17 17"
          fill="none"
          xmlns="http://www.w3.org/2000/svg">
          <g opacity="0.7">
            <path
              d="M13.1583 1.84131L15.1583 3.84131L13.6337 5.36664L11.6337 3.36664L13.1583 1.84131ZM5.83301 11.1666H7.83301L12.691 6.30864L10.691 4.30864L5.83301 9.16664V11.1666Z"
              fill="black"
            />
            <path
              d="M13.1667 13.1667H5.93867C5.92133 13.1667 5.90333 13.1733 5.886 13.1733C5.864 13.1733 5.842 13.1673 5.81933 13.1667H3.83333V3.83333H8.398L9.73133 2.5H3.83333C3.098 2.5 2.5 3.09733 2.5 3.83333V13.1667C2.5 13.9027 3.098 14.5 3.83333 14.5H13.1667C13.5203 14.5 13.8594 14.3595 14.1095 14.1095C14.3595 13.8594 14.5 13.5203 14.5 13.1667V7.388L13.1667 8.72133V13.1667Z"
              fill="black"
            />
          </g>
        </svg>
        <p className="mb-0 clr_primary fs_lg ff_work fw-medium line_height150 opacity07 ">
          edit
        </p>
      </div>}
      {small == 2 &&
        <div className="addVoiceBtnBorder-disable" onClick={showErrorDlg} >

          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" >
            <path d="M15 28V2" stroke="url(#paint0_linear_428_2316)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M2 15H28" stroke="url(#paint1_linear_428_2316)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <defs>
              <linearGradient id="paint0_linear_428_2316" x1="15" y1="32.8318" x2="16.2835" y2="32.8214" gradientUnits="userSpaceOnUse">
                <stop stop-color="#5258F2" />
                <stop offset="1" stop-color="#A64FEB" />
              </linearGradient>
              <linearGradient id="paint1_linear_428_2316" x1="2" y1="15.1858" x2="3.08794" y2="9.25924" gradientUnits="userSpaceOnUse">
                <stop stop-color="#5258F2" />
                <stop offset="1" stop-color="#A64FEB" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      }

      {small == 3 &&
        <div className="addVoiceBtnBorder " onClick={showModal}>

          <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg" >
            <path d="M15 28V2" stroke="url(#paint0_linear_428_2316)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <path d="M2 15H28" stroke="url(#paint1_linear_428_2316)" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
            <defs>
              <linearGradient id="paint0_linear_428_2316" x1="15" y1="32.8318" x2="16.2835" y2="32.8214" gradientUnits="userSpaceOnUse">
                <stop stop-color="#5258F2" />
                <stop offset="1" stop-color="#A64FEB" />
              </linearGradient>
              <linearGradient id="paint1_linear_428_2316" x1="2" y1="15.1858" x2="3.08794" y2="9.25924" gradientUnits="userSpaceOnUse">
                <stop stop-color="#5258F2" />
                <stop offset="1" stop-color="#A64FEB" />
              </linearGradient>
            </defs>
          </svg>
        </div>
      }

      <Modal title=" " width={980} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} maskClosable={false}
        closeIcon={' '}
        cancelButtonProps={{ style: { display: 'none' } }}
        okButtonProps={{ style: { display: 'none' } }}
      >
        <div className="marginRight-left80" >

          <span
            className="addVoice">
            Add Voice
          </span>
          <span
            style={{ letterSpacing: " -0.176px", marginLeft: "1px", marginTop: "10px" }}
            className="d-block addVoiceName ">
            Name
          </span>
          <input
            required
            className="w-100 clr_primary fs_md ff_work fw-normal p-2 mb-3 box_file_submit"
            placeholder="Enter template name"
            onKeyUp={input}
          />

          <div className="rowFlex ">
            {/*<input required className="checkbox_ " type="checkbox" />*/}
            <p className=" line_height150 letter_spac_minus_01 opacity08 marginTop-5 marginLeft5">
              I hereby confirm that I have all necessary rights or consents to upload and clone these voice samples
              and that I will not use the platform-generated content for any illegal, fraudulent, or harmful purpose.
              I reaffirm my obligation to abide by SpeakingAIs’ Terms of Service and Privacy Policy.
            </p>
          </div>

          <div className="rowFlex-auto-re-row">
            <div className={classType}>
              <p className="marginTop40 record-title">
                Please Record 10 Seconds of <br />Audio
              </p>
              <AudioRecorder funcUpdate={updateAudioList} addFile={handleAddControl} onError={onError}>
              </AudioRecorder>
            </div>
            <div className={classType}>
              <FetchUploadFile funcUpdate={updateAudioList} onError={onError}>
              </FetchUploadFile>
            </div>
          </div>

          {showError &&
            <div className="ErrorRed ">
              The recorded audio must be greater than 3 seconds, please record it again.
            </div>
          }
          <ContainerControl id="ContainerControl" auodioSate={auodioContainerSate} deleteItemParent={deleteItemParent.bind()}>
          </ContainerControl>

          <div className="rowFlex-auto-re-row ">
            <button className=" cancelBtn " onClick={handleCancel}>Cancel</button>
            <button className="btn_common width398 " onClick={handleOk}>Save</button>
          </div>

        </div>
      </Modal>

      {isProgressModalOpen
        &&
        <DlgProgress hide={showProgressHide} ref={modal}></DlgProgress>
      }

      {isMSGModalOpen
        &&
        <DlgMSGError hide={showMSGHide} ></DlgMSGError>
      }

    </>
  );
};

export function AddVoiceBtn({ addItemBtn, small = false }) {
  let addItemThis = () => {
    addItemBtn()
  }

  return (
    <>
      {!small &&
        <DialogAdd small={2} addItem={addItemThis}>

        </DialogAdd>
      }
      {small &&
        <DialogAdd small={3} addItem={addItemThis}>

        </DialogAdd>
      }
    </>
  );
}


class FetchUploadFile extends Component {
  constructor(props) {
    super(props);
    this.fileInput = React.createRef();
    this.fileInput2 = React.createRef();
  }

  prodata = (file) => {
    let urlHtml = URL.createObjectURL(file);
    let fileSize = file.size;//文件的字节数
    let fileSizeString = ''
    if (fileSize < 1024)
      fileSizeString = fileSize + 'B'
    else if (fileSize >= 1024 && fileSize < 1024 * 1024)
      fileSizeString = (fileSize / 1024).toFixed(1) + 'KB'
    else if (fileSize >= 1024 * 1024 && fileSize < 1024 * 1024 * 1024)
      fileSizeString = (fileSize / 1024 / 1024).toFixed(1) + 'MB'
    console.log(fileSizeString);
    let audioName = file.name;
    handleAddControl(urlHtml, audioName, fileSizeString)
    this.props.funcUpdate(file);//update UI update database
  }

  calculateAudio = (file) => {
    getAudioTime(file).then((nTime) => {
      console.log("duration" + nTime)
      if (nTime > 10) {
        cutAudioIn10s(file).then((fileNew) => {
          const strLegth = fileNew.type.length
          let strType = fileNew.type.substring(strLegth - 3, strLegth)
          strType = "." + strType
          console.log("type" + strType)
          fileNew.name = file.name.replace(/.[^/.]+$/, strType)
          this.prodata(fileNew)
        })
      } else if (nTime < 3) {
        this.props.onError()
      } else {
        console.log("type2")
        this.prodata(file)
      }
    })
  }

  submit = () => {
    let file;
    if (this.fileInput2.current.files[0] == null || this.fileInput2.current.files[0] == undefined) {
      console.log("1" + this.fileInput2)
      file = this.fileInput.current.files[0];
    }
    else {
      console.log("2" + this.fileInput2)
      file = this.fileInput2.current.files[0];
    }
    this.calculateAudio(file)
  };

  submitFiles = (file) => {
    console.log("duration 0")
    this.calculateAudio(file)
  };

  render() {
    return (
      <div>
        <FilesDragAndDrop
          className="inputFileContainer"
          onUpload={this.submitFiles}
          count={1}
          formats={['mp3', 'wav', 'moc', 'flac', 'aac', 'ogg', 'm4a']}
        >

          <p className=" line_height150 letter_spac_minus_01  marginTop40 record-title">
            Drag and drop audio file
          </p>

          <p className=" line_height150 letter_spac_minus_01 opacity08 ">
            Upload file must be less<br />than 10MB
          </p>
          <label className="mx-auto text-center " htmlFor="file-input" >
            <svg
              width="60"
              height="60"
              viewBox="0 0 60 60"
              fill="none"
              xmlns="http://www.w3.org/2000/svg">
              <path d="M41.25 30H52.5C52.9973 30 53.4742 30.1975 53.8258 30.5492C54.1775 30.9008 54.375 31.3777 54.375 31.875V46.875C54.375 47.3723 54.1775 47.8492 53.8258 48.2008C53.4742 48.5525 52.9973 48.75 52.5 48.75H7.5C7.00272 48.75 6.52581 48.5525 6.17417 48.2008C5.82254 47.8492 5.625 47.3723 5.625 46.875V31.875C5.625 31.3777 5.82254 30.9008 6.17417 30.5492C6.52581 30.1975 7.00272 30 7.5 30H18.75" stroke="#695CFF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M30 30L30 5.625" stroke="#695CFF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M41.25 16.875L30 5.625L18.75 16.875" stroke="#695CFF" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
              <path d="M44.0625 42.1875C45.6158 42.1875 46.875 40.9283 46.875 39.375C46.875 37.8217 45.6158 36.5625 44.0625 36.5625C42.5092 36.5625 41.25 37.8217 41.25 39.375C41.25 40.9283 42.5092 42.1875 44.0625 42.1875Z" fill="#695CFF" />

            </svg>
          </label>

        </FilesDragAndDrop>

        <div className="d-flex">
          <div className="onlyLine"></div>
          <svg width="36" height="32" viewBox="0 0 36 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <ellipse cx="18.343" cy="15.9554" rx="17.6396" ry="15.9554" fill="#695CFF" />
            <path d="M14.6484 11.8088C15.5604 11.8088 16.3584 11.9948 17.0424 12.3668C17.7384 12.7388 18.2784 13.2728 18.6624 13.9688C19.0584 14.6648 19.2564 15.5048 19.2564 16.4888C19.2564 17.4608 19.0584 18.3008 18.6624 19.0088C18.2784 19.7048 17.7384 20.2388 17.0424 20.6108C16.3584 20.9828 15.5604 21.1688 14.6484 21.1688C13.7484 21.1688 12.9504 20.9828 12.2544 20.6108C11.5584 20.2388 11.0124 19.7048 10.6164 19.0088C10.2324 18.3008 10.0404 17.4608 10.0404 16.4888C10.0404 15.5048 10.2324 14.6648 10.6164 13.9688C11.0124 13.2728 11.5584 12.7388 12.2544 12.3668C12.9504 11.9948 13.7484 11.8088 14.6484 11.8088ZM14.6484 13.6448C14.1924 13.6448 13.8024 13.7528 13.4784 13.9688C13.1664 14.1728 12.9264 14.4848 12.7584 14.9048C12.5904 15.3248 12.5064 15.8528 12.5064 16.4888C12.5064 17.1248 12.5904 17.6528 12.7584 18.0728C12.9264 18.4928 13.1664 18.8108 13.4784 19.0268C13.8024 19.2308 14.1924 19.3328 14.6484 19.3328C15.0924 19.3328 15.4704 19.2308 15.7824 19.0268C16.1064 18.8108 16.3524 18.4928 16.5204 18.0728C16.6884 17.6528 16.7724 17.1248 16.7724 16.4888C16.7724 15.8528 16.6884 15.3248 16.5204 14.9048C16.3524 14.4848 16.1064 14.1728 15.7824 13.9688C15.4704 13.7528 15.0924 13.6448 14.6484 13.6448ZM21.1398 20.9888V11.9888H23.2818L23.4438 13.7528C23.6838 13.1288 24.0318 12.6488 24.4878 12.3128C24.9558 11.9768 25.5378 11.8088 26.2338 11.8088C26.4378 11.8088 26.6238 11.8268 26.7918 11.8628C26.9598 11.8988 27.0978 11.9468 27.2058 12.0068L26.9178 14.0768C26.7978 14.0288 26.6478 13.9928 26.4678 13.9688C26.2998 13.9448 26.0778 13.9328 25.8018 13.9328C25.4418 13.9328 25.0938 14.0228 24.7578 14.2028C24.4218 14.3708 24.1458 14.6348 23.9298 14.9948C23.7138 15.3428 23.6058 15.7868 23.6058 16.3268V20.9888H21.1398Z" fill="#FAFAFA" />
          </svg>
          <div className="onlyLine"></div>
        </div>

        <div className="mx-auto text-center">
          <p className=" line_height150 letter_spac_minus_01 opacity08 pb-lg-3 marginTop20">
            You can select the files to upload
          </p>
          <label className="mx-auto text-center   select-file" htmlFor="file-input3" >
            select file
            <input type="file" className="file-input" id="file-input3" ref={this.fileInput2} onChange={this.submit} />
          </label>
        </div>
      </div>
    )
  }
}

class ContainerControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      containerState: 1,
    }
  }

  deleteItem(src) {
    let { auodioSate, deleteItemParent } = this.props;
    audioContainer = arrRemoveJson(audioContainer, 'url', src)
    deleteItemParent();
    this.setState({
      containerState: auodioSate++,
    })

  }

  render() {
    let { auodioSate } = this.props;
    return (
      <div className="marginBottom60" id={auodioSate}>
        {audioContainer.map((comp) => {
          return <AudioPlaySimple src={comp.url} name={comp.name} id={comp.url} size={comp.size} deleteItem={this.deleteItem.bind(this)} />;
        })}
      </div>
    );
  }
}

