import React, { Component } from "react";

import Taylor_MP3_AI from "../assets/audio/taylor_swift_3.mp3";
import {eventBus, eventBus2} from "./EventsBus";

class AudioPlayBtn extends Component {

    constructor(props) {
        super(props);
        this.state = {
            isPlaying: false,
            audioSource: Taylor_MP3_AI
        };
        this.audioRef = React.createRef(); // 创建一个对 <audio> 元素的引用
    }


    changeAudioSource = (srcAudioTemp) => {
        this.audioRef.current.pause();
        this.setState({
            isPlaying: false,
        });
        this.setState({
            audioSource: srcAudioTemp // 新的音频路径
        });
        const audioElement = this.audioRef.current;
        audioElement.src = srcAudioTemp;
        audioElement.load(); // 重新加载音频
        console.log("changeAudioSource" + srcAudioTemp)
    }

    playAudio = () => {
        const { leftOrRight} = this.props;

        if(leftOrRight === "LEFT"){
            console.log("left ====== " + leftOrRight)
            eventBus2.emit('stop', 'LEFT');
        }
        else{
            console.log("Right ====== " + leftOrRight)
            eventBus.emit('message', 'RIGHT');
        }
        this.audioRef.current.play();
        this.setState({
            isPlaying: true,
        });
    };

    oNEndedPlay = ()=>{
        const { leftOrRight} = this.props;
        if(leftOrRight === "RIGHT"){
            eventBus2.emit('stop', 'LEFT');
        }
        else{
            eventBus.emit('message', 'RIGHT');
        }
        this.audioRef.current.pause();
        this.setState({
            isPlaying: false,
        });
    };

    pauseAudio = () => {
        this.audioRef.current.pause();
        this.setState({
            isPlaying: false,
        });
    }

    render() {
        const { palyState, srcAudio} = this.props;

        let {
            isPlaying,
        } = this.state;


        return (
            <div className="height40">
                {srcAudio &&
                    <audio ref={this.audioRef}  onEnded={this.oNEndedPlay}>
                        <source src={srcAudio} type="audio/mp3" />
                        Your browser does not support the audio element.
                    </audio>
                }
                {!srcAudio &&
                    <audio ref={this.audioRef}  onEnded={this.oNEndedPlay}>
                        <source src={this.state.audioSource} type="audio/mp3" />
                        Your browser does not support the audio element.
                    </audio>
                }

                <div className="d-flex  align-items-center  ">
                    {

                    }
                    {palyState !== 2 &&
                        <svg width="122" height="2" viewBox="0 0 122 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 1.06152V1.11763" stroke="url(#paint0_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M4.3313 1.03516V1.1524" stroke="url(#paint1_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M7.66602 1.02637V1.16388" stroke="url(#paint2_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M10.9973 1.02051V1.17325" stroke="url(#paint3_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M14.332 1.01367V1.18523" stroke="url(#paint4_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M17.667 1.00195V1.20408" stroke="url(#paint5_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M20.998 0.981445V1.24001" stroke="url(#paint6_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M24.333 0.935547V1.3435" stroke="url(#paint7_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M27.6641 0.824219V1.64124" stroke="url(#paint8_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M30.999 0.614258V1.74964" stroke="url(#paint9_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M34.3342 0.44043V1.53864" stroke="url(#paint10_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M37.665 0.675781V1.34902" stroke="url(#paint11_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M41 0.883789V1.25109" stroke="url(#paint12_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M44.3311 0.964844V1.2158" stroke="url(#paint13_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M47.666 0.983398V1.2264" stroke="url(#paint14_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M51.0007 0.895508V1.28207" stroke="url(#paint15_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M54.332 0.556641V1.4217" stroke="url(#paint16_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M57.6672 0.0761719V1.73888" stroke="url(#paint17_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M60.998 0V1.94569" stroke="url(#paint18_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M64.3333 0.237305V1.59531" stroke="url(#paint19_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M67.6643 0.682617V1.40916" stroke="url(#paint20_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M70.9993 0.918945V1.3484" stroke="url(#paint21_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M74.334 0.989258V1.34323" stroke="url(#paint22_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M77.665 0.975586V1.37525" stroke="url(#paint23_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M80.9998 0.884766V1.45744" stroke="url(#paint24_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M84.3311 0.693359V1.63099" stroke="url(#paint25_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M87.6658 0.476562V1.94913" stroke="url(#paint26_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M91.0012 0.495117V1.99993" stroke="url(#paint27_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M94.3323 0.710938V1.79324" stroke="url(#paint28_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M97.667 0.910156V1.51732" stroke="url(#paint29_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M100.998 1.00879V1.3547" stroke="url(#paint30_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M104.333 1.04883V1.30314" stroke="url(#paint31_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M107.668 1.07324V1.28769" stroke="url(#paint32_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M110.999 1.08984V1.27293" stroke="url(#paint33_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M114.334 1.10254V1.25651" stroke="url(#paint34_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M117.665 1.11523V1.2345" stroke="url(#paint35_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M121 1.14453V1.18832" stroke="url(#paint36_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <defs>
                                <linearGradient id="paint0_linear_513_2185" x1="1" y1="1.0511" x2="1.08588" y2="1.37181" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5258F2"/>
                                    <stop offset="1" stop-color="#A64FEB"/>
                                </linearGradient>
                                <linearGradient id="paint1_linear_513_2185" x1="4.3313" y1="1.01337" x2="4.63739" y2="1.56036" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5258F2"/>
                                    <stop offset="1" stop-color="#A64FEB"/>
                                </linearGradient>
                                <linearGradient id="paint2_linear_513_2185" x1="7.66602" y1="1.00081" x2="8.05247" y2="1.58963" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5258F2"/>
                                    <stop offset="1" stop-color="#A64FEB"/>
                                </linearGradient>
                                <linearGradient id="paint3_linear_513_2185" x1="10.9973" y1="0.992122" x2="11.4428" y2="1.60314" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5258F2"/>
                                    <stop offset="1" stop-color="#A64FEB"/>
                                </linearGradient>
                                <linearGradient id="paint4_linear_513_2185" x1="14.332" y1="0.98179" x2="14.8472" y2="1.61097" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5258F2"/>
                                    <stop offset="1" stop-color="#A64FEB"/>
                                </linearGradient>
                                <linearGradient id="paint5_linear_513_2185" x1="17.667" y1="0.96439" x2="18.2857" y2="1.60577" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5258F2"/>
                                    <stop offset="1" stop-color="#A64FEB"/>
                                </linearGradient>
                                <linearGradient id="paint6_linear_513_2185" x1="20.998" y1="0.933394" x2="21.7729" y2="1.56125" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5258F2"/>
                                    <stop offset="1" stop-color="#A64FEB"/>
                                </linearGradient>
                                <linearGradient id="paint7_linear_513_2185" x1="24.333" y1="0.859734" x2="25.3487" y2="1.38138" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5258F2"/>
                                    <stop offset="1" stop-color="#A64FEB"/>
                                </linearGradient>
                                <linearGradient id="paint8_linear_513_2185" x1="27.6641" y1="0.672385" x2="28.8684" y2="0.981245" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5258F2"/>
                                    <stop offset="1" stop-color="#A64FEB"/>
                                </linearGradient>
                                <linearGradient id="paint9_linear_513_2185" x1="30.999" y1="0.40326" x2="32.2403" y2="0.63233" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5258F2"/>
                                    <stop offset="1" stop-color="#A64FEB"/>
                                </linearGradient>
                                <linearGradient id="paint10_linear_513_2185" x1="34.3342" y1="0.236341" x2="35.5727" y2="0.47263" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5258F2"/>
                                    <stop offset="1" stop-color="#A64FEB"/>
                                </linearGradient>
                                <linearGradient id="paint11_linear_513_2185" x1="37.665" y1="0.550669" x2="38.8353" y2="0.914867" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5258F2"/>
                                    <stop offset="1" stop-color="#A64FEB"/>
                                </linearGradient>
                                <linearGradient id="paint12_linear_513_2185" x1="41" y1="0.815531" x2="41.9685" y2="1.36797" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5258F2"/>
                                    <stop offset="1" stop-color="#A64FEB"/>
                                </linearGradient>
                                <linearGradient id="paint13_linear_513_2185" x1="44.3311" y1="0.918207" x2="45.0874" y2="1.5497" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5258F2"/>
                                    <stop offset="1" stop-color="#A64FEB"/>
                                </linearGradient>
                                <linearGradient id="paint14_linear_513_2185" x1="47.666" y1="0.93824" x2="48.4023" y2="1.57305" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5258F2"/>
                                    <stop offset="1" stop-color="#A64FEB"/>
                                </linearGradient>
                                <linearGradient id="paint15_linear_513_2185" x1="51.0007" y1="0.82367" x2="51.9929" y2="1.36142" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5258F2"/>
                                    <stop offset="1" stop-color="#A64FEB"/>
                                </linearGradient>
                                <linearGradient id="paint16_linear_513_2185" x1="54.332" y1="0.395879" x2="55.5445" y2="0.689543" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5258F2"/>
                                    <stop offset="1" stop-color="#A64FEB"/>
                                </linearGradient>
                                <linearGradient id="paint17_linear_513_2185" x1="57.6672" y1="-0.232822" x2="58.9308" y2="-0.0736027" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5258F2"/>
                                    <stop offset="1" stop-color="#A64FEB"/>
                                </linearGradient>
                                <linearGradient id="paint18_linear_513_2185" x1="60.998" y1="-0.361583" x2="62.2669" y2="-0.224943" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5258F2"/>
                                    <stop offset="1" stop-color="#A64FEB"/>
                                </linearGradient>
                                <linearGradient id="paint19_linear_513_2185" x1="64.3333" y1="-0.0150644" x2="65.587" y2="0.178371" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5258F2"/>
                                    <stop offset="1" stop-color="#A64FEB"/>
                                </linearGradient>
                                <linearGradient id="paint20_linear_513_2185" x1="67.6643" y1="0.547599" x2="68.8493" y2="0.889343" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5258F2"/>
                                    <stop offset="1" stop-color="#A64FEB"/>
                                </linearGradient>
                                <linearGradient id="paint21_linear_513_2185" x1="70.9993" y1="0.839137" x2="72.0361" y2="1.34497" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5258F2"/>
                                    <stop offset="1" stop-color="#A64FEB"/>
                                </linearGradient>
                                <linearGradient id="paint22_linear_513_2185" x1="74.334" y1="0.923476" x2="75.2845" y2="1.48612" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5258F2"/>
                                    <stop offset="1" stop-color="#A64FEB"/>
                                </linearGradient>
                                <linearGradient id="paint23_linear_513_2185" x1="77.665" y1="0.901313" x2="78.6719" y2="1.42916" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5258F2"/>
                                    <stop offset="1" stop-color="#A64FEB"/>
                                </linearGradient>
                                <linearGradient id="paint24_linear_513_2185" x1="80.9998" y1="0.778341" x2="82.1318" y2="1.19252" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5258F2"/>
                                    <stop offset="1" stop-color="#A64FEB"/>
                                </linearGradient>
                                <linearGradient id="paint25_linear_513_2185" x1="84.3311" y1="0.519113" x2="85.5536" y2="0.792302" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5258F2"/>
                                    <stop offset="1" stop-color="#A64FEB"/>
                                </linearGradient>
                                <linearGradient id="paint26_linear_513_2185" x1="87.6658" y1="0.202904" x2="88.9239" y2="0.381914" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5258F2"/>
                                    <stop offset="1" stop-color="#A64FEB"/>
                                </linearGradient>
                                <linearGradient id="paint27_linear_513_2185" x1="91.0012" y1="0.215466" x2="92.2604" y2="0.390787" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5258F2"/>
                                    <stop offset="1" stop-color="#A64FEB"/>
                                </linearGradient>
                                <linearGradient id="paint28_linear_513_2185" x1="94.3323" y1="0.509804" x2="95.5695" y2="0.749316" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5258F2"/>
                                    <stop offset="1" stop-color="#A64FEB"/>
                                </linearGradient>
                                <linearGradient id="paint29_linear_513_2185" x1="97.667" y1="0.797322" x2="98.814" y2="1.19313" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5258F2"/>
                                    <stop offset="1" stop-color="#A64FEB"/>
                                </linearGradient>
                                <linearGradient id="paint30_linear_513_2185" x1="100.998" y1="0.944505" x2="101.937" y2="1.5133" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5258F2"/>
                                    <stop offset="1" stop-color="#A64FEB"/>
                                </linearGradient>
                                <linearGradient id="paint31_linear_513_2185" x1="104.333" y1="1.00157" x2="105.098" y2="1.6315" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5258F2"/>
                                    <stop offset="1" stop-color="#A64FEB"/>
                                </linearGradient>
                                <linearGradient id="paint32_linear_513_2185" x1="107.668" y1="1.03339" x2="108.325" y2="1.67501" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5258F2"/>
                                    <stop offset="1" stop-color="#A64FEB"/>
                                </linearGradient>
                                <linearGradient id="paint33_linear_513_2185" x1="110.999" y1="1.05582" x2="111.555" y2="1.69182" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5258F2"/>
                                    <stop offset="1" stop-color="#A64FEB"/>
                                </linearGradient>
                                <linearGradient id="paint34_linear_513_2185" x1="114.334" y1="1.07392" x2="114.784" y2="1.68643" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5258F2"/>
                                    <stop offset="1" stop-color="#A64FEB"/>
                                </linearGradient>
                                <linearGradient id="paint35_linear_513_2185" x1="117.665" y1="1.09307" x2="117.979" y2="1.64491" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5258F2"/>
                                    <stop offset="1" stop-color="#A64FEB"/>
                                </linearGradient>
                                <linearGradient id="paint36_linear_513_2185" x1="121" y1="1.13639" x2="121.054" y2="1.39341" gradientUnits="userSpaceOnUse">
                                    <stop stop-color="#5258F2"/>
                                    <stop offset="1" stop-color="#A64FEB"/>
                                </linearGradient>
                            </defs>
                        </svg>
                    }

                    {palyState === 2 &&
                        <svg width="122" height="48" viewBox="0 0 122 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 25.4863V26.8328" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M4.3313 24.8359V27.6498" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M7.66602 24.6401V27.9405" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M10.9973 24.5024V28.1683" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M14.332 24.333V28.4504" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M17.667 24.0537V28.9048" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M20.998 23.5459V29.7515" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M24.333 22.4414V32.2322" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M27.6641 19.7861V39.3946" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M30.999 14.731V41.9802" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M34.3342 10.5679V36.9249" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M37.665 16.2275V32.3852" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M41 21.2046V30.0198" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M44.3311 23.1646V29.1874" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M47.666 23.5918V29.4238" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M51.0007 21.4819V30.7594" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M54.332 13.3628V34.1243" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M57.6672 1.83008V41.7351" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M60.998 0V46.6965" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M64.3333 5.70557V38.2977" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M67.6643 16.3887V33.8256" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M70.9993 22.0542V32.361" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M74.334 23.7397V32.2352" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M77.665 23.4062V32.9982" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M80.9998 21.2266V34.9708" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M84.3311 16.644V39.1471" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M87.6658 11.438V46.7795" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M91.0012 11.8843V47.9998" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M94.3323 17.0635V43.0388" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M97.667 21.8442V36.4162" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M100.998 24.2017V32.5036" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M104.333 25.1748V31.2783" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M107.668 25.7549V30.9016" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M110.999 26.1475V30.5416" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M114.334 26.4565V30.152" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M117.665 26.7764V29.6386" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M121 27.4644V28.5152" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                        </svg>
                    }

                    {isPlaying && palyState !== 2 &&
                        <div onClick={this.pauseAudio} className="marginBottom5 ">
                            <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.02163 39.5436C4.82489 37.422 3.0727 34.884 1.8673 32.0779C0.661889 29.2718 0.0274062 26.2538 0.000868405 23.1999C-0.0256694 20.1459 0.55627 17.1173 1.71273 14.2907C2.86919 11.4641 4.57701 8.89608 6.73654 6.73654C8.89608 4.57701 11.4641 2.86919 14.2907 1.71273C17.1173 0.55627 20.1459 -0.0256694 23.1999 0.000868405C26.2538 0.0274062 29.2718 0.661889 32.0779 1.8673C34.884 3.0727 37.422 4.82489 39.5436 7.02163C43.7333 11.3595 46.0515 17.1693 45.9991 23.1999C45.9467 29.2304 43.5278 34.9991 39.2635 39.2635C34.9991 43.5278 29.2304 45.9467 23.1999 45.9991C17.1693 46.0515 11.3595 43.7333 7.02163 39.5436ZM16.3826 14.0826V32.4826H20.9826V14.0826H16.3826ZM25.5826 14.0826V32.4826H30.1826V14.0826H25.5826Z" fill="#695CFF"/>
                            </svg>
                        </div>
                    }

                    {isPlaying && palyState === 2 &&
                        <div onClick={this.pauseAudio} className="marginBottom5 ">
                            <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M7.02163 39.5436C4.82489 37.422 3.0727 34.884 1.8673 32.0779C0.661889 29.2718 0.0274062 26.2538 0.000868405 23.1999C-0.0256694 20.1459 0.55627 17.1173 1.71273 14.2907C2.86919 11.4641 4.57701 8.89608 6.73654 6.73654C8.89608 4.57701 11.4641 2.86919 14.2907 1.71273C17.1173 0.55627 20.1459 -0.0256694 23.1999 0.000868405C26.2538 0.0274062 29.2718 0.661889 32.0779 1.8673C34.884 3.0727 37.422 4.82489 39.5436 7.02163C43.7333 11.3595 46.0515 17.1693 45.9991 23.1999C45.9467 29.2304 43.5278 34.9991 39.2635 39.2635C34.9991 43.5278 29.2304 45.9467 23.1999 45.9991C17.1693 46.0515 11.3595 43.7333 7.02163 39.5436ZM16.3826 14.0826V32.4826H20.9826V14.0826H16.3826ZM25.5826 14.0826V32.4826H30.1826V14.0826H25.5826Z" fill="white"/>
                            </svg>
                        </div>
                    }


                    {!isPlaying && palyState !== 2 &&
                        <div onClick={this.playAudio} className="marginBottom5 ">
                            <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M23 0C18.451 0 14.0042 1.34893 10.2219 3.8762C6.43956 6.40347 3.4916 9.99558 1.75078 14.1983C0.00996553 18.401 -0.445511 23.0255 0.441949 27.4871C1.32941 31.9486 3.51995 36.0468 6.73655 39.2634C9.95316 42.48 14.0514 44.6706 18.5129 45.558C22.9745 46.4455 27.599 45.99 31.8017 44.2492C36.0044 42.5084 39.5965 39.5604 42.1238 35.7781C44.6511 31.9958 46 27.549 46 23C46 19.9796 45.4051 16.9888 44.2492 14.1983C43.0934 11.4078 41.3992 8.87229 39.2635 6.73654C37.1277 4.6008 34.5922 2.90663 31.8017 1.75077C29.0112 0.594912 26.0204 0 23 0ZM18.4 33.35V12.65L32.2 23L18.4 33.35Z" fill="#695CFF"/>
                            </svg>
                        </div>
                    }
                    {!isPlaying && palyState === 2 &&
                        <div onClick={this.playAudio} className="marginBottom5 ">
                            <svg width="46" height="46" viewBox="0 0 46 46" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M23 0C18.451 0 14.0042 1.34893 10.2219 3.8762C6.43956 6.40347 3.4916 9.99558 1.75078 14.1983C0.00996553 18.401 -0.445511 23.0255 0.441949 27.4871C1.32941 31.9486 3.51995 36.0468 6.73655 39.2634C9.95316 42.48 14.0514 44.6706 18.5129 45.558C22.9745 46.4455 27.599 45.99 31.8017 44.2492C36.0044 42.5084 39.5965 39.5604 42.1238 35.7781C44.6511 31.9958 46 27.549 46 23C46 19.9796 45.4051 16.9888 44.2492 14.1983C43.0934 11.4078 41.3992 8.87229 39.2635 6.73654C37.1277 4.6008 34.5922 2.90663 31.8017 1.75077C29.0112 0.594912 26.0204 0 23 0ZM18.4 33.35V12.65L32.2 23L18.4 33.35Z" fill="white"/>
                            </svg>
                        </div>
                    }

                    {palyState !== 2 &&
                        <svg width="122" height="2" viewBox="0 0 122 2" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M1 1.06152V1.11763" stroke="url(#paint0_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                        <path d="M4.3313 1.03516V1.1524" stroke="url(#paint1_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                        <path d="M7.66602 1.02637V1.16388" stroke="url(#paint2_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                        <path d="M10.9973 1.02051V1.17325" stroke="url(#paint3_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                        <path d="M14.332 1.01367V1.18523" stroke="url(#paint4_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                        <path d="M17.667 1.00195V1.20408" stroke="url(#paint5_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                        <path d="M20.998 0.981445V1.24001" stroke="url(#paint6_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                        <path d="M24.333 0.935547V1.3435" stroke="url(#paint7_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                        <path d="M27.6641 0.824219V1.64124" stroke="url(#paint8_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                        <path d="M30.999 0.614258V1.74964" stroke="url(#paint9_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                        <path d="M34.3342 0.44043V1.53864" stroke="url(#paint10_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                        <path d="M37.665 0.675781V1.34902" stroke="url(#paint11_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                        <path d="M41 0.883789V1.25109" stroke="url(#paint12_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                        <path d="M44.3311 0.964844V1.2158" stroke="url(#paint13_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                        <path d="M47.666 0.983398V1.2264" stroke="url(#paint14_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                        <path d="M51.0007 0.895508V1.28207" stroke="url(#paint15_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                        <path d="M54.332 0.556641V1.4217" stroke="url(#paint16_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                        <path d="M57.6672 0.0761719V1.73888" stroke="url(#paint17_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                        <path d="M60.998 0V1.94569" stroke="url(#paint18_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                        <path d="M64.3333 0.237305V1.59531" stroke="url(#paint19_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                        <path d="M67.6643 0.682617V1.40916" stroke="url(#paint20_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                        <path d="M70.9993 0.918945V1.3484" stroke="url(#paint21_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                        <path d="M74.334 0.989258V1.34323" stroke="url(#paint22_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                        <path d="M77.665 0.975586V1.37525" stroke="url(#paint23_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                        <path d="M80.9998 0.884766V1.45744" stroke="url(#paint24_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                        <path d="M84.3311 0.693359V1.63099" stroke="url(#paint25_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                        <path d="M87.6658 0.476562V1.94913" stroke="url(#paint26_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                        <path d="M91.0012 0.495117V1.99993" stroke="url(#paint27_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                        <path d="M94.3323 0.710938V1.79324" stroke="url(#paint28_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                        <path d="M97.667 0.910156V1.51732" stroke="url(#paint29_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                        <path d="M100.998 1.00879V1.3547" stroke="url(#paint30_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                        <path d="M104.333 1.04883V1.30314" stroke="url(#paint31_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                        <path d="M107.668 1.07324V1.28769" stroke="url(#paint32_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                        <path d="M110.999 1.08984V1.27293" stroke="url(#paint33_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                        <path d="M114.334 1.10254V1.25651" stroke="url(#paint34_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                        <path d="M117.665 1.11523V1.2345" stroke="url(#paint35_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                        <path d="M121 1.14453V1.18832" stroke="url(#paint36_linear_513_2185)" stroke-width="0.81391" stroke-miterlimit="10"/>
                        <defs>
                            <linearGradient id="paint0_linear_513_2185" x1="1" y1="1.0511" x2="1.08588" y2="1.37181" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#5258F2"/>
                                <stop offset="1" stop-color="#A64FEB"/>
                            </linearGradient>
                            <linearGradient id="paint1_linear_513_2185" x1="4.3313" y1="1.01337" x2="4.63739" y2="1.56036" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#5258F2"/>
                                <stop offset="1" stop-color="#A64FEB"/>
                            </linearGradient>
                            <linearGradient id="paint2_linear_513_2185" x1="7.66602" y1="1.00081" x2="8.05247" y2="1.58963" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#5258F2"/>
                                <stop offset="1" stop-color="#A64FEB"/>
                            </linearGradient>
                            <linearGradient id="paint3_linear_513_2185" x1="10.9973" y1="0.992122" x2="11.4428" y2="1.60314" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#5258F2"/>
                                <stop offset="1" stop-color="#A64FEB"/>
                            </linearGradient>
                            <linearGradient id="paint4_linear_513_2185" x1="14.332" y1="0.98179" x2="14.8472" y2="1.61097" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#5258F2"/>
                                <stop offset="1" stop-color="#A64FEB"/>
                            </linearGradient>
                            <linearGradient id="paint5_linear_513_2185" x1="17.667" y1="0.96439" x2="18.2857" y2="1.60577" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#5258F2"/>
                                <stop offset="1" stop-color="#A64FEB"/>
                            </linearGradient>
                            <linearGradient id="paint6_linear_513_2185" x1="20.998" y1="0.933394" x2="21.7729" y2="1.56125" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#5258F2"/>
                                <stop offset="1" stop-color="#A64FEB"/>
                            </linearGradient>
                            <linearGradient id="paint7_linear_513_2185" x1="24.333" y1="0.859734" x2="25.3487" y2="1.38138" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#5258F2"/>
                                <stop offset="1" stop-color="#A64FEB"/>
                            </linearGradient>
                            <linearGradient id="paint8_linear_513_2185" x1="27.6641" y1="0.672385" x2="28.8684" y2="0.981245" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#5258F2"/>
                                <stop offset="1" stop-color="#A64FEB"/>
                            </linearGradient>
                            <linearGradient id="paint9_linear_513_2185" x1="30.999" y1="0.40326" x2="32.2403" y2="0.63233" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#5258F2"/>
                                <stop offset="1" stop-color="#A64FEB"/>
                            </linearGradient>
                            <linearGradient id="paint10_linear_513_2185" x1="34.3342" y1="0.236341" x2="35.5727" y2="0.47263" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#5258F2"/>
                                <stop offset="1" stop-color="#A64FEB"/>
                            </linearGradient>
                            <linearGradient id="paint11_linear_513_2185" x1="37.665" y1="0.550669" x2="38.8353" y2="0.914867" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#5258F2"/>
                                <stop offset="1" stop-color="#A64FEB"/>
                            </linearGradient>
                            <linearGradient id="paint12_linear_513_2185" x1="41" y1="0.815531" x2="41.9685" y2="1.36797" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#5258F2"/>
                                <stop offset="1" stop-color="#A64FEB"/>
                            </linearGradient>
                            <linearGradient id="paint13_linear_513_2185" x1="44.3311" y1="0.918207" x2="45.0874" y2="1.5497" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#5258F2"/>
                                <stop offset="1" stop-color="#A64FEB"/>
                            </linearGradient>
                            <linearGradient id="paint14_linear_513_2185" x1="47.666" y1="0.93824" x2="48.4023" y2="1.57305" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#5258F2"/>
                                <stop offset="1" stop-color="#A64FEB"/>
                            </linearGradient>
                            <linearGradient id="paint15_linear_513_2185" x1="51.0007" y1="0.82367" x2="51.9929" y2="1.36142" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#5258F2"/>
                                <stop offset="1" stop-color="#A64FEB"/>
                            </linearGradient>
                            <linearGradient id="paint16_linear_513_2185" x1="54.332" y1="0.395879" x2="55.5445" y2="0.689543" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#5258F2"/>
                                <stop offset="1" stop-color="#A64FEB"/>
                            </linearGradient>
                            <linearGradient id="paint17_linear_513_2185" x1="57.6672" y1="-0.232822" x2="58.9308" y2="-0.0736027" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#5258F2"/>
                                <stop offset="1" stop-color="#A64FEB"/>
                            </linearGradient>
                            <linearGradient id="paint18_linear_513_2185" x1="60.998" y1="-0.361583" x2="62.2669" y2="-0.224943" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#5258F2"/>
                                <stop offset="1" stop-color="#A64FEB"/>
                            </linearGradient>
                            <linearGradient id="paint19_linear_513_2185" x1="64.3333" y1="-0.0150644" x2="65.587" y2="0.178371" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#5258F2"/>
                                <stop offset="1" stop-color="#A64FEB"/>
                            </linearGradient>
                            <linearGradient id="paint20_linear_513_2185" x1="67.6643" y1="0.547599" x2="68.8493" y2="0.889343" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#5258F2"/>
                                <stop offset="1" stop-color="#A64FEB"/>
                            </linearGradient>
                            <linearGradient id="paint21_linear_513_2185" x1="70.9993" y1="0.839137" x2="72.0361" y2="1.34497" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#5258F2"/>
                                <stop offset="1" stop-color="#A64FEB"/>
                            </linearGradient>
                            <linearGradient id="paint22_linear_513_2185" x1="74.334" y1="0.923476" x2="75.2845" y2="1.48612" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#5258F2"/>
                                <stop offset="1" stop-color="#A64FEB"/>
                            </linearGradient>
                            <linearGradient id="paint23_linear_513_2185" x1="77.665" y1="0.901313" x2="78.6719" y2="1.42916" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#5258F2"/>
                                <stop offset="1" stop-color="#A64FEB"/>
                            </linearGradient>
                            <linearGradient id="paint24_linear_513_2185" x1="80.9998" y1="0.778341" x2="82.1318" y2="1.19252" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#5258F2"/>
                                <stop offset="1" stop-color="#A64FEB"/>
                            </linearGradient>
                            <linearGradient id="paint25_linear_513_2185" x1="84.3311" y1="0.519113" x2="85.5536" y2="0.792302" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#5258F2"/>
                                <stop offset="1" stop-color="#A64FEB"/>
                            </linearGradient>
                            <linearGradient id="paint26_linear_513_2185" x1="87.6658" y1="0.202904" x2="88.9239" y2="0.381914" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#5258F2"/>
                                <stop offset="1" stop-color="#A64FEB"/>
                            </linearGradient>
                            <linearGradient id="paint27_linear_513_2185" x1="91.0012" y1="0.215466" x2="92.2604" y2="0.390787" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#5258F2"/>
                                <stop offset="1" stop-color="#A64FEB"/>
                            </linearGradient>
                            <linearGradient id="paint28_linear_513_2185" x1="94.3323" y1="0.509804" x2="95.5695" y2="0.749316" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#5258F2"/>
                                <stop offset="1" stop-color="#A64FEB"/>
                            </linearGradient>
                            <linearGradient id="paint29_linear_513_2185" x1="97.667" y1="0.797322" x2="98.814" y2="1.19313" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#5258F2"/>
                                <stop offset="1" stop-color="#A64FEB"/>
                            </linearGradient>
                            <linearGradient id="paint30_linear_513_2185" x1="100.998" y1="0.944505" x2="101.937" y2="1.5133" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#5258F2"/>
                                <stop offset="1" stop-color="#A64FEB"/>
                            </linearGradient>
                            <linearGradient id="paint31_linear_513_2185" x1="104.333" y1="1.00157" x2="105.098" y2="1.6315" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#5258F2"/>
                                <stop offset="1" stop-color="#A64FEB"/>
                            </linearGradient>
                            <linearGradient id="paint32_linear_513_2185" x1="107.668" y1="1.03339" x2="108.325" y2="1.67501" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#5258F2"/>
                                <stop offset="1" stop-color="#A64FEB"/>
                            </linearGradient>
                            <linearGradient id="paint33_linear_513_2185" x1="110.999" y1="1.05582" x2="111.555" y2="1.69182" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#5258F2"/>
                                <stop offset="1" stop-color="#A64FEB"/>
                            </linearGradient>
                            <linearGradient id="paint34_linear_513_2185" x1="114.334" y1="1.07392" x2="114.784" y2="1.68643" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#5258F2"/>
                                <stop offset="1" stop-color="#A64FEB"/>
                            </linearGradient>
                            <linearGradient id="paint35_linear_513_2185" x1="117.665" y1="1.09307" x2="117.979" y2="1.64491" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#5258F2"/>
                                <stop offset="1" stop-color="#A64FEB"/>
                            </linearGradient>
                            <linearGradient id="paint36_linear_513_2185" x1="121" y1="1.13639" x2="121.054" y2="1.39341" gradientUnits="userSpaceOnUse">
                                <stop stop-color="#5258F2"/>
                                <stop offset="1" stop-color="#A64FEB"/>
                            </linearGradient>
                        </defs>
                    </svg>
                    }

                    {palyState === 2 &&
                        <svg width="122" height="48" viewBox="0 0 122 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M1 25.4863V26.8328" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M4.3313 24.8359V27.6498" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M7.66602 24.6401V27.9405" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M10.9973 24.5024V28.1683" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M14.332 24.333V28.4504" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M17.667 24.0537V28.9048" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M20.998 23.5459V29.7515" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M24.333 22.4414V32.2322" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M27.6641 19.7861V39.3946" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M30.999 14.731V41.9802" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M34.3342 10.5679V36.9249" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M37.665 16.2275V32.3852" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M41 21.2046V30.0198" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M44.3311 23.1646V29.1874" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M47.666 23.5918V29.4238" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M51.0007 21.4819V30.7594" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M54.332 13.3628V34.1243" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M57.6672 1.83008V41.7351" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M60.998 0V46.6965" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M64.3333 5.70557V38.2977" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M67.6643 16.3887V33.8256" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M70.9993 22.0542V32.361" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M74.334 23.7397V32.2352" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M77.665 23.4062V32.9982" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M80.9998 21.2266V34.9708" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M84.3311 16.644V39.1471" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M87.6658 11.438V46.7795" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M91.0012 11.8843V47.9998" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M94.3323 17.0635V43.0388" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M97.667 21.8442V36.4162" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M100.998 24.2017V32.5036" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M104.333 25.1748V31.2783" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M107.668 25.7549V30.9016" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M110.999 26.1475V30.5416" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M114.334 26.4565V30.152" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M117.665 26.7764V29.6386" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                            <path d="M121 27.4644V28.5152" stroke="white" stroke-width="0.81391" stroke-miterlimit="10"/>
                        </svg>
                    }

                </div>
            </div>
        );
    }
}

export default AudioPlayBtn;

