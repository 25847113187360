import React from "react";
import SigNIn from "../components/SignIn";
import NavBar from "../components/NavBar";
import gifpos from "../assets/Gif/Gif__signup.gif";

function SignIn() {
  return (
    <div className="position-relative">
      <img
        className="position-absolute start-50 top-50 signup_bg_image z_index1 "
        src={gifpos}
        alt="err"
      />
      <div className="min-vh-100 d-flex flex-column position-relative z_index4">
        <div className=" position-relative">
          <NavBar
            py_2="py-xl-2"
            bg_transparent="bg-transparent"
            SignUpfix="Nav_links_common_fix"
          />
        </div>
        <div className="d-flex flex-column justify-content-center align-items-center flex-grow-1 position-relative">
          <SigNIn />
        </div>
      </div>
    </div>
  );
}

export default SignIn;
