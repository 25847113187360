import React from "react";
import Slider from "react-slick";
import { benefits } from "../pages/MapData";

const EndlessBenefits = () => {
  const settings = {
    arrows: false,
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          infinite: true,
        },
      },
      {
        breakpoint: 992,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 576,
        settings: {
          slidesToShow: 1,
          infinite: true,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <section className="py-lg-5 pb-5 pt-3  sections_bg_image px-1 sec-endless-benefits common_active_dot">
      <div className="common-black-container  container-custom mx-auto py-5 my-xl-5">
        <div className="container  container-custom">
          <div className="pb-5">
            <h2 className="clr_secondary text-center fs_4xl ff_work fw-semibold line_height122 letter-spacing-05">
              Our Roadmap
            </h2>
            <Slider className="mt-1 overflow-y-visible" {...settings}>
              {benefits.map((val) => {
                return (
                  <div className="px-2 pt-lg-5 pt-4 d-flex align-items-center justify-content-center">
                    <div className="sec-endless-benefits-cards cursor_pointer transition300 d-flex flex-column justify-content-start py-5">
                      <div className="serial-number-bg d-flex  justify-content-center">
                        <p className="mb-0 clr_secondary ff_work fs_xxl fw-semibold line_height130">
                          {val.sNo}
                        </p>
                      </div>
                      <div>
                        <p className="mb-0 clr_secondary ff_work fw-semibold line_height130 fs_xl mt-4">
                          {val.head}
                        </p>
                        <p className="mb-0 clr_secondary ff_inter fw-medium line_height150 fs_md opacity06 mt-2 pt-1">
                          {val.para}
                        </p>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Slider>
          </div>
        </div>
      </div>
    </section>
  );
};

export default EndlessBenefits;
