
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react'
import { Alert, Space, Spin,Modal } from 'antd';
import PropTypes from 'prop-types'
import Lottie from "react-lottie";
import animationData from "../assets/LottieGIf/loading.json";

export const DlgProgress = forwardRef(function (props, ref) {

  useImperativeHandle(ref, () => ({
    changeProgress(p){
      //const progressbar = document.getElementById("progressBar22");
      //progressbar.style.width = Percentage(p, 100) + "%"
      //setProgress(p);
     // setIsModalOpen(true);
      function Percentage(current, total) {
        return current / total * 100
      }
    }
  }))

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const [isModalOpen, setIsModalOpen] = useState(true);
  const [nProgress, setProgress] = useState(10);
  const showModal = () => {
    setIsModalOpen(true);
  };

  const changeProgress=(p)=>{
    const progressbar = document.getElementById("progressBar22");
    progressbar.style.width = Percentage(p, 100) + "%"
    setIsModalOpen(true);
    function Percentage(current, total) {
      return current / total * 100
    }
  }
  const handleOk = () => {
    //setIsModalOpen(false);
    props.hide();
  };
  const handleCancel = () => {
    //setIsModalOpen(false);
    props.hide();
  };
  return (
      <>
        <Modal centered title=" " width={200} open={isModalOpen} onOk={handleOk} onCancel={handleCancel} maskClosable={false}
               cancelButtonProps={{ style: { display: 'none' } }}  okButtonProps={{ style: { display: 'none' } }} closeIcon=' ' >

          <div className="center-container   ">
            <Lottie
                style={{ pointerEvents: "none" }}
                options={defaultOptions}
                width="100%"
            />
          </div>
        </Modal>

      </>
  );
});


