import React, {Component} from "react";
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {slectHeadPic, voiceContainer} from "../pages/MapData";
import RoleItem from "./RoleItem"
import {CloneVoiceContainer} from "./CloneResultContainer";
import {AddVoiceBtn, DialogAdd} from "./AddVoiceDlg";
import {getFileInfoList} from "./public/main";




class RoleItemContainer extends Component{
    constructor(props) {
        super(props);
        this.state = {
            voiceNum: 0,
            selectedDivIndex: null, // 存储选中的div的索引，初始为null表示没有选中的
            isHovered: false,
        }

        this.listContainer = [
            {name:"Taylor", promptID: "67a8cd5d-a7c8-4a69-b582-dae3db072abe"},
            {name:"Musk", promptID: "d41e26cb-f024-4134-b9b9-4fb99d1857ba"},
            {name:"Kobe", promptID: "9ab73624-d308-4269-949e-0597270eaa8f"},
            {name:"Long", promptID: "b8fee791-99c5-4505-8f1b-c68f8e7b91ae"},
            {name:"Kardashian", promptID: "dd09e94d-9193-412a-9758-c13cec0b8b5a"},
            //{name:"Default", promptID: ""}
        ]
    }


    componentDidMount() {
        // 在组件挂载后执行的代码
        console.log('组件已挂载');

        getFileInfoList().then((retDat) => {
            if (retDat.code == 200) {
                for (let i = 0; i < retDat.data.length; i++) {
                    let obj = {
                        promptID: retDat.data[i].id,
                        //file_name: retDat.data[i].file_name,
                        file_s3_name: retDat.data[i].file_s3_name,
                        name: retDat.data[i].voice_name,
                    }
                    console.log(obj)
                    this.addItem(obj)
                }
            }
        });
    }


    handleMouseEnter = (divIndex) => {
        if(divIndex === 5)
            this.setState({ isHovered: true });
    };

    handleMouseLeave = (divIndex) => {
        if(divIndex === 5)
            this.setState({ isHovered: false });
    };


    // 处理div的点击事件，切换选中状态
    handleDivClick = (divIndex) => {
        console.log(this.listContainer)
        if(divIndex < this.listContainer.length){
            this.props.selectPromptID(divIndex, this.listContainer[divIndex].promptID);
            this.setState({ selectedDivIndex: divIndex });
        }else {
            this.props.selectPromptID(divIndex, this.listContainer[0].promptID);
            this.setState({ selectedDivIndex: 0 });
        }
    };


    addItem(obj) {
        this.listContainer.push(obj);
        this.handleDivClick(this.listContainer.length - 1)
        this.setState({
            voiceNum: this.state.voiceNum+1,
        })
    }

    deleteUpdate(){
        this.listContainer.pop()
        this.setState({
            voiceNum: this.state.voiceNum-1,
        })
    }

    render() {

        const { selectedDivIndex, voiceNum, isHovered } = this.state;

        const elementsUI = this.listContainer.map((item, index) => {
                return <div key={index} onClick={() => this.handleDivClick(index)} className="RoleItem"
                            onMouseEnter={() =>this.handleMouseEnter(index)}
                            onMouseLeave={() =>this.handleMouseLeave(index)}  >
                    <RoleItem key={index} isMouseHover={isHovered}  selected={selectedDivIndex === index ? true:false} whoStr={item.name}  userDefine={index === 5 ? true:false} promptID={item.promptID}  deleteUpdate={this.deleteUpdate.bind(this)}/>
                </div>;
            }
        )

        return (
            <div className="align-left-column " >
                <h2 className="   ff_work  line_height122 letter-spacing-05  addVoiceBtnBorder-margin ">
                    Voice Prompt Library
                </h2>
                {elementsUI}

                {voiceNum === 0 &&
                    <div className={"addVoiceBtnBorder-margin"}>
                        <DialogAdd small={3} addItem={this.addItem.bind(this)}>
                        </DialogAdd>
                    </div>
                }

                {voiceNum !== 0 &&
                    <div className={"addVoiceBtnBorder-margin"}>
                        <DialogAdd small={2} addItem={this.addItem.bind(this)}>
                        </DialogAdd>
                    </div>
                }


            </div>
        );
    }
};

export default RoleItemContainer;
