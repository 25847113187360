
// const ipPort = "api.speaking.ai"//"52.86.98.254:8162"
//
// const url = `https://${ipPort}`;
// const wsUrl = `wss://${ipPort}`;
//54.161.165.241
//const ipPort= "d3016l5l6hlzxl.cloudfront.net"
const ipPort = "api.speakingai.top"//"52.86.98.254:8162"  dxkhn4n09mdgt.cloudfront.net  speakingai-api.elehear.cn  127.0.0.1
const url = `https://${ipPort}`; 
const wsUrl = `wss://${ipPort}`;//`wss://${ipPort}`; 本地测试用ws

// const ipPort = "52.86.98.254:8162"//"52.86.98.254:8162"  dxkhn4n09mdgt.cloudfront.net
// const url = `http://${ipPort}`;
// const wsUrl = `ws://${ipPort}`;

//const githubLoginUrl = 'https://github.com/login/oauth/authorize?client_id=b465514450618fc1ff59&redirect_uri=http://54.161.165.241:8162/users/redirect&scope=user:email';
const githubLoginUrl = 'https://github.com/login/oauth/authorize?client_id=b465514450618fc1ff59&redirect_uri=https://api.speakingai.top/users/redirect&scope=user:email';

const googleClientId = '247250024171-ee0d8qb39ugdl63i55ku513gqj4mknot.apps.googleusercontent.com';
const githubClientId = 'b465514450618fc1ff59';

const aws_access_key_id = "AKIAU7TPKMFOOA5EWN6H"
const aws_secret_access_key = "NULUoLnkr7yGU0gdQVIh4hSfgbzt9NDDvfdgsS7q"
const aws_region = "us-east-1"
const s3_bucket_name = "node-sdk-sample-555cf59b-0f85-4625-ae9b-3c2f33c1d5b5"


const loginUseTimes = 20;
const touristUseTimes = 5;
const expiredTime = 12 * 60 * 60


module.exports = {
    aws_access_key_id,
    aws_secret_access_key,
    aws_region,
    s3_bucket_name,
    url,
    wsUrl,
    githubLoginUrl,
    googleClientId,
    githubClientId,
    loginUseTimes,
    touristUseTimes,
    expiredTime,
}
