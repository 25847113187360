import React, {useRef, useState} from "react";
import { Col, Row } from "react-bootstrap";


import {voiceContainer, DialogAdd, AddVoiceBtn} from "./AddVoiceDlg";

import {CardsContainer, CardsContainerAIRole} from "./CardsContainer";

function base64ToBlob(base64, mimeType) {
  const byteCharacters = atob(base64);
  const byteNumbers = new Array(byteCharacters.length);
  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }
  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mimeType });
}

const FavoriteCelebrities = () => {
  const [name, setName] = useState("Taylor");

  const changeName = (nameTemp) =>{
      setName(nameTemp)
  }


  return (
      <div className="container  container-custom py-lg-5 py-5 pb-4 ">
        <h2 className="text-center clr_off_black fs_4xl ff_work fw-semibold line_height122 letter-spacing-05 text-color-blue ">
            Hear Our Voice Sample
        </h2>

        <div className="box_settings_celebrities mt-4 pt-md-3 ">
            <div className="generate-language-box   text-sm-start  py-5 mt-lg-5 mt-4">
                <CardsContainer changeName={changeName.bind()} >
                </CardsContainer>
            </div>
            <div className="generate-language-box   text-sm-start  py-4 height570 mt-lg-5 mt-4 ">
                {name === "Taylor" &&
                    <CardsContainerAIRole  name={name} >
                    </CardsContainerAIRole>
                }
                {name === "Musk" &&
                    <CardsContainerAIRole  name={name} >
                    </CardsContainerAIRole>
                }
                {name === "Kobe" &&
                    <CardsContainerAIRole  name={name} >
                    </CardsContainerAIRole>
                }

            </div>
        </div>

      </div>
  );
};

export default FavoriteCelebrities;
