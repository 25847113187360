import React from "react";
import { Link } from "react-router-dom";
import Lottie from "react-lottie";
import animationData from "../assets/LottieGIf/HeroLottiNew.json";
import {login} from "./public/login_register";
function Home(props) {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const handleClick = event => {
    window.location.href = "/GenerateVoice";
  };

  return (
    <>
      <section className="sections_bg_image w-100 d-lg-flex flex-column justify-content-center py-lg-5 pt-5 pb-md-4 pb-2 py-xl-0  ">
        <div className="container container-custom position-relative hero_my__4xl">
          <div className="d-flex flex-column justify-content-center ">
            <div className="row align-items-center ">
              <div className="col-lg-6 position-relative">
                <div className="mt-xl-5 position-relative z-1">
                  <h1
                    style={{ letterSpacing: "-0.66px" }}
                    className=" ff_work fs_5xl fw-semibold line_height122 clr_off_black">
                    Speak Naturally with Conversational Generative Voice AI
                  </h1>
                  <p
                    style={{ letterSpacing: "0.198px" }}
                    className=" clr_primary fs_lg ff_work fw-medium line_height150 mb-4 pb-lg-3">
                    Experience state-of-the-art text-to-speech that speaks with natural emotion and offers zero-shot
                    voice cloning through large language model techniques.
                  </p>
                  <Link>
                    <button className="btn_common d-inline-block" onClick={handleClick}>
                      Try for free
                    </button>
                  </Link>
                </div>
              </div>
              <div className="col-lg-6 d-flex justify-content-center">
                <div className="Svg_hero_main_pos_custom">
                  <Lottie
                      style={{ pointerEvents: "none", minHeight: "150px" }}
                      options={defaultOptions}
                      width="100%"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;
