import "./App.css";
import { Route, Routes, Navigate, Outlet, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import AOS from "aos";
import "bootstrap/dist/css/bootstrap.min.css";
import "aos/dist/aos.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import LandingPage from "./pages/LandingPage";
import SignIn from "./pages/SignIn";
import SignUp from "./pages/SignUp";
import ForgetPassword from "./pages/ForgetPassword";
import Partnership from "./pages/Partnership ";
import AddVoice from "./pages/AddVoice";
import GenerateVoice from "./pages/GenerateVoice";
import Avtar from "./pages/Avtar";
import AboutPage from "./pages/AboutPage";
import BackToTop from "./components/BackToTop";
import GoogleCallback from "./components/GoogleCallback";
import TwitterContent from "./components/TwitterContent"
import Swal from "sweetalert2";

import ReactGA from 'react-ga';


// 设置路由中间件
const trackPage = page => {
    ReactGA.set({ page });
    ReactGA.pageview(page);
};

function isAuth(){
  return localStorage.getItem("SpeakingAiLogin") === "1" ?  true : false
}
function AuthRoute(props) {
  //以下的代码是为了第三方登录重定向使用
  const params = new URLSearchParams(window.location.search);
  const paramValue = params.get('email');
  const paramAuth = params.get('authorization')
  console.log(paramValue)
  if(paramValue !== null && paramValue.length > 5){
    localStorage.setItem("SpeakingAiLogin", "1")
    if(paramAuth !== null && paramAuth.length > 10){
      window.g_authorization = paramAuth;
      localStorage.setItem('SpeakingAiToken', paramAuth )
      Swal.fire({
        position: "center",
        icon: "success",
        title: "Sign In successfully",
        showConfirmButton: false,
        timer: 2500,
      });
    }
  }
  console.log(paramValue )
  //以上的代码是为了第三方登录重定向使用

  if (!isAuth()) {
    return <Navigate to="/SignIn" />
  }
  else{
    console.log(props.children )
  }
  return props.children ? props.children : <Outlet/>
}


function App() {

    const location = useLocation();

  useEffect(() => {
    AOS.init({
      once: true,
      duration: 1000,
    });
      ReactGA.initialize('G-2N78TP826M'); // 用你的实际跟踪ID替代
      ReactGA.pageview(location.pathname + location.search);
  });

  return (
      <>
        <BackToTop />
        <div style={{ maxWidth: "1920px" }} className="overflow-hidden mx-auto">
          <Routes>

              <Route path="/" element={<LandingPage />} />
              <Route path="/login/GoogleCall" element={<GoogleCallback />} />
              <Route path="/SignIn" element={<SignIn />} />
              <Route path="/SignUp" element={<SignUp />} />
              <Route path="/ForgetPassword" element={<ForgetPassword />} />
              <Route path="/Partnership" element={<Partnership />} />
              <Route path="/AboutPage" element={<AboutPage />} />
              <Route path="/Twitter" element={<TwitterContent />} />

              {/*the blow page must be login can be access*/}
              <Route path="/AddVoice" element={<AuthRoute> <AddVoice /> </AuthRoute>}  />
              <Route path="/GenerateVoice" element={<AuthRoute> <GenerateVoice /> </AuthRoute>}  />
              <Route path="/Avtar" element={<AuthRoute> <Avtar /> </AuthRoute>}  />

          </Routes>
        </div>
      </>
  );
}

// function App() {
//   useEffect(() => {
//     // 在组件加载时自动跳转到其他页面
//     window.location.href = "https://yeeqohbyi7d.typeform.com/to/RNEUexJm?typeform-source=speaking.ai"
//   }, []); // 空数组表示仅在组件加载时执行一次

//   return (
//     <div>
      
//       {/* 其他内容 */}
//     </div>
//   );
// }


export default App;
