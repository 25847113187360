import React, {useEffect} from "react";
import NavBar from "../components/NavBar";
import Home from "../components/Home";
import FavoriteCelebrities from "../components/FavoriteCelebrities";
import VoiceWithEmotion from "../components/VoiceWithEmotion";
import Footer from "../components/Footer";
import Community from "../components/Community";
import { SvgFiles } from "./DataSvg";
import UltraRealVoice from "../components/UltraRealVoice";
import PreLoader from "../components/PreLoader";

function LandingPage() {
  document.documentElement.scrollTop = 0;

  return (
    <>
      {/*<PreLoader />*/}
      <div className="d-flex flex-column min_vh_100 position-relative">
        {SvgFiles.map((data, i) => {
          return (
            <div>
              <sapn className="svg_image_hero_position1 position-absolute opacity-25">
                {data.svgBrain}
              </sapn>
              <sapn className="svg_image_hero_position2 position-absolute opacity-25">
                {data.svgSimple}
              </sapn>
              <sapn className="svg_image_hero_position3 position-absolute opacity-25">
                {data.svgBrain}
              </sapn>
            </div>
          );
        })}
        <div className="bg_blur_nav top-0 w-100">
          <NavBar NavFix="Nav_links_common_fix" />
        </div>
        <div className="d-flex flex-grow-1 justify-content-center ">
          <Home />
        </div>
      </div>
      <FavoriteCelebrities />
      <UltraRealVoice />
        {/*<VoiceWithEmotion />*/}

      <Community />
      <Footer />
    </>
  );
}

export default LandingPage;
