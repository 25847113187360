import React from "react";
import { Container } from "react-bootstrap";
import Accordion from "react-bootstrap/Accordion";
const Faq = () => {
  return (
    <section className="sections_bg_image pt-lg-5 pt-4 pb-5 pb-lg-0">
      <Container className=" container-custom">
        <h3 className="clr_off_black fs_4xl ff_work fw-semibold line_height122 letter_spac_minus_05 text-center">
          Frequently Asked Questions
        </h3>
        <div className="max_w_826 mx-auto py-lg-5 ">
          <Accordion defaultActiveKey="0">
            <Accordion.Item eventKey="0" className="border_btm_faq">
              <Accordion.Header>
                Q1.What kind of audio sample should I provide to better clone my voice instantly?
              </Accordion.Header>
              <Accordion.Body className="max_w_729">
                For optimal voice cloning results, it is recommended to provide a voice prompt of high resolution,
                lasting between 5 to 10 seconds. Minimizing background noise significantly enhances the quality of
                the cloned voice. It's worth noting that extending the duration of the voice prompt does not necessarily
                lead to better cloning accuracy.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="1" className="border_btm_faq">
              <Accordion.Header>
                Q2.Can I control the emotion of my voice?
              </Accordion.Header>
              <Accordion.Body className="max_w_729">
                Indeed. You can influence the emotional tone by using voice prompts imbued with specific emotions.
                Additionally, our advanced model possesses the capability to autonomously determine and replicate the
                appropriate emotional tone based on the text's content.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="2" className="border_btm_faq">
              <Accordion.Header>
                Q3.Can I influence the pauses in the generated voice?
              </Accordion.Header>
              <Accordion.Body className="max_w_729">
                Absolutely. You can manage pauses by incorporating appropriate punctuation like commas or periods.
                These punctuations aid our model in achieving a more nuanced understanding and rendition of the text.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="3" className="border_btm_faq">
              <Accordion.Header>
                Q4.Which language do you currently support? Will you support more languages?
              </Accordion.Header>
              <Accordion.Body className="max_w_729">
                At present, our platform supports both English and Chinese. We anticipate introducing additional
                languages in upcoming releases.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="4" className="border_btm_faq">
              <Accordion.Header>
                Q5.Can I do cross-language voice cloning now?
              </Accordion.Header>
              <Accordion.Body className="max_w_729">
                Our current model does have cross-language capabilities, but they are not yet mature. If you're
                interested in the finalized version of our cross-language voice features, we invite you to reach
                out via our Partnership page for early access.
              </Accordion.Body>
            </Accordion.Item>
            <Accordion.Item eventKey="5" className="border_btm_faq rounded-0">
              <Accordion.Header>Q6.Why sometimes is there some extra word in my generated voice?
              </Accordion.Header>
              <Accordion.Body className="max_w_729">
                Our Conversational Generative Voice model, having been trained on a vast corpus of diverse audio data,
                strives to reproduce speech that mirrors natural human interactions. As a result, it might occasionally
                integrate conversational fillers, such as "umm", "uh", or even laughter. Moreover, even if the text has
                minor grammatical oversights, the model is adept at delivering speech that remains contextually accurate.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="6" className="border_btm_faq rounded-0">
              <Accordion.Header>Q7.Why does it take a very long time to generate my voice?
              </Accordion.Header>
              <Accordion.Body className="max_w_729">
                We currently offer instant voice cloning at no charge to our users. Owing to the constraints of our
                computational resources, we decide to offer this service in an affordable cost. We're planning to
                unveil a premium plan for real-time voice generation in the near future. Concurrently, efforts are
                underway to enhance efficiency through model compression and acceleration.
              </Accordion.Body>
            </Accordion.Item>

            <Accordion.Item eventKey="7" className="border_btm_faq rounded-0">
              <Accordion.Header>Q8.Can I clone other people's voices?
              </Accordion.Header>
              <Accordion.Body className="max_w_729">
                You have the option to upload your own voice recordings or clone voices of others, provided you have
                obtained their explicit consent. We underscore the importance of ethical usage and strongly urge users
                to employ our platform responsibly.
              </Accordion.Body>
            </Accordion.Item>

          </Accordion>

        </div>
      </Container>
    </section>
  );
};

export default Faq;
