import React, {useEffect, useState, useRef} from "react";
import Swal from "sweetalert2";

import {
  forgetClick,
  sendCodeClickForget,
} from './public/login_register.js';


function BtnSendCode() {
  const [buttonName, setButtonName] = useState("Send Code");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const ref = useRef();

  let count = 61;
  function timeCall(){
    if (count == 0) {
      setButtonName("Send Code");
      count = 61;
      clearInterval(ref.current);
      setIsButtonDisabled(false);
    } else {
      if (count == 61) {
        sendCodeClickForget();
      }
      count--;
      var text = `Resend (${count})`;
      setButtonName(text)
    }
  }
  const handleClick = event => {
      setIsButtonDisabled(true);
      var timer =setInterval(() => {
        console.log('触发了')
        timeCall();
      }, 1000)
      ref.current=timer;
      console.log("启动定时")
  };
  return (
      <button id="send-code" className=" btn_common  sendCodeBtn " disabled={isButtonDisabled} onClick={handleClick} >
        {buttonName}
      </button>
  );
}


function SigNUp() {
  useEffect(() => {

  });

  const [formData, setFormData] = useState({
    Email: "",
    PassWord: "",
  });

  const FormSub = (event) => {
    event.preventDefault();
    setFormData({
      Email: "",
      PassWord: "",
    });

    Swal.fire({
      position: "center",
      icon: "success",
      title: "Change password successfully",
      showConfirmButton: false,
      timer: 2500,
    });
  };

  const handleClick = event => {
    forgetClick();
  };

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      <section className="w-100">
        <div className="container  container-custom">
          <div className="d-flex justify-content-center ">
            <div className="col-xl-5 col-lg-6 col-md-8 ">
              <div className="signup_box">
                <h2
                  className="fs_4xl ff_work fw-semibold clr_off_black line_height122 mb-sm-3 mb-2 pb-md-3"
                  style={{ letterSpacing: "-0.528px" }}>
                  Forget The Password
                </h2>
                <p className="clr_primary fs_md ff_work fw-medium opacity08 mb-md-4 mb-3">
                  Don't have an account?&nbsp;<a href="/SignUp">Sign up</a>
                </p>

                <form action="form" onSubmit={FormSub}>
                  <span
                    style={{ letterSpacing: " -0.176px", marginLeft: "1px" }}
                    className="d-block clr_primary fs_md ff_work fw-normal line_height150">
                    Email
                  </span>
                  <input
                    name="Email-f"
                    id="Email-f"
                    onChange={handleChange}
                    required
                    className="w-100 clr_primary fs_md ff_work fw-normal p-2 mb-3"
                    placeholder="Enter Your Email Address"
                    type="Email"
                  />

                  <div >
                    <span
                        style={{ letterSpacing: " -0.176px", marginLeft: "1px" }}
                        className="d-block clr_primary fs_md ff_work fw-normal line_height150 ">
                      E-mail verification code
                    </span>
                    <div  className="d-flex  gap-2 ">
                      <input
                          name="code-f"
                          id="code-f"
                          required
                          className="w-100 clr_primary fs_md ff_work fw-normal p-2 mb-lg-4 mb-3"
                          placeholder="Enter E-mail verification code"
                          type="text"
                      />
                      <BtnSendCode>
                      </BtnSendCode>
                    </div>
                  </div>

                  <span
                    style={{ letterSpacing: " -0.176px", marginLeft: "1px" }}
                    className="d-block clr_primary fs_md ff_work fw-normal line_height150 ">
                    Password
                  </span>
                  <input
                    name="PassWord-f"
                    id="PassWord-f"
                    required
                    className="w-100 clr_primary fs_md ff_work fw-normal p-2 mb-lg-4 mb-3"
                    placeholder="Enter Your Password"
                    type="password"
                  />

                  <span
                      style={{ letterSpacing: " -0.176px", marginLeft: "1px" }}
                      className="d-block clr_primary fs_md ff_work fw-normal line_height150 ">
                    Confirm Password
                  </span>
                  <input
                      name="Confirm-PassWord-f"
                      id="Confirm-PassWord-f"
                      required
                      className="w-100 clr_primary fs_md ff_work fw-normal p-2 mb-lg-4 mb-3"
                      placeholder="Confirm Your Password"
                      type="password"
                  />

                  <button id="sign-up" className="btn_common border-0 d-flex w-100 text-center justify-content-center position-relative marginTop40"  onClick={handleClick}
                          type="submit"
                  >
                    Change the password
                  </button>
                </form>

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default SigNUp;
