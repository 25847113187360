export const SvgFiles = [
  {
    svgSimple: (
      <svg
        className="d-none d-md-inline-block svg_file_simple"
        width="48"
        height="53"
        viewBox="0 0 48 53"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.3">
          <path
            d="M22.9846 0.846204C22.7769 0.973127 22.5115 1.23851 22.3846 1.4462C22.1538 1.82697 22.1538 2.08082 22.1538 26.4616C22.1538 50.8424 22.1538 51.0962 22.3846 51.477C22.7307 52.0308 23.25 52.3077 24 52.3077C24.75 52.3077 25.2692 52.0308 25.6153 51.477C25.8461 51.0962 25.8461 50.8424 25.8461 26.4616C25.8461 2.08082 25.8461 1.82697 25.6153 1.4462C25.2692 0.892358 24.75 0.615435 24 0.615435C23.573 0.615435 23.2384 0.684665 22.9846 0.846204Z"
            fill="url(#paint0_linear_363_1228)"
          />
          <path
            d="M8.21553 8.23089C8.00784 8.35782 7.74246 8.6232 7.61553 8.83089C7.38477 9.21166 7.38477 9.45397 7.38477 27.3847C7.38477 45.3155 7.38477 45.5578 7.61553 45.9386C7.96169 46.4924 8.48092 46.7693 9.23092 46.7693C9.98092 46.7693 10.5001 46.4924 10.8463 45.9386C11.0771 45.5578 11.0771 45.3155 11.0771 27.3847C11.0771 9.45397 11.0771 9.21166 10.8463 8.83089C10.5001 8.27705 9.98092 8.00012 9.23092 8.00012C8.804 8.00012 8.46938 8.06935 8.21553 8.23089Z"
            fill="url(#paint1_linear_363_1228)"
          />
          <path
            d="M37.7541 8.23089C37.5464 8.35782 37.281 8.6232 37.1541 8.83089C36.9233 9.21166 36.9233 9.45397 36.9233 27.3847C36.9233 45.3155 36.9233 45.5578 37.1541 45.9386C37.5003 46.4924 38.0195 46.7693 38.7695 46.7693C39.5195 46.7693 40.0387 46.4924 40.3849 45.9386C40.6156 45.5578 40.6156 45.3155 40.6156 27.3847C40.6156 9.45397 40.6156 9.21166 40.3849 8.83089C40.0387 8.27705 39.5195 8.00012 38.7695 8.00012C38.3426 8.00012 38.008 8.06935 37.7541 8.23089Z"
            fill="url(#paint2_linear_363_1228)"
          />
          <path
            d="M15.6003 13.7694C15.3926 13.8963 15.1272 14.1617 15.0003 14.3694C14.7695 14.7386 14.7695 14.9809 14.7695 27.3847C14.7695 39.7886 14.7695 40.0309 15.0003 40.4001C15.3465 40.954 15.8657 41.2309 16.6157 41.2309C17.3657 41.2309 17.8849 40.954 18.2311 40.4001C18.4618 40.0309 18.4618 39.7886 18.4618 27.3847C18.4618 14.9809 18.4618 14.7386 18.2311 14.3694C17.8849 13.8155 17.3657 13.5386 16.6157 13.5386C16.1888 13.5386 15.8541 13.6078 15.6003 13.7694Z"
            fill="url(#paint3_linear_363_1228)"
          />
          <path
            d="M30.3693 13.7694C30.1617 13.8963 29.8963 14.1617 29.7693 14.3694C29.5386 14.7386 29.5386 14.9809 29.5386 27.3847C29.5386 39.7886 29.5386 40.0309 29.7693 40.4001C30.1155 40.954 30.6347 41.2309 31.3847 41.2309C32.1347 41.2309 32.654 40.954 33.0001 40.4001C33.2309 40.0309 33.2309 39.7886 33.2309 27.3847C33.2309 14.9809 33.2309 14.7386 33.0001 14.3694C32.654 13.8155 32.1347 13.5386 31.3847 13.5386C30.9578 13.5386 30.6232 13.6078 30.3693 13.7694Z"
            fill="url(#paint4_linear_363_1228)"
          />
          <path
            d="M0.830769 19.3079C0.623077 19.4348 0.357692 19.7002 0.230769 19.9079C0.0115385 20.2771 0 20.5079 0 27.3848C0 34.2617 0.0115385 34.4925 0.230769 34.8617C0.576923 35.4156 1.09615 35.6925 1.84615 35.6925C2.59615 35.6925 3.11538 35.4156 3.46154 34.8617C3.68077 34.4925 3.69231 34.2617 3.69231 27.3848C3.69231 20.5079 3.68077 20.2771 3.46154 19.9079C3.11538 19.354 2.59615 19.0771 1.84615 19.0771C1.41923 19.0771 1.08462 19.1464 0.830769 19.3079Z"
            fill="url(#paint5_linear_363_1228)"
          />
          <path
            d="M45.1389 19.3079C44.9312 19.4348 44.6658 19.7002 44.5389 19.9079C44.3196 20.2771 44.3081 20.5079 44.3081 27.3848C44.3081 34.2617 44.3196 34.4925 44.5389 34.8617C44.885 35.4156 45.4043 35.6925 46.1543 35.6925C46.9043 35.6925 47.4235 35.4156 47.7696 34.8617C47.9889 34.4925 48.0004 34.2617 48.0004 27.3848C48.0004 20.5079 47.9889 20.2771 47.7696 19.9079C47.4235 19.354 46.9043 19.0771 46.1543 19.0771C45.7273 19.0771 45.3927 19.1464 45.1389 19.3079Z"
            fill="url(#paint6_linear_363_1228)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_363_1228"
            x1="22.1538"
            y1="25.7231"
            x2="25.8461"
            y2="25.7231"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#2825C7" />
            <stop offset="1" stopColor="#6A33DF" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_363_1228"
            x1="7.38477"
            y1="26.8309"
            x2="11.0771"
            y2="26.8309"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#2825C7" />
            <stop offset="1" stopColor="#6A33DF" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_363_1228"
            x1="36.9233"
            y1="26.8309"
            x2="40.6156"
            y2="26.8309"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#2825C7" />
            <stop offset="1" stopColor="#6A33DF" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_363_1228"
            x1="14.7695"
            y1="26.9891"
            x2="18.4618"
            y2="26.9891"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#2825C7" />
            <stop offset="1" stopColor="#6A33DF" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_363_1228"
            x1="29.5386"
            y1="26.9891"
            x2="33.2309"
            y2="26.9891"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#2825C7" />
            <stop offset="1" stopColor="#6A33DF" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_363_1228"
            x1="9.11754e-09"
            y1="27.1475"
            x2="3.69231"
            y2="27.1475"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#2825C7" />
            <stop offset="1" stopColor="#6A33DF" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_363_1228"
            x1="44.3081"
            y1="27.1475"
            x2="48.0004"
            y2="27.1475"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#2825C7" />
            <stop offset="1" stopColor="#6A33DF" />
          </linearGradient>
        </defs>
      </svg>
    ),
    svgBrain: (
      <svg
        width="50"
        height="40"
        viewBox="0 0 50 40"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <g opacity="0.3">
          <path
            d="M23.7272 0.252554C23.4889 0.395544 23.2387 0.705356 23.0957 1.01517C22.8693 1.52755 22.8693 1.9446 22.8931 20.1878C22.9289 38.8241 22.9289 38.848 23.1791 39.1697C23.62 39.7774 24.0132 39.98 24.6924 39.98C25.4669 39.98 26.0627 39.6583 26.3487 39.0982C26.5513 38.705 26.5632 37.5491 26.5632 19.9733C26.5632 1.51563 26.5632 1.2654 26.3249 0.872178C25.8125 0.0380685 24.5733 -0.259828 23.7272 0.252554Z"
            fill="url(#paint0_linear_1001_1016)"
          />
          <path
            d="M8.57031 8.92719C8.34391 9.04635 8.04601 9.29658 7.91494 9.47532C7.67662 9.79705 7.67662 9.96387 7.64087 19.8898C7.61704 29.7918 7.61704 29.9944 7.85536 30.3757C8.59414 31.5792 10.4054 31.5196 11.0488 30.2685L11.311 29.7442L11.2871 19.8302C11.2514 10.0354 11.2514 9.91621 11.0011 9.59448C10.3934 8.77229 9.36867 8.48631 8.57031 8.92719Z"
            fill="url(#paint1_linear_1001_1016)"
          />
          <path
            d="M31.5679 8.9273C31.3415 9.03454 31.0317 9.30861 30.8887 9.53501L30.6146 9.94015V19.9614C30.6146 29.7562 30.6265 29.9945 30.8529 30.3758C31.2342 31.0074 31.7466 31.2576 32.5569 31.198C33.1646 31.1623 33.3076 31.1027 33.6651 30.7333C33.9153 30.495 34.1298 30.1256 34.1894 29.8515C34.3562 29.1604 34.3443 10.6789 34.1775 10.0831C33.8676 8.93922 32.6284 8.39109 31.5679 8.9273Z"
            fill="url(#paint2_linear_1001_1016)"
          />
          <path
            d="M16.0773 13.503C15.8628 13.6579 15.5887 13.9677 15.4577 14.1941C15.2551 14.5873 15.2432 14.9329 15.267 20.1162C15.3028 25.4545 15.3147 25.6214 15.553 25.9431C16.0058 26.5508 16.3633 26.7415 17.0901 26.7415C17.817 26.7415 18.1745 26.5508 18.6273 25.9431C18.8656 25.6214 18.8775 25.4545 18.9133 20.1162C18.949 13.9677 18.9609 14.0749 18.1149 13.5149C17.5549 13.1336 16.6254 13.1336 16.0773 13.503Z"
            fill="url(#paint3_linear_1001_1016)"
          />
          <path
            d="M39.0987 13.4792C38.8842 13.6102 38.6101 13.8843 38.479 14.0988C38.2526 14.4801 38.2407 14.7184 38.2407 19.9852V25.4784L38.5148 25.8716C38.9557 26.527 39.3608 26.7534 40.0758 26.7534C40.5881 26.7534 40.8026 26.6938 41.1601 26.4198C41.9346 25.824 41.9346 25.8716 41.9346 19.9971C41.9346 14.7542 41.9346 14.7184 41.6725 14.1941C41.1959 13.2885 39.9804 12.943 39.0987 13.4792Z"
            fill="url(#paint4_linear_1001_1016)"
          />
          <path
            d="M0.944149 17.3994C0.717748 17.5185 0.407936 17.7926 0.25303 18.019C-0.00911882 18.4003 -0.0210347 18.5076 0.0147129 20.1162C0.0504604 21.7248 0.0623763 21.8202 0.348357 22.1181C0.765411 22.5709 1.21821 22.7734 1.77826 22.7734C2.3383 22.7734 2.7911 22.5709 3.20816 22.1181C3.49414 21.8202 3.50606 21.7248 3.5418 20.1162C3.57755 18.5076 3.56563 18.4003 3.30349 18.019C2.96984 17.5185 2.3383 17.173 1.77826 17.173C1.55186 17.173 1.17055 17.2683 0.944149 17.3994Z"
            fill="url(#paint5_linear_1001_1016)"
          />
          <path
            d="M46.9989 17.3399C46.558 17.5305 46.1648 17.9595 45.998 18.4004C45.9265 18.591 45.8669 19.3179 45.8669 20.0209C45.8669 21.5223 46.1171 22.1777 46.8201 22.559C47.3564 22.8569 48.3573 22.7973 48.822 22.4518C49.4178 22.0109 49.5608 21.5462 49.5608 19.9733C49.5608 18.2574 49.4059 17.8523 48.6075 17.4352C48.0117 17.1373 47.5232 17.1135 46.9989 17.3399Z"
            fill="url(#paint6_linear_1001_1016)"
          />
        </g>
        <defs>
          <linearGradient
            id="paint0_linear_1001_1016"
            x1="22.8829"
            y1="19.4189"
            x2="26.5632"
            y2="19.4189"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#2825C7" />
            <stop offset="1" stop-color="#6A33DF" />
          </linearGradient>
          <linearGradient
            id="paint1_linear_1001_1016"
            x1="7.6308"
            y1="19.6606"
            x2="11.311"
            y2="19.6606"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#2825C7" />
            <stop offset="1" stop-color="#6A33DF" />
          </linearGradient>
          <linearGradient
            id="paint2_linear_1001_1016"
            x1="30.6146"
            y1="19.6422"
            x2="34.3087"
            y2="19.6422"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#2825C7" />
            <stop offset="1" stop-color="#6A33DF" />
          </linearGradient>
          <linearGradient
            id="paint3_linear_1001_1016"
            x1="15.2581"
            y1="19.7914"
            x2="18.9254"
            y2="19.7914"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#2825C7" />
            <stop offset="1" stop-color="#6A33DF" />
          </linearGradient>
          <linearGradient
            id="paint4_linear_1001_1016"
            x1="38.2407"
            y1="19.7958"
            x2="41.9346"
            y2="19.7958"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#2825C7" />
            <stop offset="1" stop-color="#6A33DF" />
          </linearGradient>
          <linearGradient
            id="paint5_linear_1001_1016"
            x1="8.78223e-09"
            y1="19.8932"
            x2="3.55652"
            y2="19.8932"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#2825C7" />
            <stop offset="1" stop-color="#6A33DF" />
          </linearGradient>
          <linearGradient
            id="paint6_linear_1001_1016"
            x1="45.8669"
            y1="19.8901"
            x2="49.5608"
            y2="19.8901"
            gradientUnits="userSpaceOnUse">
            <stop stop-color="#2825C7" />
            <stop offset="1" stop-color="#6A33DF" />
          </linearGradient>
        </defs>
      </svg>
    ),
  },
];


export const svgRoleItem = [

]
