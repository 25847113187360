import Transforming from "../components/Transforming";
import NavBar from "../components/NavBar";
import AboutPage from "../components/About";
import Footer from "../components/FooterUser";
import {useEffect} from "react";

function AddVoice() {
  document.documentElement.scrollTop = 0;

  return (
    <>
      <NavBar />
      <AboutPage />
      <Footer />
    </>
  );
}

export default AddVoice;
