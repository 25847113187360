
import {url } from './config';
import md5 from 'js-md5';
import {redirect} from "react-router-dom";

window.g_authorization = ""
function httpRequest(subUrl, paramBody, callback, isPost = "POST") {
	let httpRequest = new XMLHttpRequest();
	//httpRequest.withCredentials = true;
	if (isPost == "GET") {
		httpRequest.open(isPost, url + subUrl, true);
		httpRequest.setRequestHeader("Accept", "application/json,text/plain,*/*");
		httpRequest.setRequestHeader("Content-type", "application/json");
		httpRequest.setRequestHeader("Authorization", paramBody.authorization);
		httpRequest.send(null);//�������� ��jsonд��send��
	}
	else {
		httpRequest.open(isPost, url + subUrl, true);
		httpRequest.setRequestHeader("Accept", "application/json,text/plain,*/*");
		httpRequest.setRequestHeader("Content-type", "application/json");
		let json = JSON.stringify(paramBody)
		httpRequest.send(json);//�������� ��jsonд��send��
    }

	/**
	 * ��ȡ���ݺ�Ĵ�������
	 */
	httpRequest.onreadystatechange = function () {//�����Ļص��ӿڣ��ɽ�����ɹ���Ҫִ�еĳ���д������
		if (httpRequest.readyState == 4) {//��֤�����Ƿ��ͳɹ�
			let json = httpRequest.responseText;//��ȡ������˷��ص�����
			callback(json);
		}
	};
}

window.onload = function () {

	getDefaultUsername();
}

function changeReactInputValue(inputDom,newText){
	let lastValue = inputDom.value;
	inputDom.value = newText;
	let event = new Event('input', { bubbles: true });
	event.simulated = true;
	let tracker = inputDom._valueTracker;
	if (tracker) {
		tracker.setValue(lastValue);
	}
	inputDom.dispatchEvent(event);
}

function getDefaultUsername() {
	let name = localStorage.getItem("SpeakingAiusername");
	let password = localStorage.getItem("SpeakingAipassword");

	//��ʼ���û�������
	let userIdDom = document.getElementById('Email-login');		//��ͨJS��ȡ�����Dom
	//changeReactInputValue(userIdDom,name);			//�ı�React��������ֵ
	let PassWordDom = document.getElementById('PassWord-login');		//��ͨJS��ȡ�����Dom
	//changeReactInputValue(PassWordDom,password);			//�ı�React��������ֵ
	return {
		name: name,
		pwd: password
	}
}

function isnull(strParam) {
	if (strParam == '' || strParam == undefined || strParam == null) {//�����������ո�ҲΪ��
		return true;
	} else {
		return false;
	}
}


function isCheckEmail(email) {

	if (!isnull(email)) {
		//var reg = /^[a-zA-Z0-9_-]+@([a-zA-Z0-9]+\.)+(com|cn|net|org|edu)$/;
		const reg = /^\w+((-\w+)|(\.\w+))*\@[A-Za-z0-9]+((\.|-)[A-Za-z0-9]+)*\.[A-Za-z0-9]+$/;
		if (!reg.test(email)) {//�����ַ����

			return false;
		}
		else
			return true;
	}
	return false;
}


function login() {

	return new Promise((resolve, reject) => {

		let userIdDom = document.getElementById('Email-login');
		const userName = userIdDom.value

		let pwd1Dom = document.getElementById('PassWord-login');
		const pwd = pwd1Dom.value
		if (isnull(userName)) {
			alert("user name is illegal!");
			return resolve(false);
		}

		if (isnull(pwd)) {
			alert("password is illegal!");
			return resolve(false);
		}

		if (!isCheckEmail(userName)) {
			alert("user name email address is illegal!");
			return resolve(false);
		}
		function callbackLogin(json) {
			console.log("��¼��",json)
			let obj = window.JSON.parse(json);

			if (obj.code === 200) {
				if (document.getElementById("Remember-me").checked) {
					localStorage.setItem("SpeakingAipassword", pwd);
				}
				localStorage.setItem("SpeakingAiusername", userName);
				localStorage.setItem("SpeakingAiLogin", "1");
				window.g_authorization = obj.authorization;
				localStorage.setItem('SpeakingAiToken', obj.authorization  )
				window.location.href = "/GenerateVoice?authorization=" + obj.authorization;
				return resolve({
					result: true,
					data: obj.authorization,
				});
			}else{
				alert(obj.msg);
				return resolve(false);
			}

		};

		let strData = {
			name: userName,
			password: md5(pwd)
		};

		console.log("login")
		httpRequest('/users/login', strData, callbackLogin);
	})
}

function loginGoogle(userName) {

	return new Promise((resolve, reject) => {
		console.log("login + " + userName)
		if (isnull(userName)) {
			alert("user name is illegal!");
			return resolve({
				result: false,
				data: "",
			});
		}


		if (!isCheckEmail(userName)) {
			alert("user name email address is illegal!");
			return resolve({
				result: false,
				data: "",
			});
		}

		function callbackLogin(json) {

			let obj = window.JSON.parse(json);

			if (obj.code === 200) {

				localStorage.setItem("SpeakingAiusername", userName);
				localStorage.setItem("SpeakingAiLogin", "1");
				window.g_authorization = obj.authorization;
				localStorage.setItem('SpeakingAiToken', obj.authorization  )
				window.location.href = "/GenerateVoice?authorization=" + obj.authorization;
				return resolve({
					result: true,
					data: obj.authorization,
				});
			}
			else{
				return resolve({
					result: false,
					data: "",
				});
			}

		};


		httpRequest(`/users/googleLogin?email=${userName}`, "", callbackLogin, "GET");
	})
}

//���������֤�밴ť
function sendCodeClick() {
	let userIdDom = document.getElementById('Email');
	const userName = userIdDom.value

	if (!isCheckEmail(userName)) {
		alert("user name email address is illegal!");
		return;
	}
	function callbackSendCode(json) {
		let obj = window.JSON.parse(json);
		if (obj.code === 200) {
			console.log("Verification code send successfully");
		}
		else
			alert(obj.msg);
	};

	let strData = {
		email: userName,
	};
	httpRequest('/users/SendEmailCode', strData, callbackSendCode);

}

//���ע�ᰴť
function registerClick() {

	return new Promise((resolve, reject) => {

		let userIdDom = document.getElementById('Email');
		const userName = userIdDom.value

		let pwd1Dom = document.getElementById('PassWord');
		const pwd1 = pwd1Dom.value

		let pwd2Dom = document.getElementById('Confirm-PassWord');
		const pwd2 = pwd2Dom.value

		let codeDom = document.getElementById('code');
		const code = codeDom.value

		if (!isCheckEmail(userName)) {
			return resolve({
				success: false,
                message: "Invalid Email Address."
			});
		}

		if (pwd1 !== pwd2) {
			return resolve({
				success: false,
                message: "Password not match."
			})
		}
		else {
			function callbackRegister(json) {
				let obj = window.JSON.parse(json);
				if (obj.code === 200) {
					localStorage.setItem("SpeakingAiusername", userName);
					return resolve({
						success: true,
						message: "Register user success."
					});
				}
				else{
					return resolve({
						success: false,
						message: obj.msg
					});
				}
			}

			let strData = {
				userName: userName,
				userPwd: md5(pwd1),
				emailCode: code
			};
			httpRequest('/users/register', strData, callbackRegister);
		}

	})


}


//���������֤�밴ť
function sendCodeClickForget() {

	let userIdDom = document.getElementById('Email-f');
	const userName = userIdDom.value

	if (!isCheckEmail(userName)) {
		alert("user name is not meet specifications!");
		return {result: false,
				data: "user name must be email!"
		}
	}

	function callbackSendCode(json) {
		let obj = window.JSON.parse(json);
		if (obj.code === 200) {

			return {
				result: true,
				data: "Verification code send successfully"
			}
		}
		else{
			return {
				result: false,
				data: obj.msg
			}
		}
	};

	var strData = {
		email: userName,
	};
	httpRequest('/users/SendEmailCodeForget', strData, callbackSendCode);

}

//����������밴ť
function forgetClick() {
	let userIdDom = document.getElementById('Email-f');
	const userName = userIdDom.value

	let pwd1Dom = document.getElementById('PassWord-f');
	const pwd1 = pwd1Dom.value

	let pwd2Dom = document.getElementById('Confirm-PassWord-f');
	const pwd2 = pwd2Dom.value

	let codeDom = document.getElementById('code-f');
	const code = codeDom.value

	if (pwd1 != pwd2) {
		return{
			result: false,
			data:"password mismatch"
		}
	}
	else {
		function callbackRegister(json) {
			let obj = window.JSON.parse(json);
			if (obj.code === 200) {

				window.location.href = "/SignIn"
			}
			else {
				console.log(json);
				alert("modify password , " + obj.msg);
			}
		};

		let strData = {
			userName: userName,
			userPwd: md5(pwd1),
			emailCode: code
		};
		httpRequest('/users/forgetPwd', strData, callbackRegister);
	}
}


var countdown = 61;
function settime(val) {

	if (countdown == 0) {
		val.removeAttribute("disabled");
		val.value = "Send Code";
		countdown = 61;
	} else {
		if (countdown == 61) {
			sendCodeClick();
			countdown--;
		}
		let userIdDom = document.getElementById('send-code');		//��ͨJS��ȡ�����Dom
		changeReactInputValue(userIdDom,"Resend (" + countdown + ")");			//�ı�React��������ֵ

		countdown--;
		setTimeout(function () {
			settime(val)
		}, 1000)
	}
}

var countdownForget = 61;
function settimeForget(val) {
	if (countdownForget == 0) {
		val.removeAttribute("disabled");
		val.value = "Send Code";
		countdownForget = 61;
	} else {
		if (countdownForget == 61) {
			sendCodeClickForget();
			countdownForget--;
		}
		val.setAttribute("disabled", true);
		val.value = "Resend (" + countdownForget + ")";
		countdownForget--;
		setTimeout(function () {
			settimeForget(val)
		}, 1000)
	}
}


export  {
	settimeForget,
	settime,
	forgetClick,
	sendCodeClickForget,
	registerClick,
	sendCodeClick,
	login,
	loginGoogle,
	getDefaultUsername
}
