import React, {Component} from "react";
import {Col, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import {selectHeadPic, selectHeadName} from "../pages/MapData";
import {deleteVoiceFile} from "./public/main";


class RoleItem extends Component{
    constructor(props) {
        super(props);
        this.state = {
            voiceNum: 0
        }
    }

    addItem() {
        this.setState({
            voiceNum: this.state.voiceNum+1,
        })
    }

    deleteUpdate(promptID){
        if(deleteVoiceFile(promptID) === 200){
            this.props.deleteUpdate();
            this.setState({
                voiceNum: this.state.voiceNum-1,
            })
        }
    }

    render() {
        let { whoStr, selected, userDefine, promptID, isMouseHover } = this.props;
        const labelClassName = selected ? 'myInputCheckBoxChecked marginRight40' : 'myInputCheckBox marginRight40';
        return (
            <li  className={selected ? 'align-left-row RoleItemSel' : 'align-left-row RoleItem'}>
                <li className="RoleItemSub align-left-row">
                    <li className="marginLeft40">{selectHeadPic(whoStr)}</li>
                    <li className={selected ? 'marginLeft10 RoleItemTextCheck' : 'marginLeft10 RoleItemText'}>
                        {selectHeadName(whoStr)}
                    </li>
                </li>
                <label className={labelClassName} htmlFor="file-input2" >
                    <input  className="displayNone" type="checkbox"  id="file-input2"   disabled={true} />
                </label>

                {userDefine && isMouseHover &&
                    <li className="blueBack center-container" onClick={()=>{this.deleteUpdate(promptID)}}>
                        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M26.1 11.6996H22.5V10.7996C22.5 10.0796 22.221 9.40461 21.708 8.89161C21.195 8.37861 20.52 8.09961 19.8 8.09961H16.2C15.48 8.09961 14.805 8.37861 14.292 8.89161C13.779 9.40461 13.5 10.0796 13.5 10.7996V11.6996H9.9C9.405 11.6996 9 12.1046 9 12.5996C9 13.0946 9.405 13.4996 9.9 13.4996H10.8V25.1996C10.8 25.9196 11.079 26.5946 11.592 27.1076C12.105 27.6206 12.78 27.8996 13.5 27.8996H22.5C23.22 27.8996 23.895 27.6206 24.408 27.1076C24.921 26.5946 25.2 25.9196 25.2 25.1996V13.4996H26.1C26.595 13.4996 27 13.0946 27 12.5996C27 12.1046 26.595 11.6996 26.1 11.6996ZM15.3 10.7996C15.3 10.5656 15.399 10.3316 15.561 10.1606C15.732 9.99861 15.966 9.89961 16.2 9.89961H19.8C20.034 9.89961 20.268 9.99861 20.439 10.1606C20.601 10.3316 20.7 10.5656 20.7 10.7996V11.6996H15.3V10.7996ZM23.4 25.1996C23.4 25.4336 23.301 25.6676 23.139 25.8386C22.968 26.0006 22.734 26.0996 22.5 26.0996H13.5C13.266 26.0996 13.032 26.0006 12.861 25.8386C12.699 25.6676 12.6 25.4336 12.6 25.1996V13.4996H23.4V25.1996Z" fill="white"/>
                            <path d="M16.2 23.3992C15.705 23.3992 15.3 22.9942 15.3 22.4992V17.0992C15.3 16.6042 15.705 16.1992 16.2 16.1992C16.695 16.1992 17.1 16.6042 17.1 17.0992V22.4992C17.1 22.9942 16.695 23.3992 16.2 23.3992Z" fill="white"/>
                            <path d="M19.7999 23.3992C19.3049 23.3992 18.8999 22.9942 18.8999 22.4992V17.0992C18.8999 16.6042 19.3049 16.1992 19.7999 16.1992C20.2949 16.1992 20.6999 16.6042 20.6999 17.0992V22.4992C20.6999 22.9942 20.2949 23.3992 19.7999 23.3992Z" fill="white"/>
                        </svg>

                    </li>
                }
            </li>
        );
    }
};

export default RoleItem;
