import React, { useState } from "react";
import { Col, Row } from "react-bootstrap";
import voiceRecognition from "../assets/images/webp/voice-recognition.webp";
import { Link } from "react-router-dom";

const VoiceCloning = () => {
  const [tab, setTab] = useState(1);
  return (
    <section className="py-5 sections_bg_image">
      <div className="container  container-custom">
        <Row>
          <Col lg={4}>
            <div
              onClick={() => setTab(1)}
              className={`for-influencer d-flex align-items-center justify-content-between mt-1 cursor_pointer ${
                tab === 1 ? "active-nav-tab" : "non-active-nav-tab"
              }`}>
              <p className="mb-0 ff_work clr_off_black fs_xl fw-semibold line_height130">
                For Influencers:
              </p>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.1499 21.1C6.8999 20.85 6.7749 20.554 6.7749 20.212C6.7749 19.87 6.8999 19.5743 7.1499 19.325L14.4749 12L7.1249 4.65C6.89157 4.41667 6.7749 4.125 6.7749 3.775C6.7749 3.425 6.8999 3.125 7.1499 2.875C7.3999 2.625 7.6959 2.5 8.0379 2.5C8.3799 2.5 8.67557 2.625 8.9249 2.875L17.3249 11.3C17.4249 11.4 17.4959 11.5083 17.5379 11.625C17.5799 11.7417 17.6006 11.8667 17.5999 12C17.5999 12.1333 17.5789 12.2583 17.5369 12.375C17.4949 12.4917 17.4242 12.6 17.3249 12.7L8.8999 21.125C8.66657 21.3583 8.3789 21.475 8.0369 21.475C7.6949 21.475 7.39924 21.35 7.1499 21.1Z"
                  fill="#333333"
                />
              </svg>
            </div>
            <div
              onClick={() => setTab(2)}
              className={`for-influencer d-flex align-items-center justify-content-between mt-1 cursor_pointer ${
                tab === 2 ? "active-nav-tab" : "non-active-nav-tab"
              }`}>
              <p className="mb-0 ff_work clr_off_black fs_xl fw-semibold line_height130">
                For Developers:
              </p>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.1499 21.1C6.8999 20.85 6.7749 20.554 6.7749 20.212C6.7749 19.87 6.8999 19.5743 7.1499 19.325L14.4749 12L7.1249 4.65C6.89157 4.41667 6.7749 4.125 6.7749 3.775C6.7749 3.425 6.8999 3.125 7.1499 2.875C7.3999 2.625 7.6959 2.5 8.0379 2.5C8.3799 2.5 8.67557 2.625 8.9249 2.875L17.3249 11.3C17.4249 11.4 17.4959 11.5083 17.5379 11.625C17.5799 11.7417 17.6006 11.8667 17.5999 12C17.5999 12.1333 17.5789 12.2583 17.5369 12.375C17.4949 12.4917 17.4242 12.6 17.3249 12.7L8.8999 21.125C8.66657 21.3583 8.3789 21.475 8.0369 21.475C7.6949 21.475 7.39924 21.35 7.1499 21.1Z"
                  fill="#333333"
                />
              </svg>
            </div>
            <div
              onClick={() => setTab(3)}
              className={`for-influencer d-flex align-items-center justify-content-between mt-1 cursor_pointer ${
                tab === 3 ? "active-nav-tab" : "non-active-nav-tab"
              }`}>
              <p className="mb-0 ff_work clr_off_black fs_xl fw-semibold line_height130">
                For Individuals:
              </p>
              <svg
                width="24"
                height="24"
                viewBox="0 0 24 24"
                fill="none"
                xmlns="http://www.w3.org/2000/svg">
                <path
                  d="M7.1499 21.1C6.8999 20.85 6.7749 20.554 6.7749 20.212C6.7749 19.87 6.8999 19.5743 7.1499 19.325L14.4749 12L7.1249 4.65C6.89157 4.41667 6.7749 4.125 6.7749 3.775C6.7749 3.425 6.8999 3.125 7.1499 2.875C7.3999 2.625 7.6959 2.5 8.0379 2.5C8.3799 2.5 8.67557 2.625 8.9249 2.875L17.3249 11.3C17.4249 11.4 17.4959 11.5083 17.5379 11.625C17.5799 11.7417 17.6006 11.8667 17.5999 12C17.5999 12.1333 17.5789 12.2583 17.5369 12.375C17.4949 12.4917 17.4242 12.6 17.3249 12.7L8.8999 21.125C8.66657 21.3583 8.3789 21.475 8.0369 21.475C7.6949 21.475 7.39924 21.35 7.1499 21.1Z"
                  fill="#333333"
                />
              </svg>
            </div>
          </Col>
          <Col lg={8}>
            <div className="ps-lg-5 mt-4 mt-lg-0">
              <div className={tab === 1 ? "d-block" : "d-none"}>
                <div className="voice-recognition-bg d-flex align-items-center justify-content-center">
                  <img src={voiceRecognition} alt="voiceRecognitions" />
                </div>
                <h2 className="fs_3xl ff_work fw-semibold line_height122 mb-0 mt-3">
                  Elevate Audience Engagement with Ultra-Realistic Avatars
                </h2>
                <p className="clr_primary fs_lg ff_work line_height150 opacity08 mb-0 mt-3">
                  Immerse your audience with dedicated solutions crafted to create ultra-realistic voices and avatars
                  mirroring your unique persona. Enhance your connection, foster deeper engagement, and redefine how
                  you interact with your followers.
                </p>
              </div>
              <div className={tab === 2 ? "d-block" : "d-none"}>
                <div className="voice-recognition-bg d-flex align-items-center justify-content-center">
                  <img src={voiceRecognition} alt="voiceRecognitions" />
                </div>
                <h2 className="fs_3xl ff_work fw-semibold line_height122 mb-0 mt-3">
                  Harness Cutting-Edge Voice Technology for Your Applications
                </h2>
                <p className="clr_primary fs_lg ff_work line_height150 opacity08 mb-0 mt-3">
                  Harness the power of our cutting-edge technology through real-time APIs, granting you access to our
                  latest voice models. Integrate our advanced capabilities seamlessly into your applications,
                  ensuring your users enjoy an unparalleled experience.
                </p>
              </div>
              <div className={tab === 3 ? "d-block" : "d-none"}>
                <div className="voice-recognition-bg d-flex align-items-center justify-content-center">
                  <img src={voiceRecognition} alt="voiceRecognitions" />
                </div>
                <h2 className="fs_3xl ff_work fw-semibold line_height122 mb-0 mt-3">
                  Cherish and Relive Memories with Voice Cloning of Departed Loved Ones
                </h2>
                <p className="clr_primary fs_lg ff_work line_height150 opacity08 mb-0 mt-3">
                  Cherish memories in a profound way by cloning the voice of a departed loved one. Our state-of-the-art
                  technology allows you to encapsulate precious moments, ensuring that their presence
                  is felt and remembered forever.
                </p>
              </div>
            </div>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default VoiceCloning;
