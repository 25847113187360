import React from "react";

function About() {

  const handleClick = event => {
    window.location.href = "https://yeeqohbyi7d.typeform.com/to/RNEUexJm"
  };

  return (
    <section className="py-sm-5 pt-5 pb-3  h-100 w-100  position-relative d-flex flex-column justify-content-center align-content-between">
      <div className="container  container-custom text-center py-lg-4">
        <h1
          className="ff_work fs_5xl clr_off_black line_height122 fw-semibold text-center mx-auto "
          style={{ letterSpacing: "-0.66px", maxWidth: "703px" }}>
          About Speaking AI
        </h1>
        <p
          style={{ letterSpacing: "-0.198px", maxWidth: "900px" }}
          className="ff_work clr_primary fw-medium fs_lg text-center mx-auto line_height150 opacity08 pb-3 clr_primary fs_xl ff_work line_height150 ">
          Speaking.ai is created by a diversified team scattered across the globe, including California, Seattle,
          and the Asia Pacific region. We are brought together by the same belief that conversational generative
          audio AI represents the future interface between humans and artificial intelligence. At Speaking.ai,
          we build connections between humanity and future artificial intelligence, using state-of-the-art
          foundational models for generative audio AI. If you are interested in creating this future with us,
          please contact us contact@speaking.ai.
        </p>

      </div>
    </section>
  );
}

export default About;
