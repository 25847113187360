
import React, {Component} from "react";
import AudioAnalyser from "react-audio-analyser"
import {getCurrentFormatDate} from "./public/main";
//https://github.com/jiwenjiang/react-audio-analyser/blob/master/README_zh-CN.md  这是控件的github地址  此控件还有暂停录音的功能

export default class AudioRecorder extends Component {
    constructor(props) {
        super(props)
        this.state = {
            status: "paused",
            audioType: "audio/wav",
            millisecond: 0,
            second: 0,
            recording: false
        }
    }

    setClock()
    {
        this.setState((prevState) => ({
            millisecond: prevState.millisecond + 50
        }));
        if(this.state.millisecond>=1000)
        {
            this.setState((prevState) => ({
                millisecond: 0
            }));

            this.setState((prevState) => ({
                second: prevState.second + 1
            }));
        }

        if(this.state.second>=10){
            this.controlAudio("paused")
            this.controlAudio("inactive")
        }
    }
    componentDidMount() {

    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    controlAudio(status) {
        if(status == "recording"){
            this.setState( {millisecond : 0})
            this.setState( {second : 0})
            this.interval = setInterval(() => this.setClock(), 50);
            this.setState({
                recording: true
            })
        }else {
            this.setState({
                recording: false
            })
            clearInterval(this.interval);
        }
        this.setState({
            status
        })
    }
    render() {
        const {status, audioSrc, audioType} = this.state;
        const audioProps = {
            audioType: 'audio/mp3',
            // audioOptions: {sampleRate: 30000}, // 设置输出音频采样率
            status,
            //audioSrc,
            width: 100,
            height: 0,
            backgroundColor: 'rgba(80,100,239,0.5)',
            strokeColor: '#f5f0f0',
            className: 'bkLabelQuality',
            timeslice: 1000, // 时间切片（https://developer.mozilla.org/en-US/docs/Web/API/MediaRecorder/start#Parameters）
            startCallback: (e) => {
                console.log("succ start", e)
            },
            pauseCallback: (e) => {
                console.log("succ pause", e)
            },
            stopCallback: (e) => {
                //this.setState({
                //    audioSrc: window.URL.createObjectURL(e)
                //})
                if(this.state.second < 3){
                    this.props.onError()
                    return;
                }

                let file = e;
                let audioName =  "P-" + getCurrentFormatDate() + "-01.mp3";
                let urlHtml = URL.createObjectURL(file);
                let fileSize = file.size;//文件的字节数
                let fileSizeString = ''
                if(fileSize < 1024)
                    fileSizeString = fileSize + 'B'
                else if(fileSize >= 1024 && fileSize < 1024* 1024)
                    fileSizeString = (fileSize / 1024).toFixed(1) + 'KB'
                else if(fileSize >= 1024* 1024 && fileSize < 1024* 1024* 1024)
                    fileSizeString = (fileSize / 1024 / 1024).toFixed(1)+ 'MB'
                this.props.addFile(urlHtml, audioName, fileSizeString)//增加控件
                this.props.funcUpdate(file);//update UI update database
                console.log("succ stop", e)
            },
            onRecordCallback: (e) => {
                console.log("recording", e)
            },
            errorCallback: (err) => {
                console.log("error", err)
            }
        }
        return (
            <div >
                <AudioAnalyser {...audioProps} className=" marginTop20">

                </AudioAnalyser>

                <div>
                    <svg
                        width="32" height="48" viewBox="0 0 32 48"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path d="M16.0922 31.9343C13.9784 31.9343 11.9907 31.1097 10.4922 29.6173C9.0184 28.1435 8.17224 26.1035 8.17224 24.0143V8.17427C8.17224 6.05735 8.99686 4.06658 10.4892 2.5712C11.9876 1.07581 13.9753 0.254272 16.0922 0.254272C18.2092 0.254272 20.2184 1.10043 21.6922 2.5712C23.1661 4.04504 24.0122 6.08504 24.0122 8.17427V24.0143C24.0122 26.0974 23.1661 28.1404 21.6922 29.6143C20.2184 31.0881 18.1784 31.9343 16.0922 31.9343ZM16.0922 4.21427C15.0338 4.21427 14.0399 4.62658 13.2892 5.37427C12.5538 6.10966 12.1322 7.1312 12.1322 8.17427V24.0143C12.1322 25.0574 12.5538 26.0789 13.2892 26.8143C14.7753 28.3004 17.4092 28.3004 18.8922 26.8143C19.6276 26.0789 20.0522 25.0574 20.0522 24.0143V8.17427C20.0522 7.1312 19.6276 6.10966 18.8922 5.37119C18.1569 4.63273 17.1353 4.21427 16.0922 4.21427Z" fill="#695CFF"/>
                        <path d="M31.9261 20.0543V24.0143C31.9261 28.2512 30.283 32.2112 27.2923 35.2204C24.7784 37.7343 21.5507 39.3004 18.0646 39.7158V43.8143H24.003C25.1107 43.8143 25.9815 44.7066 25.9815 45.7927C25.9815 46.8789 25.1107 47.7712 24.003 47.7712H8.16304C7.07381 47.7712 6.18458 46.8789 6.18458 45.7927C6.18458 44.7066 7.07689 43.8143 8.16304 43.8143H14.1015V39.7158C10.6369 39.2789 7.3692 37.6973 4.89535 35.2204C1.90458 32.2297 0.243042 28.2512 0.243042 24.0143V20.0543C0.243042 18.965 1.13535 18.0758 2.2215 18.0758C3.30766 18.0758 4.19997 18.9681 4.19997 20.0543V24.0143C4.19997 27.1835 5.44612 30.1712 7.68612 32.4081C9.90458 34.6266 12.9538 35.8943 16.08 35.8943C19.2061 35.8943 22.2369 34.6666 24.4953 32.4081C26.7323 30.1712 27.96 27.1804 27.96 24.0143V20.0543C27.96 18.965 28.8523 18.0758 29.9384 18.0758C31.0246 18.0758 31.9169 18.9681 31.9169 20.0543H31.9261Z" fill="#695CFF"/>
                    </svg>

                    <p id="clock" className="record-time gap-2 marginTop20 marginBottom20">{this.state.second.toString().padStart(2, '0') + ':' + this.state.millisecond.toString().padStart(4, '0')}</p>
                </div>

                <div className="mx-auto text-center  marginRight-left100">
                    {!this.state.recording &&
                        <div onClick={() => this.controlAudio("recording") } title="Begin record">
                            <svg  //record button
                                width="60" height="61" viewBox="0 0 60 61"
                                fill="none"
                                xmlns="http://www.w3.org/2000/svg">
                                <rect x="0.5" y="1.36963" width="59" height="59" rx="29.5" stroke="#695CFF"/>
                                <rect x="18" y="18.8696" width="24" height="24" rx="12" fill="#FF5C66"/>
                            </svg>
                        </div>
                    }
                    {this.state.recording &&
                        <div onClick={() => {this.controlAudio("paused"); this.controlAudio("inactive")}} title="Stop">
                            <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <rect width="60" height="60" rx="30" fill="#FF5C66"/>
                                <rect x="18" y="18" width="24" height="24" rx="4" fill="white"/>
                            </svg>
                        </div>
                    }

                </div>
            </div>
        );
    }
}
