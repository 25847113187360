
import React, {
  useState,
  forwardRef,
  useImperativeHandle,
} from 'react'

import { Button, Modal } from 'antd';


export function DlgMSGError ({hide}) {
  const [isModalOpen, setIsModalOpen] = useState(true);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    //setIsModalOpen(false);
    hide();
  };
  const handleCancel = () => {
    //setIsModalOpen(false);
    hide();
  };
  return (
      <>
        <Modal title="Error" open={isModalOpen} onOk={handleOk} onCancel={handleCancel} maskClosable={false}
               cancelButtonProps={{ style: { display: 'none' } }} >
          <p>Must enter a voice name!</p>
          <p>Must select a file or record a voice!</p>
        </Modal>
      </>
  );
};

