import React, { Component } from "react";

class AudioPlaySimple extends Component {
    constructor(props) {
        super(props);
        this.state = {
            isPlay: false,
        };
    }

    componentDidMount() {}

    // 该视频已准备好开始播放
    onCanPlay = () => {
        const { id } = this.props;
        const audio = document.getElementById(`audio${id}`);
        this.setState({
            allTime: audio.duration,
        });
    };

    playAudio = () => {
        const { id } = this.props;
        const audio = document.getElementById(`audio${id}`);
        audio.play();
        this.setState({
            isPlay: true,
        });
    };

    oNEndedPaly = ()=>{
        const { id } = this.props;
        const audio = document.getElementById(`audio${id}`);
        audio.pause();
        this.setState({
            isPlay: false,
        });
    };


    pauseAudio = () => {
        const { id } = this.props;
        const audio = document.getElementById(`audio${id}`);
        audio.pause();
        this.setState({
            isPlay: false,
        });
    };

    onMuteAudio = () => {
        const { id } = this.props;
        const audio = document.getElementById(`audio${id}`);
        this.setState({
            isMuted: !audio.muted,
        });
        audio.muted = !audio.muted;
    };

    deleteAudio = () => {
        const { id } = this.props;
        const audio = document.getElementById(`audio${id}`);
        this.setState({
            allTime: audio.duration,
        });
    };

    render() {
        const { src, id, name, size} = this.props;

        const {
            isPlay,
        } = this.state;

        return (
            <div className="height40">
                <audio
                    id={`audio${id}`}
                    src={src}
                    ref={(audio) => {
                        this.audioDom = audio;
                    }}
                    preload={"auto"}
                    onCanPlay={this.onCanPlay}
                    onEnded={this.oNEndedPaly}
                    onTimeUpdate={this.onTimeUpdate}
                >
                    <track src={src} kind="captions" />
                </audio>

                <div className="d-flex gap-3 align-items-center mt-4  btn_play_simple box_file_submit">
                    <label id="label_id" className="slidAudio ">{name}</label>
                    <label className="bkLabel" >{size}</label>

                    {isPlay ? (
                        <div onClick={this.pauseAudio} className="marginBottom5 ">

                            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M5.49519 30.9472C3.776 29.2867 2.40472 27.3005 1.46136 25.1045C0.518 22.9084 0.0214483 20.5464 0.000679622 18.1564C-0.0200891 15.7664 0.435342 13.3961 1.3404 11.184C2.24545 8.97188 3.58201 6.96215 5.27208 5.27208C6.96215 3.58201 8.97188 2.24545 11.184 1.3404C13.3961 0.435342 15.7664 -0.0200891 18.1564 0.000679622C20.5464 0.0214483 22.9084 0.518 25.1045 1.46136C27.3005 2.40472 29.2867 3.776 30.9472 5.49519C34.226 8.89003 36.0403 13.4369 35.9993 18.1564C35.9583 22.8759 34.0653 27.3906 30.7279 30.7279C27.3906 34.0653 22.8759 35.9583 18.1564 35.9993C13.4369 36.0403 8.89003 34.226 5.49519 30.9472ZM12.8212 11.0212V25.4212H16.4212V11.0212H12.8212ZM20.0212 11.0212V25.4212H23.6212V11.0212H20.0212Z" fill="#695CFF"/>
                            </svg>

                        </div>
                    ) : (
                        <div onClick={this.playAudio} className="marginBottom5 ">
                            <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M18 0C14.4399 0 10.9598 1.05568 7.99974 3.03355C5.03966 5.01141 2.73255 7.82263 1.37018 11.1117C0.00779911 14.4008 -0.348661 18.02 0.345873 21.5116C1.04041 25.0033 2.75474 28.2106 5.27208 30.7279C7.78943 33.2453 10.9967 34.9596 14.4884 35.6541C17.98 36.3487 21.5992 35.9922 24.8883 34.6298C28.1774 33.2674 30.9886 30.9603 32.9664 28.0003C34.9443 25.0402 36 21.5601 36 18C36 15.6362 35.5344 13.2956 34.6298 11.1117C33.7252 8.92783 32.3994 6.94353 30.7279 5.27208C29.0565 3.60062 27.0722 2.27475 24.8883 1.37017C22.7044 0.465584 20.3638 0 18 0ZM14.4 26.1V9.9L25.2 18L14.4 26.1Z" fill="#695CFF"/>
                            </svg>
                        </div>
                    )}

                    <div onClick={()=>{this.deleteAudio(); this.props.deleteItem(src)}} className=" marginRight40" >
                        <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <circle cx="18" cy="18" r="17.5" fill="white" stroke="#695CFF"/>
                            <path d="M26.1 11.7H22.5V10.8C22.5 10.08 22.221 9.40498 21.708 8.89198C21.195 8.37898 20.52 8.09998 19.8 8.09998H16.2C15.48 8.09998 14.805 8.37898 14.292 8.89198C13.779 9.40498 13.5 10.08 13.5 10.8V11.7H9.9C9.405 11.7 9 12.105 9 12.6C9 13.095 9.405 13.5 9.9 13.5H10.8V25.2C10.8 25.92 11.079 26.595 11.592 27.108C12.105 27.621 12.78 27.9 13.5 27.9H22.5C23.22 27.9 23.895 27.621 24.408 27.108C24.921 26.595 25.2 25.92 25.2 25.2V13.5H26.1C26.595 13.5 27 13.095 27 12.6C27 12.105 26.595 11.7 26.1 11.7ZM15.3 10.8C15.3 10.566 15.399 10.332 15.561 10.161C15.732 9.99898 15.966 9.89998 16.2 9.89998H19.8C20.034 9.89998 20.268 9.99898 20.439 10.161C20.601 10.332 20.7 10.566 20.7 10.8V11.7H15.3V10.8ZM23.4 25.2C23.4 25.434 23.301 25.668 23.139 25.839C22.968 26.001 22.734 26.1 22.5 26.1H13.5C13.266 26.1 13.032 26.001 12.861 25.839C12.699 25.668 12.6 25.434 12.6 25.2V13.5H23.4V25.2Z" fill="#695CFF"/>
                            <path d="M16.2 23.4C15.705 23.4 15.3 22.995 15.3 22.5V17.1C15.3 16.605 15.705 16.2 16.2 16.2C16.695 16.2 17.1 16.605 17.1 17.1V22.5C17.1 22.995 16.695 23.4 16.2 23.4Z" fill="#695CFF"/>
                            <path d="M19.7999 23.4C19.3049 23.4 18.8999 22.995 18.8999 22.5V17.1C18.8999 16.605 19.3049 16.2 19.7999 16.2C20.2949 16.2 20.6999 16.605 20.6999 17.1V22.5C20.6999 22.995 20.2949 23.4 19.7999 23.4Z" fill="#695CFF"/>
                        </svg>

                    </div>


                </div>
            </div>
        );
    }
}

export default AudioPlaySimple;

