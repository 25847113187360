
import { url } from './config';


function httpRequest(subUrl, paramBody, callback, isPost = "POST")//�첽����
{
	let httpRequest = new XMLHttpRequest();
	if (isPost === "GET") {
		httpRequest.open(isPost, url + subUrl, true);
		httpRequest.setRequestHeader("Accept", "application/json,text/plain,*/*");
		httpRequest.setRequestHeader("Content-type", "application/x-www-form-urlencoded");
		httpRequest.send(null);//�������� ��jsonд��send��
	}
	else {
		httpRequest.open(isPost, url + subUrl, true);
		httpRequest.setRequestHeader("Accept", "application/json,text/plain,*/*");
		httpRequest.setRequestHeader("Content-type", "application/json");
		httpRequest.send(JSON.stringify(paramBody));//�������� ��jsonд��send��
	}

	/**
	 * ��ȡ���ݺ�Ĵ�������
	 */
	httpRequest.onreadystatechange = function () {//�����Ļص��ӿڣ��ɽ�����ɹ���Ҫִ�еĳ���д������
		if (httpRequest.readyState === 4 && httpRequest.status === 200) {//��֤�����Ƿ��ͳɹ�
			let json = httpRequest.responseText;//��ȡ������˷��ص�����
			callback(json);
		}
	};
}


window.onload = function () {
	var href = window.location.href;
	var arrhref = href.split("=");
	window.g_authorization = arrhref[1];
	//console.log(window.g_authorization);
	//getFileInfoList();
}

function getAuthUrlUploadFile(fileName) {
	let authorization = localStorage.getItem('SpeakingAiToken')
	if (authorization === undefined || authorization === "")
		return;
	console.log("get auth url");
	var request = new XMLHttpRequest();
	request.open('GET', url + `/file/uploadURL?file=${fileName}&authorization=${authorization}`, false);
	request.send(null);
	if (request.status === 200) {
		console.log(request.responseText);
		var obj = window.JSON.parse(request.responseText);
		if (obj.code === 200) {
			return obj;
		}
		else
			alert(obj.msg);
	}
}

function addFileInfoToSql(fileName, fileS3Name, voiceName, voiceDsc) {
	console.log("fileName=" + fileName + "  fileS3Name=" + fileS3Name + "  voiceName=" + voiceName+ "  voiceDsc=" +voiceDsc);
	return new Promise((resolve, reject) => {
		let authorization = localStorage.getItem('SpeakingAiToken')
		if (authorization === undefined || authorization === "")
			return -1;

		if (voiceDsc === undefined || voiceDsc === '')
			voiceDsc = ' '
		let strData = {
			file: fileName,
			fileS3Name: fileS3Name,
			voiceName: voiceName,
			voiceDsc: voiceDsc
		};

		const req = new XMLHttpRequest();   //����XMLHttpRequest����
		req.open('POST', url + `/file/uploadFileInfo?&authorization=${authorization}`);
		req.setRequestHeader("Accept", "application/json,text/plain,*/*");
		req.setRequestHeader("Content-type", "application/json");
		req.onload = function () {
			if (req.status === 200) {
				console.log('success');
				console.log(req.response);
				let obj = window.JSON.parse(req.response);
				return resolve(obj.msg);   //��Ӧ�ɹ�
			}
			else {
				console.log('failed');
				return reject(-1); //��Ӧʧ��
			}
		}
		req.onerror = function () {
			console.log("network error");
			return reject(-1);
		}
		req.send(JSON.stringify(strData));
	})
}


function getFileInfoList() {
	return new Promise((resolve, reject) => {
		let authorization = localStorage.getItem('SpeakingAiToken')
		if (authorization === undefined || authorization === "")
			return reject(Error('parameter error'));
		const req = new XMLHttpRequest();   //����XMLHttpRequest����
		req.open('GET', url + `/file/fileList?authorization=${authorization}`);
		req.onload = function () {
			if (req.status === 200) {
				console.log('success');
				console.log(req.response);
				let obj = window.JSON.parse(req.response);
				return resolve(obj);   //��Ӧ�ɹ�
			}
			else {
				console.log('failed');
				return reject(Error('failed')); //��Ӧʧ��
			}
		}
		req.onerror = function () {
			console.log("network error");
			return reject(Error('Network error'));
		}
		req.send();
	})
}


function getVoiceList() {
	return new Promise((resolve, reject) => {
		let authorization = localStorage.getItem('SpeakingAiToken')
		if (authorization === undefined || authorization === "")
			return reject(Error('parameter error'));
		const req = new XMLHttpRequest();   //����XMLHttpRequest����
		req.open('GET', url + `/file/voiceList?&authorization=${authorization}`);
		req.onload = function () {
			if (req.status === 200) {
				console.log('success');
				console.log(req.response);
				let obj = window.JSON.parse(req.response);
				return resolve(obj);   //��Ӧ�ɹ�
			}
			else {
				console.log('failed');
				return reject(Error('failed')); //��Ӧʧ��
			}
		}
		req.onerror = function () {
			console.log("network error");
			return reject(Error('Network error'));
		}
		req.send();
	})
}

function getDownloadURL(fileName) {
	console.log(fileName)
	return new Promise((resolve, reject) => {
		let authorization = localStorage.getItem('SpeakingAiToken')
		if (authorization === undefined || authorization === "")
			return reject(Error('parameter error'));
		const req = new XMLHttpRequest();   //����XMLHttpRequest����
		req.open('GET', url + `/file/downloadVoiceUrl?&file=${fileName}&authorization=${authorization}`);
		req.onload = function () {
			if (req.status === 200) {
				console.log('success');
				console.log(req.response);
				let obj = window.JSON.parse(req.response);
				return resolve(obj);   //��Ӧ�ɹ�
			}
			else {
				console.log('failed');
				return reject(Error('failed')); //��Ӧʧ��
			}
		}
		req.onerror = function () {
			console.log("network error");
			return reject(Error('Network error'));
		}
		req.send();
	})
}


function deleteVoiceFile(fileS3Name) {
	let authorization = localStorage.getItem('SpeakingAiToken')
	if (authorization === undefined || authorization === "")
		return;
	var request = new XMLHttpRequest();
	request.open('GET', url + `/file/deleteFile?file=${fileS3Name}&authorization=${authorization}`, false);
	request.send(null);
	if (request.status === 200) {
		console.log(request.responseText);
		var obj = window.JSON.parse(request.responseText);
		if (obj.code === 200) {
			return 200;
		}
		else
			return -1;
	}
}

function deleteGenerateVoiceFile(fileS3Name) {
	let authorization = localStorage.getItem('SpeakingAiToken')
	if (authorization === undefined || authorization === "")
		return;
	var request = new XMLHttpRequest();
	request.open('GET', url + `/file/deleteGenerateVoice?file=${fileS3Name}&authorization=${authorization}`, false);
	request.send(null);
	if (request.status === 200) {
		console.log(request.responseText);
		var obj = window.JSON.parse(request.responseText);
		if (obj.code === 200) {
			return 200;
		}
		else
			return -1;
	}
}

function getGeneratePosition(msgKey) {
	return new Promise((resolve, reject) => {
		let authorization = localStorage.getItem('SpeakingAiToken')
		if (authorization === undefined || authorization === "")
			return reject(Error('parameter error'));
		const req = new XMLHttpRequest();   //����XMLHttpRequest����
		req.open('GET', url + `/file/getQueuePosition?message_key=${msgKey}&authorization=${authorization}`);
		req.onload = function () {
			if (req.status === 200) {
				console.log('success');
				console.log(req.response);
				let obj = window.JSON.parse(req.response);
				return resolve(obj);   //��Ӧ�ɹ�
			}
			else {
				console.log('failed');
				return reject(Error('failed')); //��Ӧʧ��
			}
		}
		req.onerror = function () {
			console.log("network error");
			return reject(Error('Network error'));
		}
		req.send();
	})
}


/**
 * ��ȡϵͳ��ǰʱ�䲢��ʽ��
 * @returns yyyy-MM-dd HH:mm:ss
 */
function getCurrentFormatDate() {
	// ϵͳ��ǰʱ���ʽ��
	var currentFormatDate = "";
	// ��ȡϵͳ��ǰ����
	var date = new Date();
	// ��ȡ��ǰ��
	var currentYear = date.getFullYear();
	// ��ȡ��ǰ��
	var currentMonth = date.getMonth() + 1;
	currentMonth = (currentMonth <= 9) ? "0" + currentMonth : currentMonth;
	// ��ȡ��ǰ��
	var currentDay = date.getDate();
	currentDay = (currentDay <= 9) ? "0" + currentDay : currentDay;
	// ʱ
	var currentHours = date.getHours();
	// ��
	var currentMinutes = date.getMinutes();
	// ��
	var currentSeconds = date.getSeconds();
	// yyyy-MM-dd HH:mm:ss
	currentFormatDate = currentYear + currentMonth + currentDay
	return currentFormatDate;
}

// Convert AudioBuffer to a Blob using WAVE representation
function bufferToWaveBlob(abuffer, len) {
	var numOfChan = abuffer.numberOfChannels,
		length = len * numOfChan * 2 + 44,
		buffer = new ArrayBuffer(length),
		view = new DataView(buffer),
		channels = [], i, sample,
		offset = 0,
		pos = 0;

	// write WAVE header
	// "RIFF"
	setUint32(0x46464952);
	// file length - 8
	setUint32(length - 8);
	// "WAVE"
	setUint32(0x45564157);
	// "fmt " chunk
	setUint32(0x20746d66);
	// length = 16
	setUint32(16);
	// PCM (uncompressed)
	setUint16(1);
	setUint16(numOfChan);
	setUint32(abuffer.sampleRate);
	// avg. bytes/sec
	setUint32(abuffer.sampleRate * 2 * numOfChan);
	// block-align
	setUint16(numOfChan * 2);
	// 16-bit (hardcoded in this demo)
	setUint16(16);
	// "data" - chunk
	setUint32(0x61746164);
	// chunk length
	setUint32(length - pos - 4);

	// write interleaved data
	for (i = 0; i < abuffer.numberOfChannels; i++)
		channels.push(abuffer.getChannelData(i));

	while (pos < length) {
		// interleave channels
		for (i = 0; i < numOfChan; i++) {
			// clamp
			sample = Math.max(-1, Math.min(1, channels[i][offset]));
			// scale to 16-bit signed int
			sample = (0.5 + sample < 0 ? sample * 32768 : sample * 32767) | 0;
			// write 16-bit sample
			view.setInt16(pos, sample, true);
			pos += 2;
		}
		// next source sample
		offset++
	}

	// create Blob
	return new Blob([buffer], { type: "audio/wav" });

	function setUint16(data) {
		view.setUint16(pos, data, true);
		pos += 2;
	}

	function setUint32(data) {
		view.setUint32(pos, data, true);
		pos += 4;
	}
}


function cutAudioIn10s(file) {

	return new Promise((resolve, reject) => {
		var reader = new FileReader();
		reader.onload = function (event) {
			var arrBuffer = event.target.result;

			var audioCtx = new AudioContext();
			audioCtx.decodeAudioData(arrBuffer, function (audioBuffer) {
				// audioBuffer����AudioBuffer
				// ���������Ͳ�����
				var channels = audioBuffer.numberOfChannels;
				var rate = audioBuffer.sampleRate;
				var startOffset = 0;
				var endOffset = rate * 10;// ��ȡǰ10��
				// 3���Ӧ��֡��
				var frameCount = endOffset - startOffset;// ����ͬ�������ʡ�ͬ������������������ǰ3��Ŀյ�AudioBuffer
				var newAudioBuffer = new AudioContext().createBuffer(channels, endOffset - startOffset, rate);
				// ������ʱ��Array��Ÿ��Ƶ�buffer����
				var anotherArray = new Float32Array(frameCount);
				// ���������ݵĸ��ƺ�д��
				var offset = 0;
				for (var channel = 0; channel < channels; channel++) {
					audioBuffer.copyFromChannel(anotherArray, channel, startOffset);
					newAudioBuffer.copyToChannel(anotherArray, channel, offset);
				}
				// newAudioBuffer����ȫ�µĸ��Ƶ�3�볤�ȵ�AudioBuffer����
				let blobData = bufferToWaveBlob(newAudioBuffer, frameCount)
				console.log(blobData)
				return resolve(blobData);
			});
		}
		// arrBuffer���ǰ�����Ƶ���ݵ�ArrayBuffer����});
		reader.readAsArrayBuffer(file);
	});
}



function getAudioTime(blob) {
	return new Promise((resolve, reject) => {
		// ����DOMString URL�������ںʹ������Ĵ����е�document��
		// URL�����ʾָ����File�����Blob����(�Ѷ���ת����urlʹ�� ʮ������ �ر��Ƕ����ļ�����)
		const url = URL.createObjectURL(blob);

		// audio���Ի�ȡ��Ƶ����Ƶʱ��
		const audioElement = new Audio(url);
		console.log("createObjectURL : " + audioElement);
		// ����Ƶ/��Ƶ��Ԫ�����Ѽ���ʱ �ᴥ��loadedmetadata�¼� ��Ƶ/��Ƶ��Ԫ����(ʱ�� �ߴ�(����Ƶ) �ı����)
		audioElement.addEventListener("loadedmetadata", function (_event) {
			let duration = audioElement.duration;
			console.log(duration);
			return resolve(duration);
		});

		audioElement.addEventListener('error', () => {
			return resolve(-1);
		});
	})
}



export {
	getFileInfoList,
	addFileInfoToSql,
	getAuthUrlUploadFile,
	deleteVoiceFile,
	getVoiceList,
	getDownloadURL,
	getCurrentFormatDate,
	getGeneratePosition,
	cutAudioIn10s,
	getAudioTime,
	deleteGenerateVoiceFile
}
