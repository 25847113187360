import React from "react";
import { pricingplans } from "../pages/MapData";
import { Col, Container, Row } from "react-bootstrap";
const PricingPlans = () => {
  return (
    <section className="pt-lg-5">
      <Container className="pt-xxl-5 container-custom">
        <h3 className="ff_work fw-semibold line_height122 letter_spac_minus_05 clr_off_black fs_4xl text-center py-3">
          Pricing Plans
        </h3>
        <Row className="py-lg-5 pt-0 pb-5 pb-md-0">
          {pricingplans.map((val, index) => {
            return (
              <Col md={6} lg={4} className="pb-4" key={index}>
                <div className="pricing_plans_card plans_card_hover transition300 cursor_pointer h-100">
                  <h2 className="ff_work fw-semibold line_height130 letter_spac_minus_05 clr_off_black fs_xxl mb-0">
                    {val.h1}
                  </h2>
                  <div className="d-flex align-items-end mt-1">
                    <span className="ff_work fw-semibold line_height130 fs_2xl text_linear">
                      {val.para1}
                    </span>
                    <span className="ff_work fw-medium line_height150 clr_primary letter_spac_minus_01 fs_lg">
                      {val.para2}
                    </span>
                  </div>
                  <p className="ff_work fw-normal line_height150 pt-3 clr_primary letter_spac_minus_01 fs_lg opacity06 brdr_btm_pricing_card pb-2 pb-md-4 mb-0">
                    {val.para3}
                  </p>
                  <div className="mt-md-4 mt-3">
                    <div className="d-flex align-items-center">
                      <span>{val.ticksvg}</span>
                      <p className="ff_work fw-medium line_height150 mb-0 ps-2 letter_spac_minus_01 fs_lg clr_rgba_30 position-relative">
                        {val.para4}
                      </p>
                    </div>
                    <div className="d-flex align-items-center">
                      <span>{val.ticksvg}</span>
                      <p className="ff_work fw-medium line_height150 ps-2 my-2 py-1 letter_spac_minus_01 fs_lg clr_rgba_30 position-relative">
                        {val.para4}
                      </p>
                    </div>
                    <div className="d-flex align-items-center">
                      <span>{val.ticksvg}</span>
                      <p className="ff_work fw-medium line_height150 ps-2 mb-0 pb-1 letter_spac_minus_01 fs_lg clr_rgba_30 position-relative">
                        {val.para4}
                      </p>
                    </div>
                    <button className="ff_work fw-medium line_height150 mt-4 w-100 py_12 plans_btn_border_radius bg_light_grey border-0 clr_primary letter_spac_minus_01 fs_lg  position-relative transition300">
                      {val.btn}
                    </button>
                  </div>
                </div>
              </Col>
            );
          })}
        </Row>
      </Container>
    </section>
  );
};

export default PricingPlans;
