
import Taylor from "../assets/images/webp/Taylor.png";
import Musk from "../assets/images/webp/Musk.png";
import Kobe from "../assets/images/webp/Kobe.png";
import Jay from "../assets/images/webp/jay.png";
import Long from "../assets/images/webp/Long.png";
import Mimi from "../assets/images/webp/mimi.png";
import Avatars from "../assets/images/webp/Avatars.png";



export function selectHeadPic(indexName){
  switch (indexName) {
    case "Taylor":
      return <img src={Taylor} alt="Taylor" style={{borderRadius: "50%"}}/>
      break;
    case "Musk":
      return <img src={Musk} alt="Musk" style={{borderRadius: "50%"}}/>
      break;
    case "Kobe":
      return <img src={Kobe} alt="Kobe" style={{borderRadius: "50%"}}/>
      break;
    case "Spider":
      return <img src={Jay} alt="Spider" style={{borderRadius: "50%", width: "80px"}}/>
      break;
    case "Long":
      return <img src={Long} alt="Long" style={{borderRadius: "50%"}}/>
      break;
    case "Kardashian":
      return <img src={Mimi} alt="Dashian" style={{borderRadius: "50%", width: "80px"}}/>
      break;
    default:
      return <img src={Avatars} alt="Avatars" style={{borderRadius: "50%"}}/>
      break;
  }
}

export function selectHeadPic2(indexName, widthTemp = 118){
  let classStyle = {borderRadius: "22px", width: `${widthTemp}px`, height: `${widthTemp}px`}
  switch (indexName) {
    case "Taylor":
      return <img src={Taylor} alt="Taylor" style={classStyle}/>
      break;
    case "Musk":
      return <img src={Musk} alt="Musk" style={classStyle}/>
      break;
    case "Kobe":
      return <img src={Kobe} alt="Kobe" style={classStyle}/>
      break;
    case "Spider":
      return <img src={Jay} alt="Spider" style={classStyle}/>
      break;
    case "Long":
      return <img src={Long} alt="Long" style={classStyle}/>
      break;
    case "Kardashian":
      return <img src={Mimi} alt="Dashian" style={classStyle}/>
      break;
    default:
      return <img src={Avatars} alt="Avatars" style={classStyle}/>
      break;
  }
}

export function selectHeadName(indexName){
  switch (indexName) {
    case "Taylor":
      return "Taylor Swift"
      break;
    case "Musk":
      return "Elon Musk"
      break;
    case "Kobe":
      return "Kobe Bryant"
      break;
    case "Spider":
      return "Jay Chou"
      break;
    case "Long":
      return "Jackie Chan"
      break;
    case "Kardashian":
      return "Yang Mi"
      break;
    default:
      return indexName
      break;
  }
}

export function selectHeadSentence(indexName){
  switch (indexName) {
    case "Taylor":
      return "Generative voice AI is going through a major breakthrough, making voice cloning sound more natural. This is really going to change how we interact with artificial intelligence."
      break;
    case "Musk":
      return "At Speaking AI, we are committed to using and improving this technology responsibly, and to creating value for society in a positive way."
      break;
    case "Kobe":
      return "Currently we support English and Chinese.If you are interested in collaborating with us, please contact us via the partnership page."
      break;
    case "Spider":
      return "Jay Chou"
      break;
    case "Long":
      return "Jackie Chan"
      break;
    case "Kardashian":
      return "Yang Mi"
      break;
    default:
      return indexName
      break;
  }
}

export const prompt = [
  {
    name: "Elon Musk",
    prompt_id: "d41e26cb-f024-4134-b9b9-4fb99d1857ba"
  },
  {
    name: "Steve Jobs",
    prompt_id: "733d6a62-fa07-42d5-ae64-ae55e5d26eb1"
  },
  {
    name: "Taylor Swift",
    prompt_id: "579326a9-ed25-49ad-bd1c-5f3a0523b5bc"
  },
  {
    name: "Donald Trump",
    prompt_id: "88611a99-259c-4fce-8d07-1902227c2a5f"
  },
]

export let arrRemoveJson = function (arr, attr, value) {
  if (!arr || arr.length == 0) {
    return ""
  }
  let newArr = arr.filter(function (item, index) {
    return item[attr] != value
  })
  return newArr
}

export let voiceContainer = [];

export function removeVoiceTemplate(url){
  voiceContainer = arrRemoveJson(voiceContainer,'url',url)
}

export let BallaDAta = [
  "Elon Musk",
  "Steve Jobs",
  "Taylor Swift",
  "Donald Trump",
];

export const benefits = [
  {
    sNo: 1,
    head: "Translingual Model Development",
    para: "Developing cross-language models for multilingual voice fidelity. Contact for early access.",
  },
  {
    sNo: 2,
    head: "API Service Deployment",
    para: "Launching a real-time text-to-voice API. Early registrants prioritized.",
  },
  {
    sNo: 3,
    head: "Dedicated Fine-Tuning Solutions",
    para: "Custom voice tuning using provided audio for near-original quality.",
  }
];

export const recentVoice = [

  {
    head: "Taylor Swift",
    para: "This voice has a total of 5 samples.",
    use: "Use ",
    edit: "Edit",
    remove: "Remove",
  },
  {
    head: "Taylor Swift",
    para: "This voice has a total of 5 samples.",
    use: "Use ",
    edit: "Edit",
    remove: "Remove",
  },
  {
    head: "Taylor Swift",
    para: "This voice has a total of 5 samples.",
    use: "Use ",
    edit: "Edit",
    remove: "Remove",
  },
  {
    head: "Taylor Swift",
    para: "This voice has a total of 5 samples.",
    use: "Use ",
    edit: "Edit",
    remove: "Remove",
  },
];
export const pricingplans = [
  {
    h1: "Basic",
    para1: "$5",
    para2: "/month",
    para3: "Aliquet vel turpis fermentum eros vehicula non euismod nisl.",
    ticksvg: (
      <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.6879 2.36417C16.896 1.68569 15.6963 1.81425 15.0661 2.64509L7.52207 12.5912L3.56272 8.63471C2.90669 7.97916 1.8435 7.97935 1.18771 8.63514C0.531758 9.29109 0.531758 10.3546 1.18771 11.0106L7.91543 17.7383L17.9392 4.85203C18.533 4.08864 18.4224 2.99342 17.6879 2.36417Z"
          fill="url(#paint0_linear_472_1047)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_472_1047"
            x1="4.69174e-08"
            y1="9.66328"
            x2="19"
            y2="9.66328"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#2825C7" />
            <stop offset="1" stopColor="#6A33DF" />
          </linearGradient>
        </defs>
      </svg>
    ),
    para4: "Aliquet vel turpis ferm",
    btn: "Get started",
  },
  {
    h1: "Regular",
    para1: "$10",
    para2: "/month",
    para3: "Aliquet vel turpis fermentum eros vehicula non euismod nisl.",
    ticksvg: (
      <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.6879 2.36417C16.896 1.68569 15.6963 1.81425 15.0661 2.64509L7.52207 12.5912L3.56272 8.63471C2.90669 7.97916 1.8435 7.97935 1.18771 8.63514C0.531758 9.29109 0.531758 10.3546 1.18771 11.0106L7.91543 17.7383L17.9392 4.85203C18.533 4.08864 18.4224 2.99342 17.6879 2.36417Z"
          fill="url(#paint0_linear_472_1047)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_472_1047"
            x1="4.69174e-08"
            y1="9.66328"
            x2="19"
            y2="9.66328"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#2825C7" />
            <stop offset="1" stopColor="#6A33DF" />
          </linearGradient>
        </defs>
      </svg>
    ),
    para4: "Aliquet vel turpis ferm",
    btn: "Get started",
  },
  {
    h1: "Startup",
    para1: "$15",
    para2: "/month",
    para3: "Aliquet vel turpis fermentum eros vehicula non euismod nisl.",
    ticksvg: (
      <svg
        width="19"
        height="19"
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.6879 2.36417C16.896 1.68569 15.6963 1.81425 15.0661 2.64509L7.52207 12.5912L3.56272 8.63471C2.90669 7.97916 1.8435 7.97935 1.18771 8.63514C0.531758 9.29109 0.531758 10.3546 1.18771 11.0106L7.91543 17.7383L17.9392 4.85203C18.533 4.08864 18.4224 2.99342 17.6879 2.36417Z"
          fill="url(#paint0_linear_472_1047)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_472_1047"
            x1="4.69174e-08"
            y1="9.66328"
            x2="19"
            y2="9.66328"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#2825C7" />
            <stop offset="1" stopColor="#6A33DF" />
          </linearGradient>
        </defs>
      </svg>
    ),
    para4: "Aliquet vel turpis ferm",
    btn: "Get started",
  },
];
export const Languages = [
  "English",
  "Chinese"
];

export const StyleDAta = [
  "Style",
  "Balanced",
  "Conversational",
  "Child",
  "Old man ",
  "Young boy",
  "Young girl",
  "Style",
  "Balanced",
  "Conversational",
  "Child",
  "Old man ",
  "Young boy",
  "Young girl",
  "Style",
  "Balanced",
  "Conversational",
  "Child",
  "Old man ",
  "Young boy",
  "Young girl",
  "Style",
  "Balanced",
  "Conversational",
  "Child",
  "Old man ",
  "Young boy",
  "Young girl",
  "Style",
  "Balanced",
  "Conversational",
  "Child",
  "Old man ",
  "Young boy",
  "Young girl",
];

export const ModelDAta = [
  "Model",
  "V1.1",
  "V2.3",
];

