import Transforming from "../components/Transforming";
import NavBar from "../components/NavBar";
import RecentVoice from "../components/RecentVoice";
import Footer from "../components/FooterUser";

function AddVoice() {
  document.documentElement.scrollTop = 0;

  return (
    <>
      <NavBar />
      <RecentVoice />
      <Footer />
    </>
  );
}

export default AddVoice;
