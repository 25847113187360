import React from "react";
import Ticker from "react-ticker";

const TopNotice = () => (
        <Ticker>
            {() => (
                <div className="wait-time-top-notice">
                    <div className="background-notice"></div>
                    <div className="text-wait-time-top-notice">
                        Due to the rapid growth of user volume, the 1.0 version requires a longer waiting time. Version 2.0 is being urgently upgraded, please wait patiently. Thank you for your support.
                    </div>
                </div>
            )}
        </Ticker> 
)

export default TopNotice