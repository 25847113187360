import React, {Component, createRef, useRef} from "react";

import { getDownloadURL, deleteGenerateVoiceFile} from "./public/main";
import {url } from './public/config';
import {eventBus, eventBus2} from "./EventsBus";

class AudioPlay extends Component {
    constructor(props) {
        super(props);
        const audioRef = createRef()

        this.state = {
            rateList: [1.0, 1.25, 1.5, 2.0],
            playRate: 1.0,
            isPlay: false,
            isMuted: false,
            volume: 100,
            allTime: 0,
            currentTime: 0,
            isLoading: false,
            src:"",
            s3Name: ""
        };

        this.audioRef = React.createRef(); // 创建一个对 <audio> 元素的引用
    }

    getBlobSrcDownload = () =>{
        return new Promise((resolve, reject) => {
            console.log("request")
            getDownloadURL(this.props.s3Name).then((res) => {
                let downloadUrl = res.msg;
                console.log("download URL:" + downloadUrl)
                fetch(downloadUrl)
                    .then((response) => response.blob())
                    .then((res) => {
                        //获取文件格式
                        var index = downloadUrl.lastIndexOf(".");
                        //获取文件后缀判断文件格式
                        let blod = new Blob([res], {type: 'audio/wav'});
                        console.log("blod : " ,blod)
                        const audioUrl = URL.createObjectURL(blod);
                        console.log("srcUrl : " + audioUrl)

                        // 设置audio元素的src属性为Blob URL
                        this.audioRef.current.src = audioUrl;
                        this.audioRef.current.addEventListener('loadedmetadata', () => {
                            console.log("alltime == " + this.audioRef.current.duration)
                            this.setState({ allTime: this.audioRef.current.duration });
                        });

                        // 监听音频播放事件以更新当前时间
                        this.audioRef.current.addEventListener('timeupdate', () => {
                            this.setState({ currentTime: this.audioRef.current.currentTime });
                        });

                        this.audioRef.current.addEventListener('ended', () => {
                            this.audioRef.current.pause();
                            this.setState({
                                isPlay: false,
                            });
                        });

                        this.setState({src: audioUrl})
                        this.setState({
                            isLoading: false,
                        });

                        const link = document.createElement('a');
                        link.style.display = 'none';
                        document.body.appendChild(link);
                        let {  fileName} = this.props;
                        // 设置a标签的href属性和download属性
                        link.href = downloadUrl;
                        link.download = fileName;

                        // 触发a标签的点击事件
                        link.click();

                        // 清理创建的元素
                        document.body.removeChild(link);
                        window.URL.revokeObjectURL(url);

                        resolve(true);
                    });
            })
        })
    }

    getBlobSrcPlay = () =>{
        return new Promise((resolve, reject) => {
            console.log("request")
            getDownloadURL(this.props.s3Name).then((res) => {
                let downloadUrl = res.msg;
                console.log("download URL:" + downloadUrl)
                fetch(downloadUrl)
                    .then((response) => response.blob())
                    .then((res) => {
                        //获取文件后缀判断文件格式
                        let blod = new Blob([res], {type: 'audio/wav'});
                        console.log("blod : " ,blod)
                        const audioUrl = URL.createObjectURL(blod);
                        console.log("srcUrl : " + audioUrl)

                        this.setState({src: audioUrl})
                        this.setState({
                            isLoading: false,
                        });

                        this.audioRef.current.src = audioUrl;
                        this.audioRef.current.addEventListener('loadedmetadata', () => {
                            this.setState({ allTime: this.audioRef.current.duration });
                        });

                        // 监听音频播放事件以更新当前时间
                        this.audioRef.current.addEventListener('timeupdate', () => {
                            this.setState({ currentTime: this.audioRef.current.currentTime });
                        });

                        this.audioRef.current.addEventListener('ended', () => {
                            this.audioRef.current.pause();
                            this.setState({
                                isPlay: false,
                            });
                        });

                        this.audioRef.current.play();
                        this.setState({
                            isPlay: true,
                        });

                        resolve(true);
                    });
            })
        })
    }

    handleDownloadClick =()=>  {
        let {  fileName} = this.props;
        if(this.state.src === "" || this.state.src === undefined){
            console.log("request")
            this.setState({
                isLoading: true,
            });
            this.getBlobSrcDownload().then((res)=>{

            })
        }else{
            // 创建一个隐藏的a标签
            const link = document.createElement('a');
            link.style.display = 'none';
            document.body.appendChild(link);

            // 设置a标签的href属性和download属性
            link.href = this.state.src;
            link.download = fileName;

            // 触发a标签的点击事件
            link.click();

            // 清理创建的元素
            document.body.removeChild(link);
            window.URL.revokeObjectURL(url);
        }

    };


    componentDidMount() {}

    setAudioSrc = (src) =>{
        this.setState({s3Name: src})
    }

    formatSecond(time) {
        const second = Math.floor(time % 60);
        let minite = Math.floor(time / 60);
        return `${minite}:${second >= 10 ? second : `0${second}`}`;
    }


    playAudio = () => {
        if(this.state.src === "" || this.state.src === undefined){
            console.log("request")
            this.setState({
                isLoading: true,
            });
            this.getBlobSrcPlay().then((res) =>{

            })
        }else{
            this.audioRef.current.play();
            this.setState({
                isPlay: true,
            });
        }
    };

    pauseAudio = () => {
        this.audioRef.current.pause();
        this.setState({
            isPlay: false,
        });
    };


    oNEndedPlay = ()=>{
        this.audioRef.current.pause();
        this.setState({
            isPlay: false,
        });
    };

    changeTime = (e) => {
        const { value } = e.target;
        this.setState({
            currentTime: value,
        });
        this.audioRef.current.currentTime = value;
        if (value === this.audioRef.current.duration) {
            this.setState({
                isPlay: false,
            });
        }
    };


    deleteFile = () =>{
        const { s3Name, deleteFile} = this.props;
        if(deleteGenerateVoiceFile(s3Name) === 200)
        {
            deleteFile();
        }
    }

    render() {
        let { src, id, showDownload , showTime, s3Name, DownloadFileName, fileName} = this.props;

        const {
            isPlay,
            allTime,
            currentTime,
            isLoading,
        } = this.state;

        return (
            <div>

                <audio ref={this.audioRef} onEnded={this.oNEndedPlay} ></audio>

                <div className="marginTop-20">

                    <div className="rowFlex ">

                        <div className="rowFlex gap-5 marginLeft10">
                            <div>{fileName}</div>
                            <div>{" "}</div>
                        </div>

                        <div className="rowFlex marginRight40">
                            <a className="d-flex align-items-center  justify-content-center btn_play_simple"  href="#" onClick={this.handleDownloadClick}>
                                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="18" cy="18" r="17.5" fill="white" stroke="#695CFF"/>
                                    <path
                                        d="M10 23V25C10 25.5304 10.2107 26.0391 10.5858 26.4142C10.9609 26.7893 11.4696 27 12 27H24C24.5304 27 25.0391 26.7893 25.4142 26.4142C25.7893 26.0391 26 25.5304 26 25V23M13 17L18 22M18 22L23 17M18 22V10"
                                        stroke="#695CFF" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                </svg>
                            </a>

                            <div className="d-flex align-items-center  justify-content-center btn_play_simple autoMarginleft" onClick={this.deleteFile}>
                                <svg width="36" height="36" viewBox="0 0 36 36" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <circle cx="18" cy="18" r="17.5" fill="white" stroke="#695CFF"/>
                                    <path
                                        d="M26.1 11.7H22.5V10.8C22.5 10.08 22.221 9.40498 21.708 8.89198C21.195 8.37898 20.52 8.09998 19.8 8.09998H16.2C15.48 8.09998 14.805 8.37898 14.292 8.89198C13.779 9.40498 13.5 10.08 13.5 10.8V11.7H9.9C9.405 11.7 9 12.105 9 12.6C9 13.095 9.405 13.5 9.9 13.5H10.8V25.2C10.8 25.92 11.079 26.595 11.592 27.108C12.105 27.621 12.78 27.9 13.5 27.9H22.5C23.22 27.9 23.895 27.621 24.408 27.108C24.921 26.595 25.2 25.92 25.2 25.2V13.5H26.1C26.595 13.5 27 13.095 27 12.6C27 12.105 26.595 11.7 26.1 11.7ZM15.3 10.8C15.3 10.566 15.399 10.332 15.561 10.161C15.732 9.99898 15.966 9.89998 16.2 9.89998H19.8C20.034 9.89998 20.268 9.99898 20.439 10.161C20.601 10.332 20.7 10.566 20.7 10.8V11.7H15.3V10.8ZM23.4 25.2C23.4 25.434 23.301 25.668 23.139 25.839C22.968 26.001 22.734 26.1 22.5 26.1H13.5C13.266 26.1 13.032 26.001 12.861 25.839C12.699 25.668 12.6 25.434 12.6 25.2V13.5H23.4V25.2Z"
                                        fill="#695CFF"/>
                                    <path
                                        d="M16.2 23.4C15.705 23.4 15.3 22.995 15.3 22.5V17.1C15.3 16.605 15.705 16.2 16.2 16.2C16.695 16.2 17.1 16.605 17.1 17.1V22.5C17.1 22.995 16.695 23.4 16.2 23.4Z"
                                        fill="#695CFF"/>
                                    <path
                                        d="M19.8 23.4C19.305 23.4 18.9 22.995 18.9 22.5V17.1C18.9 16.605 19.305 16.2 19.8 16.2C20.295 16.2 20.7 16.605 20.7 17.1V22.5C20.7 22.995 20.295 23.4 19.8 23.4Z"
                                        fill="#695CFF"/>
                                </svg>
                            </div>
                        </div>
                    </div>

                <div className="d-flex gap-2 align-items-center  btn_play marginTop10">
                    {isPlay  &&
                        <div onClick={this.pauseAudio}>
                            <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M6.86899 38.684C4.72001 36.6084 3.00591 34.1257 1.8267 31.3806C0.647501 28.6355 0.0268104 25.683 0.000849527 22.6955C-0.0251113 19.708 0.544177 16.7452 1.6755 13.98C2.80682 11.2149 4.47751 8.70268 6.5901 6.5901C8.70268 4.47751 11.2149 2.80682 13.98 1.6755C16.7452 0.544177 19.708 -0.0251113 22.6955 0.000849527C25.683 0.0268104 28.6355 0.647501 31.3806 1.8267C34.1257 3.00591 36.6084 4.72001 38.684 6.86899C42.7825 11.1125 45.0504 16.7961 44.9991 22.6955C44.9479 28.5949 42.5816 34.2382 38.4099 38.4099C34.2382 42.5816 28.5949 44.9479 22.6955 44.9991C16.7961 45.0504 11.1125 42.7825 6.86899 38.684ZM16.0265 13.7765V31.7765H20.5265V13.7765H16.0265ZM25.0265 13.7765V31.7765H29.5265V13.7765H25.0265Z" fill="#695CFF"/>
                            </svg>
                        </div>}
                    {!isPlay &&
                        <div onClick={this.playAudio} style={{position: "relative"}}>
                            <svg width="45" height="45" viewBox="0 0 45 45" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M22.5 0C18.0499 0 13.6998 1.3196 9.99968 3.79193C6.29957 6.26427 3.41569 9.77828 1.71272 13.8896C0.00974889 18.001 -0.435826 22.525 0.432341 26.8895C1.30051 31.2541 3.44343 35.2632 6.59011 38.4099C9.73679 41.5566 13.7459 43.6995 18.1105 44.5677C22.4751 45.4358 26.9991 44.9903 31.1104 43.2873C35.2217 41.5843 38.7357 38.7004 41.2081 35.0003C43.6804 31.3002 45 26.9501 45 22.5C45 19.5453 44.418 16.6194 43.2873 13.8896C42.1566 11.1598 40.4992 8.67942 38.4099 6.5901C36.3206 4.50078 33.8402 2.84344 31.1104 1.71271C28.3806 0.58198 25.4548 0 22.5 0ZM18 32.625V12.375L31.5 22.5L18 32.625Z" fill="#695CFF"/>
                            </svg>
                            {isLoading &&
                                <span className="border-loader_small "></span>
                            }
                        </div>
                    }


                    <div className="d-flex gap-2 align-items-center w-100">
                        <div className="music-timeline ms-1 position-relative">
                            {showTime && (
                                <p className="mb-0 line_height150 time-played2 position-absolute clr_primary fs_md ff_work line_150 opacity06 ">
                                    {this.formatSecond(currentTime) + "/" + this.formatSecond(allTime)}
                                </p>
                            )}

                            <input
                                className="slidAudio"
                                type="range"
                                step="0.01"

                                max={allTime}
                                value={currentTime}
                                onChange={this.changeTime}
                            />
                        </div>
                    </div>

                    {showDownload &&  ( <svg
                        width="30"
                        height="30"
                        viewBox="0 0 24 25"
                        fill="none"
                        xmlns="http://www.w3.org/2000/svg">
                        <path
                            d="M4 17.129V19.129C4 19.6595 4.21071 20.1682 4.58579 20.5432C4.96086 20.9183 5.46957 21.129 6 21.129H18C18.5304 21.129 19.0391 20.9183 19.4142 20.5432C19.7893 20.1682 20 19.6595 20 19.129V17.129M7 11.129L12 16.129M12 16.129L17 11.129M12 16.129V4.12903"
                            stroke="#C0C0C0"
                            strokeWidth="2"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                        />
                    </svg>) }

                </div>
                </div>
            </div>
        );
    }
}

export default AudioPlay;

