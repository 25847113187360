import React, {useEffect, useState, useRef} from "react";
import Swal from "sweetalert2";

import { GoogleLogin } from 'react-google-login';
import { gapi } from 'gapi-script';
import {url , githubLoginUrl, googleClientId} from './public/config.js';

import {
  settimeForget,
  settime,
  forgetClick,
  sendCodeClickForget,
  registerClick,
  sendCodeClick,
  login, loginGoogle
} from './public/login_register.js';

import {Modal} from "antd";
import {redirect} from "react-router-dom";
import jwt_decode from "jwt-decode";
import {GoogleOAuthProvider} from "@react-oauth/google";


function BtnSendCode() {
  const [buttonName, setButtonName] = useState("Send Code");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);
  const ref = useRef();

  let count = 61;
  function timeCall(){
    if (count == 0) {
      setButtonName("Send Code");
      count = 61;
      clearInterval(ref.current);
      setIsButtonDisabled(false);
    } else {
      if (count == 61) {
        sendCodeClick();
      }
      count--;
      let text = `Resend (${count})`;
      setButtonName(text)
    }
  }
  const handleClick = event => {
      setIsButtonDisabled(true);
      let timer =setInterval(() => {
        console.log('触发了')
        timeCall();
      }, 1000)
      ref.current = timer;
      console.log("启动定时")
  };
  return (
      <button id="send-code" className="btn_common  sendCodeBtn" disabled={isButtonDisabled} onClick={handleClick} >
        {buttonName}
      </button>
  );
}


function SigNUp() {
  const responseGoogle = (response) => {
    console.log(response);
    const userObject = jwt_decode(response.credential);
    console.log(userObject);
    let email = userObject.email
    localStorage.setItem("SpeakingAiusername", userObject.email);

    loginGoogle(email).then((res)=>{
      if(res.result ){
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Sign In successfully",
          showConfirmButton: false,
          timer: 2500,
        });
      }
    })
  }

  useEffect(() => {
  });


  const [formData, setFormData] = useState({
    Email: "",
    PassWord: "",
  });

  const FormSub = (event) => {
    event.preventDefault();

    setFormData({
      Email: "",
      PassWord: "",
    });
    registerClick().then((res)=>{
      if(res.success){
        /* 之前的弹窗
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Sign Up successfully, please Sign In",
          showConfirmButton: false,
          timer: 1500,
        });
        window.location.href = "/SignIn"; */
        Swal.fire({
          position: "center",
          icon: "success",
          iconColor: "#695CFF",
          title: "Thanks for registering",
          width: "680px",
          html: `<div style="text-align: left; 
                             color: rgba(63, 63, 63, 0.50);
                             font-family: Work Sans;
                             font-size: 24px;
                             font-style: normal;
                             font-weight: 500;
                             line-height: 32px;">
                  Due to the rapid growth of user volume, the 1.0 version requires a longer waiting time.

                  <br/><br/>Version 2.0 is being urgently upgraded, please wait patiently.
          
                  <br/><br/>Thank you for your support. 
                </div>`,
          showConfirmButton: true,
          customClass: {
            popup:'register-popup-container',
            title:'register-popup-title',
            html:'register-popup-text',
            confirmButton: 'register-popup-button'
          }
        }).then(()=>(window.location.href = "/SignIn"));
      } else {
        
        Swal.fire({
          position: "center",
          icon: "error",
          title: res.message,
          showConfirmButton: true
      }); 
      } 
    })

  };

  const handleClick = event => {

  };

  const handleClickTerms = event =>{

  }

  const handleClickService= event =>{

  }

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  return (
    <>
      <section className="w-100">
        <div className="container  container-custom">
          <div className="d-flex justify-content-center ">
            <div className="col-xl-5 col-lg-6 col-md-8 ">
              <div className="signup_box">
                <h2
                  className="fs_4xl ff_work fw-semibold clr_off_black line_height122 mb-sm-3 mb-2 pb-md-3"
                  style={{ letterSpacing: "-0.528px" }}>
                  Sign Up
                </h2>
                <p className="clr_primary fs_md ff_work fw-medium opacity08 mb-md-4 mb-3">
                  Already have an account?&nbsp;<a href="/SignIn">Sign in</a>
                </p>

                <form action="form" onSubmit={FormSub}>
                  <span
                    style={{ letterSpacing: " -0.176px", marginLeft: "1px" }}
                    className="d-block clr_primary fs_md ff_work fw-normal line_height150">
                    Email
                  </span>
                  <input
                    name="Email"
                    id="Email"
                    onChange={handleChange}
                    required
                    value={formData.Email}
                    className="w-100 clr_primary fs_md ff_work fw-normal p-2 mb-3"
                    placeholder="Enter Your Email Address"
                    type="Email"
                  />
                  <div  className="hide-css ">
                    <span
                        style={{ letterSpacing: " -0.176px", marginLeft: "1px" }}
                        className="d-block clr_primary fs_md ff_work fw-normal line_height150 ">
                      E-mail verification code
                    </span>
                    <div  className="d-flex  gap-2 hide-css ">
                      <input
                          name="code"
                          id="code"
                          className="w-100 clr_primary fs_md ff_work fw-normal p-2 mb-lg-4 mb-3"
                          placeholder="Enter E-mail verification code"
                          type="text"
                      />
                      <BtnSendCode>

                      </BtnSendCode>
                    </div>
                  </div>

                  <span
                    style={{ letterSpacing: " -0.176px", marginLeft: "1px" }}
                    className="d-block clr_primary fs_md ff_work fw-normal line_height150 ">
                    Password
                  </span>
                  <input
                    name="PassWord"
                    id="PassWord"
                    required
                    className="w-100 clr_primary fs_md ff_work fw-normal p-2 mb-lg-4 mb-3"
                    placeholder="Enter Your Password"
                    type="password"
                  />

                  <span
                      style={{ letterSpacing: " -0.176px", marginLeft: "1px" }}
                      className="d-block clr_primary fs_md ff_work fw-normal line_height150 ">
                    Confirm Password
                  </span>
                  <input
                      name="Confirm-PassWord"
                      id="Confirm-PassWord"
                      required
                      className="w-100 clr_primary fs_md ff_work fw-normal p-2 mb-lg-4 mb-3"
                      placeholder="Confirm Your Password"
                      type="password"
                  />

                  {/*
                  <div className="d-flex align-items-center gap-2 pt-lg-2 pb-md-4 pb-3 mb-lg-2">
                    <input required className="checkbox_" type="checkbox" />
                    <p className="mb-0 clr_primary fs_md ff_work fw-normal opacity07">
                      I want to receive product updates.
                    </p>
                  </div>
                  */}


                  <button id="sign-up" className="btn_common border-0 d-flex w-100 text-center justify-content-center position-relative"  onClick={handleClick}
                          type="submit"
                  >
                    Sign up
                  </button>


                  <div className="d-flex align-items-center gap-2 pt-lg-2 pb-md-4 pb-3 mb-lg-2 marginTop20 text-center">
                    <p className="mb-0 clr_primary fs_md ff_work fw-normal opacity07">
                      By signing up, you are agreeing to Speaking.ai&nbsp;
                      <DialogTerms className="cursor_pointer Signin_link fw-normal" >
                      </DialogTerms>
                      and &nbsp;
                      <DialogService className="cursor_pointer Signin_link fw-normal" >
                      </DialogService>
                    </p>
                  </div>

                </form>

              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

const DialogTerms = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  let [auodioContainerSate, setauodioContainerSate] = useState(0);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  let updateAudioList = () =>{
    setauodioContainerSate(auodioContainerSate = auodioContainerSate + 1);
    console.log("add control2")
  }


  return (
      <>
        <label className=" cursor_pointer Signin_link fw-normal" onClick={showModal} >
          terms of service  &nbsp;
        </label>
        <Modal title="Term of Services"   open={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={1000} maskClosable={false} cancelButtonProps={{ style: { display: 'none' } }}>
          <span
              style={{ letterSpacing: " -0.176px", marginLeft: "1px", marginTop: "10px" }}
              className="d-block clr_primary fs_md ff_work fw-normal line_height150 fw-semibold">
              Welcome to Speaking.AI!
          </span>
          <div className="bkLabelQuality" >
            <p className=" line_height150 letter_spac_minus_01">
              These are the terms and conditions for:
              - https://speaking.ai
              By using the platform and our services, you agree to these terms and conditions and our privacy policy.
              In these terms and conditions, the words "platform" refers to the Speaking.AI website, unless mentioned
              individually in the document, "we", "us", "our" and "Speaking.AI" refers to Speaking.AI and "you"
              and "user" refers to you, the user of Speaking.AI.
              The following terms and conditions apply to your use of the platform. This includes mobile and tablet
              versions, as well as any other version of Speaking.AI accessible via desktop, mobile, tablet, social
              media or other devices.
              PLEASE READ THESE CONDITIONS CAREFULLY BEFORE USING THE FUNCTIONALITIES AVAILABLE ON THE PLATFORM.
            </p>
          </div>

          <span
              style={{ letterSpacing: " -0.176px", marginLeft: "1px", marginTop: "10px" }}
              className="d-block clr_primary fs_md ff_work fw-normal line_height150 fw-semibold">
              1. ACCEPTANCE OF TERMS
          </span>

          <div className="bkLabelQuality" >
            <p className=" line_height150 letter_spac_minus_01">
              By using the platform, you agree to be bound by this agreement. If you do not agree to the terms of
              this agreement, you must not use our services and our platform. We may modify this agreement from time
              to time, and such modification will be effective upon posting on the platform. You agree to be bound by
              any modifications to these terms and conditions when you use Speaking.AI following the posting of such
              modification; therefore, it is important that you review this agreement regularly.
              Access to the platform is available for all ages. It is the responsibility of parents or legal guardians
              to determine whether the content available on the platform is appropriate for their children or minors in
              their care.By using the platform, you represent and warrant that you have the full right, power and
              authority to enter into this agreement and to fully perform all of your obligations hereunder. You
              further represent and warrant that you are not under any legal incapacity or contractual restriction
              that would prevent you from entering into this agreement.
            </p>
          </div>

          <span
              style={{ letterSpacing: " -0.176px", marginLeft: "1px", marginTop: "10px" }}
              className="d-block clr_primary fs_md ff_work fw-normal line_height150 fw-semibold">
              2. NOTIFICATIONS
          </span>

          <div className="bkLabelQuality" >
            <p className=" line_height150 letter_spac_minus_01">
              By providing your email address to Speaking.AI, you agree that we may use your email address to send you
              notifications about Speaking.AI. We may also use your email address to send you notifications and other
              messages, such as changes to service features, news and special content. If you do not wish to receive
              these emails, you may opt-out of receiving them by submitting your unsubscribe request through the
              contact information or by using the "unsubscribe" option in the emails. Opting out may prevent you
              from receiving notifications and mailings about updates, news or special content.
            </p>
          </div>

          <span
              style={{ letterSpacing: " -0.176px", marginLeft: "1px", marginTop: "10px" }}
              className="d-block clr_primary fs_md ff_work fw-normal line_height150 fw-semibold">
              3. ACCOUNT
          </span>

          <div className="bkLabelQuality" >
            <p className=" line_height150 letter_spac_minus_01">
              Users will be able to register on the platform. Users will be able to register and log in through their
              Facebook and Google accounts. When registering on the platform, the user must choose a password, and
              may be asked for additional information, such as email address. You are responsible for maintaining
              the confidentiality of your password and account information, and are fully responsible for all
              activities that occur under your password or account. You agree to (a) immediately notify
              Speaking.AI of any unauthorized use of your password or account or any other breach of security,
              and (b) ensure that you log out of your account at the end of each session. You may never use
              another user's account without Speaking.AI's prior authorization. Speaking.AI will not be liable
              for any loss or damage arising from your failure to comply with this agreement.
              Users may terminate their accounts at any time and for any reason by sending us their request
              through our contact information. Such cancellation will only result in the deletion of the account
              and the deletion of all personal data provided to Speaking.AI. Speaking.AI reserves the right to
              terminate your account or your access immediately, with or without notice, and without liability
              to you, if Speaking.AI believes that you have violated any of these terms.
            </p>
          </div>

          <span
              style={{ letterSpacing: " -0.176px", marginLeft: "1px", marginTop: "10px" }}
              className="d-block clr_primary fs_md ff_work fw-normal line_height150 fw-semibold">
             4. PLANS
          </span>

          <div className="bkLabelQuality" >
            <p className=" line_height150 letter_spac_minus_01">
              Speaking.AI offers the following plans:
              - Basic
              - Regular
              - Startup
              When a user purchases a plan, Speaking.AI will send a confirmation email. This confirmation email will
              be produced automatically so that the user will have confirmation of the payment and the start of the
              plan. If the user does not receive the email confirmation of the purchase and the start of the plan,
              it is possible that it has been sent to your spam folder.
              Speaking.AI may change or discontinue the availability of plans at any time at its sole discretion.
              If a plan purchase is cancelled, the payment made for the plan will be refunded for the applicable
              billing period. This does not affect your statutory rights.
              Plans include monthly automatic billing payments. You authorize Speaking.AI to renew your plan and
              to charge you periodically and progressively on each billing date. The plan billing date is the date
              on which you purchase the plan and make the first payment. On the corresponding billing date, you
              will automatically be charged the corresponding plan fee. The plan will remain active until you cancel
              it or we terminate it. You must cancel your plan before it renews to avoid the next billing period.
              We will bill you for the plan billing fee in the payment method you choose during registration and
              plan purchase.
              Plans will automatically renew for an additional period unless cancelled prior to the next billing
              period. To cancel plans, users must submit a cancellation request through our contact information and
              the plan will be cancelled for the next billing period. If a plan is cancelled, the user may continue
              to use the website payment features for the plan until the next billing period, at which time the plan
              and access to the website payment features will be cancelled.
            </p>
          </div>

          <span
              style={{ letterSpacing: " -0.176px", marginLeft: "1px", marginTop: "10px" }}
              className="d-block clr_primary fs_md ff_work fw-normal line_height150 fw-semibold">
              5. PAYMENTS
          </span>
          <div className="bkLabelQuality" >
            <p className=" line_height150 letter_spac_minus_01">
              Plans will be paid by the following payment methods:
              - Credit/debit card (Visa, Master, Discover, Amex, Diners, etc.)
              The corresponding plan payment will be charged to your credit or debit card upon completion of the
              payment and plan registration process. The plan will be activated upon completion of the payment and
              registration process and will be charged on each billing date of the plan automatically. Once the
              transaction has been processed, we will send an electronic receipt to the user's e-mail address.
              If you find any inconsistencies in your billing, please contact us via our contact details or you
              can make a complaint via the customer service of the relevant payment processor.
              If your card is declined, you will receive an error message. No payment will be charged to your card
              and no order will be processed. There may be a pending transaction on your account until your card
              issuing bank withdraws the authorization. This usually takes 2 to 5 working days. Your card may be
              declined for a number of reasons, such as insufficient funds, AVS (Address Verification System)
              mismatch or you have entered an incorrect security code.
              If your payment is declined, you will need to provide an alternative payment method or provide another
              card on which the payment can be charged and processed.
              Your payment details will be treated and retained securely and for the sole purpose of processing the
              purchase of the plans. Speaking.AI reserves the right to engage any payment platform available on the
              market, which processes your data for the sole purpose of processing the purchase of the plans.
            </p>
          </div>

          <span
              style={{ letterSpacing: " -0.176px", marginLeft: "1px", marginTop: "10px" }}
              className="d-block clr_primary fs_md ff_work fw-normal line_height150 fw-semibold">
              6. USER CONTENT
          </span>
          <div className="bkLabelQuality" >
            <p className=" line_height150 letter_spac_minus_01">
              Some features of the platform may allow users to provide content and information through the creation
              and publication of user profiles. You retain copyright or any rights you may have in the content you
              provide through the platform. Speaking.AI is not responsible for the accuracy, safety or legality of
              content posted on the platform by users. Users are solely and exclusively responsible for their content
              and the consequences of its publication. By providing content and information through the platform, the
              user grants Speaking.AI a worldwide, non-exclusive, royalty-free, fully paid-up right and license to
              host, store, transfer, display, perform, reproduce and modify the user content for the sole purpose of
              displaying it through the platform and to the general public.
              Speaking.AI is not responsible for the content of the user. The user is solely responsible for the content
              and the consequences of providing content through the platform. By providing content through the platform,
              you affirm, represent and warrant that:
              - You are the creator and owner of the content you provide through the platform, or have the necessary
              licenses, rights, consents and permissions to authorise Speaking.AI to publish and display your content
              through the platform.
              - Your content does not infringe, violate or misappropriate any third party right, including any copyright,
              trademark, patent, trade secret, moral right, right of privacy, right of publicity or any other intellectual
              property or proprietary right.
              - Your content does not defame, libel or invade the privacy, publicity or other proprietary rights of any
              other person.
              - Your content does not cause Speaking.AI to violate any law or regulation.
              - Your content could not be considered by a reasonable person to be objectionable, profane, indecent,
              pornographic, harassing, threatening, embarrassing, hateful or otherwise inappropriate.
            </p>
          </div>

          <span
              style={{ letterSpacing: " -0.176px", marginLeft: "1px", marginTop: "10px" }}
              className="d-block clr_primary fs_md ff_work fw-normal line_height150 fw-semibold">
              7. RESPONSIBLE USE OF THE PLATFORM
          </span>
          <div className="bkLabelQuality" >
            <p className=" line_height150 letter_spac_minus_01">
              The user acknowledges and agrees that the use of the platform for cloning and voice generation through
              Artificial Intelligence is subject to certain conditions and restrictions to ensure proper and ethical
              use of the technology.
              The user agrees to use the platform in a legal and ethical manner, respecting all laws, regulations and
              third party rights. It is not allowed to use the platform to generate or clone voices for fraudulent,
              misleading, defamatory, illegal, immoral or infringing the intellectual property rights of third parties.
              The user is solely responsible for any content generated or cloned through the platform. The user
              guarantees that he/she has the necessary rights to use and share such content, and that it does not
              violate the rights of third parties.
              The user undertakes to obtain all necessary consents from the persons whose voices will be cloned or
              generated through the platform. In addition, user agrees to respect the privacy and proprietary rights of
              the persons whose voices are used in the generation or cloning.
              User acknowledges that the platform is provided solely for user's personal or internal business use, as
              applicable. It is expressly prohibited to resell, sublicense, distribute or commercially exploit the
              platform or any output generated by the platform without the prior written consent of Speaking.AI.
            </p>
          </div>

          <span
              style={{ letterSpacing: " -0.176px", marginLeft: "1px", marginTop: "10px" }}
              className="d-block clr_primary fs_md ff_work fw-normal line_height150 fw-semibold">
              8. DISCLAIMER
          </span>
          <div className="bkLabelQuality" >
            <p className=" line_height150 letter_spac_minus_01">
              The platform is provided "as is" and Speaking.AI does not guarantee that it will be free of errors or
              interruptions. While security measures have been implemented and reasonable efforts have been made to
              provide reliable service, Speaking.AI is not responsible for any failures, delays, errors or interruptions
              in voice generation and cloning.
              Speaking.AI makes every effort to achieve maximum accuracy and quality in voice generation and cloning.
              However, due to the complexity of the technology and inherent limitations, the accuracy, completeness or
              adequacy of the results generated by the platform cannot be guaranteed.
              The user of the platform assumes full responsibility for the use of the voice generation and voice cloning
              services. Speaking.AI is not responsible for any improper, illegal or unethical use of the platform or any
              consequences arising from such use.
              In no event shall Speaking.AI, its directors, employees or representatives be liable for any direct or
              indirect loss, damage or injury, including, but not limited to, loss of data, business interruption,
              consequential, incidental, special or punitive damages, which may arise as a result of the use of the platform.
              In the event that, notwithstanding the foregoing limitations, Speaking.AI is held liable, its total liability
              shall be limited to the total amount paid by the user for access to the platform in the time period in which
              the event giving rise to liability occurred.
              Please note that the use of the platform and the contents generated by AI through the platform are for
              entertainment purposes only. Speaking.AI is not responsible if the user or any third party uses the platform
              for other purposes. Speaking.AI does not accept any claim for the use or misuse of the platform.
            </p>
          </div>

          <span
              style={{ letterSpacing: " -0.176px", marginLeft: "1px", marginTop: "10px" }}
              className="d-block clr_primary fs_md ff_work fw-normal line_height150 fw-semibold">
              9. LICENSE TO USE THE PLATFORM
          </span>
          <div className="bkLabelQuality" >
            <p className=" line_height150 letter_spac_minus_01">
              Speaking.AI grants you a personal, worldwide, royalty-free, non-assignable, non-exclusive license to use
              the Speaking.AI platform. This license is for the sole purpose of allowing you to use the features available
              on the platform, such as cloning and generating speech through written text using AI, in the manner permitted
              by these terms and conditions. You may not copy, modify, distribute, sell or lease any part of our platform
              or the included software, nor may you reverse engineer or attempt to extract the source code of such software,
              unless such restrictions are prohibited by law or you have our written permission.
              The user agrees not to use the platform in a negligent, fraudulent or unlawful manner. The user also agrees
              not to engage in any conduct or action that could damage the image, interests or rights of Speaking.AI or
              third parties. Speaking.AI reserves the right to terminate the user's access to the platform immediately,
              with or without notice, and without liability to the user, if Speaking.AI believes that the user has violated any of
            </p>
          </div>

          <span
              style={{ letterSpacing: " -0.176px", marginLeft: "1px", marginTop: "10px" }}
              className="d-block clr_primary fs_md ff_work fw-normal line_height150 fw-semibold">
              10. COPYRIGHT
          </span>
          <div className="bkLabelQuality" >
            <p className=" line_height150 letter_spac_minus_01">
              All materials on Speaking.AI, including, without limitation, names, logos, trademarks, games, images, text,
              columns, graphics, videos, photographs, illustrations, software and other elements are protected by copyrights,
              trademarks and/or other intellectual property rights owned and controlled by Speaking.AI or by third parties
              that have licensed or otherwise provided their material to the platform. You acknowledge and agree that all
              Materials on Speaking.AI are made available for limited, non-commercial, personal use only. Except as
              specifically provided herein. No material may be copied, reproduced, republished, sold, downloaded, posted,
              transmitted, or distributed in any way, or otherwise used for any purpose, by any person or entity, without
              Speaking.AI prior express written permission. You may not add, delete, distort, or otherwise modify the material.
              Any unauthorized attempt to modify any material, to defeat or circumvent any security features, or to utilize
              Speaking.AI or any part of the material for any purpose other than its intended purposes is strictly prohibited.
            </p>
          </div>

          <span
              style={{ letterSpacing: " -0.176px", marginLeft: "1px", marginTop: "10px" }}
              className="d-block clr_primary fs_md ff_work fw-normal line_height150 fw-semibold">
              11. COPYRIGHT INFRINGEMENT
          </span>
          <div className="bkLabelQuality" >
            <p className=" line_height150 letter_spac_minus_01">
              Speaking.AI will respond to all inquiries, complaints and claims regarding alleged infringement for failure
              to comply with or violation of the provisions contained in the Digital Millennium Copyright Act (DMCA).
              Speaking.AI respects the intellectual property of others, and expects users to do the same.  If you believe,
              in good faith, that any material provided on or in connection with the website infringes your copyright
              or other intellectual property right, please send us your copyright infringement request pursuant to Section
              512 of the Digital Millennium Copyright Act (DMCA), via our contact information, with the following information:
              - Identification of the intellectual property right that is allegedly infringed. All relevant registration numbers or a statement regarding ownership of the work should be included.
              - A statement specifically identifying the location of the infringing material.
              - Your name, address, telephone number and email address.
              - A statement by you that you have a good faith belief that the use of the allegedly infringing material is not authorized by the copyright owner, or its agents, or by law.
              - A statement by you, made under penalty of perjury, that the information in your notification is accurate, and that you are the copyright owner or authorized to act on its behalf.
              - An electronic or physical signature of the copyright owner or of the person authorized to act on the copyright owner's behalf.
            </p>
          </div>

          <span
              style={{ letterSpacing: " -0.176px", marginLeft: "1px", marginTop: "10px" }}
              className="d-block clr_primary fs_md ff_work fw-normal line_height150 fw-semibold">
              12. PROHIBITED ACTIVITIES
          </span>
          <div className="bkLabelQuality" >
            <p className=" line_height150 letter_spac_minus_01">
              The content and information available on the platform (including, but not limited to, data, information,
              text, music, sound, photos, graphics, video, maps, icons or other material), as well as the infrastructure
              used to provide such content and information, is proprietary to Speaking.AI or licensed to the Speaking.AI
              by third parties. For all content other than your content, you agree not to otherwise modify, copy, distribute,
              transmit, display, perform, reproduce, publish, license, create derivative works from, transfer, or sell
              or re-sell any information, software or services obtained from or through the platform. In addition, the
              following activities are prohibited:
              - Access, monitor, reproduce, distribute, transmit, broadcast, display, sell, license, copy or otherwise exploit any content of the services, including but not limited to, using any robot, spider, scraper or other automated means or any manual process for any purpose not in accordance with this agreement or without our express written permission.
              - Violate the restrictions in any robot exclusion headers on the services or bypass or circumvent other measures employed to prevent or limit access to the services.
              - Take any action that imposes, or may impose, in our discretion, an unreasonable or disproportionately large load on our infrastructure.
              - Deep-link to any portion of the services for any purpose without our express written permission.
              - "Frame", "mirror" or otherwise incorporate any part of the platform into any other platform or service without our prior written authorization.
              - Attempt to modify, translate, adapt, edit, decompile, disassemble, or reverse engineer any software programs used by Speaking.AI in connection with the platform.
              - Circumvent, disable or otherwise interfere with security-related features of the platform or features that prevent or restrict use or copying of any content.
            </p>
          </div>

          <span
              style={{ letterSpacing: " -0.176px", marginLeft: "1px", marginTop: "10px" }}
              className="d-block clr_primary fs_md ff_work fw-normal line_height150 fw-semibold">
              13. DISCLAIMER OF WARRANTIES
          </span>
          <div className="bkLabelQuality" >
            <p className=" line_height150 letter_spac_minus_01">
              Except as provided above we can give no other warranties, conditions or other terms, express or implied, statutory or otherwise and all such terms are hereby excluded to the maximum extent permitted by law.
              You will be responsible for any breach of these terms by you and if you use the platform in breach of these terms you will be liable to and will reimburse Speaking.AI for any loss or damage caused as a result.
              Speaking.AI shall not be liable for any amount for failure to perform any obligation under this Agreement if such failure is due to the occurrence of any unforeseen event beyond its reasonable control, including, without limitation, Internet outages, communications outages, fire, flood, or any uncontrollable act of nature.
              Subject as aforesaid, to the maximum extent permitted by law, Speaking.AI excludes liability for any loss or damage of any kind howsoever arising, including without limitation any direct, indirect or consequential loss whether or not such arises out of any problem you notify to Speaking.AI and Speaking.AI shall have no liability to pay any money by way of compensation, including without limitation all liability in relation to:
              - Any incorrect or inaccurate information on the platform.
              - The infringement by any person of any Intellectual Property Rights of any third party caused by their use of the platform.
              - Any loss or damage resulting from your use or the inability to use the platform or resulting from unauthorized access to, or alteration of your transmissions or data in circumstances which are beyond our control.
              - Any loss of profit, wasted expenditure, corruption or destruction of data or any other loss which does not directly result from something we have done wrong.
              - Any amount or kind of loss or damage due to viruses or other malicious software that may infect a user's computer equipment, software, data or other property caused by persons accessing or using content from the platform or from transmissions via emails or attachments received from Speaking.AI.
              - All representations, warranties, conditions and other terms which but for this notice would have effect.
            </p>
          </div>

          <span
              style={{ letterSpacing: " -0.176px", marginLeft: "1px", marginTop: "10px" }}
              className="d-block clr_primary fs_md ff_work fw-normal line_height150 fw-semibold">
              14. ELECTRONIC COMMUNICATIONS
          </span>
          <div className="bkLabelQuality" >
            <p className=" line_height150 letter_spac_minus_01">
              Speaking.AI will not accept any responsibility for failed, partial or garbled computer transmissions,
              for any breakdown, failure, connection or availability of computer, telephone, network, electronic or
              Internet hardware or software, for Internet accessibility or availability or traffic congestion or for
              any unauthorized human acts, including any errors or mistakes.
            </p>
          </div>

          <span
              style={{ letterSpacing: " -0.176px", marginLeft: "1px", marginTop: "10px" }}
              className="d-block clr_primary fs_md ff_work fw-normal line_height150 fw-semibold">
              15. INDEMNIFICATION
          </span>
          <div className="bkLabelQuality" >
            <p className=" line_height150 letter_spac_minus_01">
              You agree to defend and indemnify Speaking.AI from and against any claims, causes of action, demands,
              recoveries, losses, damages, fines, penalties or other costs or expenses of any kind or nature including
              but not limited to reasonable legal and accounting fees, brought by third parties as a result of:
              - Your breach of this agreement or the documents referenced herein.
              - Your violation of any law or the rights of a third party.
              - Your use of the Speaking.AI platform.
            </p>
          </div>

          <span
              style={{ letterSpacing: " -0.176px", marginLeft: "1px", marginTop: "10px" }}
              className="d-block clr_primary fs_md ff_work fw-normal line_height150 fw-semibold">
              16. CHANGES AND TERMINATION
          </span>
          <div className="bkLabelQuality" >
            <p className=" line_height150 letter_spac_minus_01">
              We may change the platform and these terms at any time, in our sole discretion and without notice to you.
              You are responsible for remaining knowledgeable about these terms. Your continued use of the platform
              constitutes your acceptance of any changes to these terms and any changes will supersede all previous
              versions of the terms. Unless otherwise specified herein, all changes to these terms apply to all users
              take effect. Furthermore, we may terminate this agreement with you under these terms at any time by
              notifying you in writing (including by email) or without any warning.
            </p>
          </div>

          <span
              style={{ letterSpacing: " -0.176px", marginLeft: "1px", marginTop: "10px" }}
              className="d-block clr_primary fs_md ff_work fw-normal line_height150 fw-semibold">
              17. INTEGRATION CLAUSE
          </span>
          <div className="bkLabelQuality" >
            <p className=" line_height150 letter_spac_minus_01">
              This agreement together with the privacy policy and any other legal notices published by Speaking.AI,
              shall constitute the entire agreement between you and Speaking.AI concerning and governs your use of the platform.
            </p>
          </div>

          <span
              style={{ letterSpacing: " -0.176px", marginLeft: "1px", marginTop: "10px" }}
              className="d-block clr_primary fs_md ff_work fw-normal line_height150 fw-semibold">
              18. DISPUTES
          </span>
          <div className="bkLabelQuality" >
            <p className=" line_height150 letter_spac_minus_01">
              The user agrees that any dispute, claim or controversy arising out of or relating to these terms and conditions, or the breach, termination, enforcement, interpretation or validity thereof or the use of the platform, shall be resolved by binding arbitration between the user and Speaking.AI, provided that each party retains the right to bring an individual action in a court of competent jurisdiction.
              In the event that a dispute arises in connection with the use of the platform or breach of these terms and conditions, the parties agree to submit their dispute to arbitration resolution before a reputable arbitration organization as mutually agreed by the parties and in accordance with applicable commercial arbitration rules.
              To the fullest extent permitted by law, you agree that you will not file, join or participate in any class action lawsuit in connection with any claim, dispute or controversy that may arise in connection with your use of the platform.
              The courts of the United States, specifically the courts located in the State of California, shall have jurisdiction over any dispute, controversy or claim relating to Speaking.AI and its business operations. Any such dispute or controversy shall be brought and resolved in the courts of the United States, specifically the courts located in the State of California.
            </p>
          </div>

          <span
              style={{ letterSpacing: " -0.176px", marginLeft: "1px", marginTop: "10px" }}
              className="d-block clr_primary fs_md ff_work fw-normal line_height150 fw-semibold">
              19. FINAL PROVISIONS
          </span>
          <div className="bkLabelQuality" >
            <p className=" line_height150 letter_spac_minus_01">
              These terms and conditions are governed by the laws of the United States. Use of the Speaking.AI platform is unauthorized in any jurisdiction that does not give effect to all provisions of these terms and conditions.
              Our performance of these terms is subject to existing laws and legal process, and nothing contained in these terms limits our right to comply with law enforcement or other governmental or legal requests or requirements relating to your use of our platform or information provided to or gathered by us with respect to such use.
              If any part of these terms is found to be invalid, illegal or unenforceable, the validity, legality and enforceability of the remaining provisions will not in any way be affected or impaired. Our failure or delay in enforcing any provision of these terms at any time does not waive our right to enforce the same or any other provision(s) hereof in the future.
              Any rights not expressly granted herein are reserved.
            </p>
          </div>

          <span
              style={{ letterSpacing: " -0.176px", marginLeft: "1px", marginTop: "10px" }}
              className="d-block clr_primary fs_md ff_work fw-normal line_height150 fw-semibold">
              20. CONTACT INFORMATION
          </span>
          <div className="bkLabelQuality" >
            <p className=" line_height150 letter_spac_minus_01">
              If you have questions or concerns about these terms, please contact us using the contact information below:
              Speaking.AI.
              Email: support@speaking.ai
            </p>
          </div>

        </Modal>
      </>
  );
};

const DialogService = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  let [auodioContainerSate, setauodioContainerSate] = useState(0);
  const showModal = () => {
    setIsModalOpen(true);
  };
  const handleOk = () => {
    setIsModalOpen(false);
  };
  const handleCancel = () => {
    setIsModalOpen(false);
  };

  let updateAudioList = () =>{
    setauodioContainerSate(auodioContainerSate = auodioContainerSate + 1);
    console.log("add control2")
  }


  return (
      <>
        <label className=" cursor_pointer Signin_link fw-normal" onClick={showModal} >
          privacy policy
        </label>
        <Modal title="Privacy Policy"   open={isModalOpen} onOk={handleOk} onCancel={handleCancel} width={1000} maskClosable={false} cancelButtonProps={{ style: { display: 'none' } }}>
          <span
              style={{ letterSpacing: " -0.176px", marginLeft: "1px", marginTop: "10px" }}
              className="d-block clr_primary fs_md ff_work fw-normal line_height150 fw-semibold">
              Welcome to Speaking.AI!
          </span>
          <div className="bkLabelQuality" >
            <p className=" line_height150 letter_spac_minus_01">
              (Hereinafter referred to as "Speaking.AI").
              Speaking.AI values your privacy and the protection of your personal data. This privacy policy describes
              what information we collect from you, how we collect it, how we use it, how we obtain your consent,
              how long we keep it in our databases and, if necessary, with whom we share it.
              By using the platform, you are accepting the practices described in this privacy policy. Your use of
              the platform is also subject to our terms and conditions. In this privacy policy, the words "platform"
              refers to the Speaking.AI website, unless mentioned individually in the document, "we", "us", "our" and
              "Speaking.AI" refers to Speaking.AI and "you" and "user" refers to you, the user of Speaking.AI.
              This privacy policy may change from time to time. Your continued use of the platform after we make changes
              to this privacy policy will be deemed acceptance of those changes, so please check this policy periodically
              for updates. This privacy policy has been developed and is maintained in accordance with all applicable federal
              and international laws and regulations and, in particular, the California Consumer Privacy Act (CCPA) and the General Data Protection Regulation (GDPR).
            </p>
          </div>

          <span
              style={{ letterSpacing: " -0.176px", marginLeft: "1px", marginTop: "10px" }}
              className="d-block clr_primary fs_md ff_work fw-normal line_height150 fw-semibold">
             1. GENERAL INFORMATION
          </span>

          <div className="bkLabelQuality" >
            <p className=" line_height150 letter_spac_minus_01">
              The personal data of users that are collected and processed through:
              - https://speaking.ai
              Will be under the responsibility and in charge of:
              - Speaking.AI.
              - Email: support@speaking.ai
            </p>
          </div>

          <span
              style={{ letterSpacing: " -0.176px", marginLeft: "1px", marginTop: "10px" }}
              className="d-block clr_primary fs_md ff_work fw-normal line_height150 fw-semibold">
              2. HOW DO YOU OBTAIN MY CONSENT?
          </span>

          <div className="bkLabelQuality" >
            <p className=" line_height150 letter_spac_minus_01">
              By using the platform, registering as a user, purchasing a plan, subscribing to our newsletter, contacting
              us through our contact details and providing us with personal information to communicate with you, you
              consent to our collection, storage and use of your information on the terms contained in this privacy policy.
              You may withdraw your consent at any time by sending us your request through our contact information.
            </p>
          </div>

          <span
              style={{ letterSpacing: " -0.176px", marginLeft: "1px", marginTop: "10px" }}
              className="d-block clr_primary fs_md ff_work fw-normal line_height150 fw-semibold">
              3. TYPES OF INFORMATION COLLECTED
          </span>

          <div className="bkLabelQuality" >
            <p className=" line_height150 letter_spac_minus_01">
              The information we collect from our users helps us to continually improve the user experience on the platform. These are the types of information we collect:
              Information you provide to us. You provide information when you register as a user, purchase a plan, use the features available on the platform (voice generation through AI) and/or communicate with us through our contact information. As a result of those actions, you may provide us with the following information:
              - Name
              - E-mail address
              - Any additional information related to you that you provide to us directly or indirectly through our platform.
              Speaking.AI will not collect any personally identifiable information about you unless you provide it.
              Information Collected Automatically: By accessing and using the platform, you automatically provide us with the following information:
              - Your IP address
              - Referrer URLs
              Payment Information: Your payment details will be processed by the payment processors available on this platform, which will process and store your data securely and for the sole purpose of processing the purchase of the plans. Speaking.AI reserves the right to contract any payment platform available on the market, which processes your data for the sole purpose of processing the purchase of the plans.
              GOOGLE Analytics. We use Google Analytics provided by Google, Inc., USA (“Google”). These tool and technologies collect and analyze certain types of information, including IP addresses, device and software identifiers, referring and exit URLs, feature use metrics and statistics, usage history, media access control address (MAC Address), mobile unique device identifiers, and other similar information via the use of cookies. The information generated by Google Analytics (including your IP address) may be transmitted to and stored by Google on servers in the United States. We use the GOOGLE Analytics collection of data to enhance the website and improve our service.
              Please consult Google's privacy policy here:
              - https://policies.google.com/privacy
              Social Media: On our website you will find links and functions linked to different social networks, in which you can share your information. It is advisable to consult the privacy policy and data protection of each social network used on our website.
              - Facebook: https://www.facebook.com/privacy/explanation
              - Instagram: http://instagram.com/about/legal/privacy/
              - Twitter: https://twitter.com/privacy
              - Discord: https://discord.com/privacy
              Contact information: We may access some personal information about the user, such as name and email address, when the user or any third party communicates with us through our contact information. Personal information provided through our contact information is not stored on any Speaking.AI server and will be stored on the respective server of our email service.
              Careers: users or any third party can provide information if they apply for any of our available jobs. Please note that by submitting any information to us for the purpose of applying for our jobs, the user authorizes us to process and store his or her information for employment purposes only. The information will not be shared with any third party without the user's authorization.
            </p>
          </div>

          <span
              style={{ letterSpacing: " -0.176px", marginLeft: "1px", marginTop: "10px" }}
              className="d-block clr_primary fs_md ff_work fw-normal line_height150 fw-semibold">
            4. HOW LONG WE KEEP YOUR DATA
          </span>

          <div className="bkLabelQuality" >
            <p className=" line_height150 letter_spac_minus_01">
              Personal data provided by users through the platform will be retained for as long as necessary to provide our platform, services and plans or until the user closes the user account or requests deletion of their data. Speaking.AI may retain personal data for a longer period provided that the user has consented to such processing, as long as such consent is not withdrawn. In addition, Speaking.AI may be obliged to retain personal data for a longer period provided that this is required for compliance with a legal obligation or by order of an authority. Once the retention period has expired, the personal data will be deleted. Therefore, the right of access, the right of deletion, the right of rectification and the right to data portability cannot be asserted after the retention period has expired.
            </p>
          </div>

          <span
              style={{ letterSpacing: " -0.176px", marginLeft: "1px", marginTop: "10px" }}
              className="d-block clr_primary fs_md ff_work fw-normal line_height150 fw-semibold">
             5. HOW WE USE YOUR INFORMATION.
          </span>
          <div className="bkLabelQuality" >
            <p className=" line_height150 letter_spac_minus_01">
              In general, we use the information we collect primarily to provide, maintain, protect and improve our platform and our current services. We use personal information collected through our platform as described below:
              - User registration.
              - Provide the functionalities available in the platform (voice generation through AI).
              - Provide the plans.
              - Processing payments through our payment processor.
              - Understand and improve your experience using our platform.
              - Respond to your comments or questions through our contact information.
              - Send you related information, including confirmations, invoices, technical notices, updates, security alerts, and support and administrative messages.
              - Send you relevant information about Speaking.AI.
              - Speaking.AI marketing purposes.
              - Link or combine your information with other data we obtain from third parties to help us understand your needs and provide you with better service.
              - Protect, investigate and deter fraudulent, unauthorized or illegal activities.
            </p>
          </div>

          <span
              style={{ letterSpacing: " -0.176px", marginLeft: "1px", marginTop: "10px" }}
              className="d-block clr_primary fs_md ff_work fw-normal line_height150 fw-semibold">
              6. HOW WE SHARE INFORMATION
          </span>
          <div className="bkLabelQuality" >
            <p className=" line_height150 letter_spac_minus_01">
              Information about our customers is an important part of our business, and we are not in the business of selling it to others. We share customer information only as described below.
              Third-Party Service Providers. We use third party services to perform certain functions on our platform. Some of these functions and services are: Building and hosting the platform, processing payments, sending emails and analyzing data (Google Analytics).
              These third-party services and tools may have access to personal information needed to perform their functions, but may not use that information for other purposes. Information shared with these third-party services will be treated and stored in accordance with their respective privacy policies and our privacy policy.
              Business Transfers. In the event Speaking.AI creates, merges with, or is acquired by another entity, your information will likely be transferred. Speaking.AI will send you an email or post a prominent notice on our platform before your information becomes subject to another privacy policy.
              Protection of Speaking.AI and Others. We release personal information when we believe release is appropriate to comply with the law, enforce or apply our terms and conditions and other agreements, or protect the rights, property, or safety of Speaking.AI, our users, or others. This includes exchanging information with other companies and organizations for fraud protection and credit risk reduction.
              Anonymous Information. Speaking.AI uses anonymous browsing information collected automatically by our servers primarily to help us administer and improve the platform. We may also use aggregated anonymous information to provide information about the Platform to potential business partners and other unaffiliated entities. This information is not personally identifiable.
            </p>
          </div>

          <span
              style={{ letterSpacing: " -0.176px", marginLeft: "1px", marginTop: "10px" }}
              className="d-block clr_primary fs_md ff_work fw-normal line_height150 fw-semibold">
              7. PROTECTION OF YOUR INFORMATION
          </span>
          <div className="bkLabelQuality" >
            <p className=" line_height150 letter_spac_minus_01">
              We work to protect the security of your information during transmission by using Secure Sockets Layer (SSL) software, which encrypts information you provide through the website and platform. If transactions are processed on the website, transaction information is transmitted to and from the platform in encrypted form using industry-standard SSL connections to help protect such information from interception. We restrict authorized access to your personal information to those persons who have a legitimate need to know that information and to those persons you have authorized to have access to that information. Speaking.AI follows generally accepted industry standards to protect the personal information submitted to us, both during transmission and once Speaking.AI receives it. No method of transmission over the Internet, or method of electronic storage, is 100% secure. Therefore, while Speaking.AI strives to use commercially acceptable means to protect your personal information, we cannot guarantee its absolute security. We will not sell, distribute or lease your personal information to third parties unless we have your permission or are required by law to do so.
            </p>
          </div>

          <span
              style={{ letterSpacing: " -0.176px", marginLeft: "1px", marginTop: "10px" }}
              className="d-block clr_primary fs_md ff_work fw-normal line_height150 fw-semibold">
              8. RIGHTS
          </span>
          <div className="bkLabelQuality" >
            <p className=" line_height150 letter_spac_minus_01">
              Users who provide information through our platform, as data subjects, have the right to access, rectify, download or delete their information, as well as to restrict and oppose certain processing of their information. While some of these rights apply generally, others only apply in certain limited circumstances. These rights are described below:
              - Access and portability: to access and know what information is stored on our servers, you can send us your request through our contact information.
              - Rectification, restriction, limitation and deletion: You may also rectify, restrict, limit or delete much of your information.
              - Right to be informed: Users of our platform will be informed, upon request, about what data we collect, how it is used, how long it is kept and whether it is shared with third parties.
              - Object: Where we process your data based on our legitimate interests, as explained above, or in the public interest, you may object to this processing in certain circumstances. In such cases, we will stop processing your information unless we have legitimate grounds to continue processing it or where necessary for legal reasons.
              - Withdraw consent: Where you have previously given your consent, for example to allow us to process and store your personal information, you have the right to withdraw your consent to the processing and storage of your information at any time. For example, you can withdraw your consent by updating your settings. In certain cases, we may continue to process your information after you have withdrawn your consent if we have a lawful basis for doing so or if your withdrawal of consent was limited to certain processing activities.
              - Complaint: If you wish to lodge a complaint about our use of your information (and without prejudice to any other rights you may have), you have the right to do so with your local supervisory authority. Users may exercise all of these rights by contacting us via the contact information or contact page.
              - Rights related to automated decision-making, including profiling: Users may request that we provide them with a copy of the automated processing activities we conduct if they believe that data is being unlawfully processed.
              Users or owners of the personal data they provide through the platform can exercise these rights over their personal data at any time and without any limitation by sending us their request through our contact information.
            </p>
          </div>

          <span
              style={{ letterSpacing: " -0.176px", marginLeft: "1px", marginTop: "10px" }}
              className="d-block clr_primary fs_md ff_work fw-normal line_height150 fw-semibold">
              9. PROTECTION OF CHILDREN'S ONLINE PRIVACY
          </span>
          <div className="bkLabelQuality" >
            <p className=" line_height150 letter_spac_minus_01">
              We comply with the requirements of national and international data protection regulations regarding the protection of personal data of minors. Although the platform is available to all ages, we do not collect any information from children under the age of 13 without the respective permission of their parents or legal guardians. If we become aware that a child under the age of 13 has provided us with personal information without the permission of his or her parent or legal guardian, we will take the necessary steps to delete that information.
            </p>
          </div>

          <span
              style={{ letterSpacing: " -0.176px", marginLeft: "1px", marginTop: "10px" }}
              className="d-block clr_primary fs_md ff_work fw-normal line_height150 fw-semibold">
              10. THIRD PARTIES
          </span>
          <div className="bkLabelQuality" >
            <p className=" line_height150 letter_spac_minus_01">
              Except as expressly included in this privacy policy, this document addresses only the use and disclosure of information Speaking.AI collects from you. If you disclose your information to others, whether other users or Speaking.AI vendors, different rules may apply to their use or disclosure of the information you disclose to them. Speaking.AI does not control the privacy policies of third parties, and you are subject to the privacy policies of those third parties where applicable. Speaking.AI is not responsible for the privacy or security practices of another Internet platform, including those linked to or from the Speaking.AI platform. Speaking.AI encourages you to ask questions before disclosing your personal information to others.
            </p>
          </div>

          <span
              style={{ letterSpacing: " -0.176px", marginLeft: "1px", marginTop: "10px" }}
              className="d-block clr_primary fs_md ff_work fw-normal line_height150 fw-semibold">
             11. CONTACT
          </span>
          <div className="bkLabelQuality" >
            <p className=" line_height150 letter_spac_minus_01">
              If you have questions or concerns about this privacy policy and the treatment and security of your data, please contact us using the contact information below:
              Speaking.AI.
              Email: support@speaking.ai
            </p>
          </div>



        </Modal>
      </>
  );
};


export default SigNUp;
